import React from 'react'
import { useState,useEffect,useRef } from 'react';
import { Amplify, Auth, API, Storage,Cache } from 'aws-amplify';
import {Flex, View, Button,Text,
    Divider,Radio,SelectField,TextField,Icon, Link} from '@aws-amplify/ui-react';
    import  NavBar  from '../components/navbar.tsx';
import studioTheme from '../ui-components/studioTheme';
import SideBar from '../ui-components/SideBar';
import AccountAccountInformation from '../ui-components/Forecasts'; 
import { withAuthenticator, Loader } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
// import { getUser,getStripeCustomer } from '../graphql/queries';
import { createImportSpecifier } from 'typescript';
import Selectedbutton from '../ui-components/SelectedButton';
import ItemCard from '../ui-components/ItemCard';
import ItemCardNew from '../components/item-card.tsx';

import Partners from '../ui-components/Partners';
import moment from 'moment';
import SalesReportOptions from '../ui-components/SalesReportOptions';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ChartDataset,LabelItem, Color, CategoryScale, LinearScale, PointElement, LineElement, Tooltip} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { validateSubscription } from '../utils/subscriptionService.js';
import DialogModal from '../components/dialog-modal.tsx';
import  { useNavigate } from 'react-router'

Amplify.configure(awsconfig);

function TrafficReport({changeView}) {

    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(true);
    const [lineChartOptions, setLineChartOptions] = useState({});
    const [asinIsLoading, setasinIsLoading] = useState(true);
    const [selectValue, setSelectValue] = useState("lastThirtyDays");
    const [orderedProducts, setOrderedProducts] = useState({});
    const [asinOrderedProducts, setAsinOrderedProducts] = useState({});
    const [chartData,setChartData]= useState({});
  
    const [averages1,setAverages1]= useState("");
    const [averages2,setAverages2]= useState("");

    const [subscriptionPlan,setSubscriptionPlan]= useState(null);
    const [subscriptionLoading, setSubscriptionLoading] = useState(true);
    
    const _orderedProductSales= useRef<Array<number>>([]);
    const _orderedProductSalesB2B = useRef<Array<number>>([]);
    const _orderedProductSalesNotB2B = useRef<Array<number>>([]);

    let averageOfferCount = new Array<Number>();
    let averageParentItems = new Array<Number>();
    let browserPageViews = new Array<Number>();
    let browserSessions = new Array<Number>();
    
    let feedbackReceived = new Array<Number>();
    let mobileAppPageViews = new Array<Number>();
    
    let negativeFeedbackReceived = new Array<Number>();
    let orderItemSessionPercentage = new Array<Number>();
    let orderItemSessionPercentageB2B = new Array<Number>();
    
    let receivedNegativeFeedbackRate = new Array<Number>();
    
    let unitSessionPercentage = new Array<Number>();
    let unitSessionPercentageB2B = new Array<Number>();
    let unitSessionPercentageNotB2B = new Array<Number>();


    //new report variables
    const _retailUnitSessionPercentage = useRef<Number[]>([]);
    const _corporateUnitSessionPercentage = useRef<Number[]>([]);
    const _retailMobileAppPageViews = useRef<Number[]>([]);
    const _corporateMobileAppPageViews = useRef<Number[]>([]);
    const _retailBrowserPageViews = useRef<Number[]>([]);
    const _corporateBrowserPageViews = useRef<Number[]>([]);
    const _totalRetailPageViews = useRef<Number[]>([]);
    const _totalBusinessPageViews = useRef<Number[]>([]);
    

    // column AD retailUnitSessionPercentage
    // column AE corporateUnitSessionPercentage
    // column N  retailMobileAppPageViews
    // column O  corporateMobileAppPageViews
    // column P  retailBrowserPageViews
    // column Q  corporateBrowserPageViews
    // column R  totalRetailPageViews
    // column S  totalBusinessPageViews

    const _strDates = useRef<String[]>([]);
    const _filterBy = useRef<String>("");
    const _sessionType = useRef<String>("");
    const _pageViews = useRef<Number[]>([]);
    const _sessions = useRef<Number[]>([]);
    const _buyBoxPercentage = useRef<Number[]>([]);
    const _mobileAppSessions = useRef<Number[]>([]);

    let lineChartData: Number[] = [];
    let lineChartLabels: String[] = [];
    
    let asinLineChartOptions: any = {};
    let asinLineChartLabels: String[] = [];

    let trafficByDate = {};
    let asinParentList = {};
    let asinChildList = {};

    let asinSales = [];
    let asinStrDates  = [];

    let view = "sales";

    const [dialogModalOpen, setDialogModalOpen] = useState(false);
    const [dialogModalMessage, setDialogModalMessage] = useState('');
  
    const handleCloseModal = () => {
      setDialogModalOpen(false); 
    };
    
    useEffect(() => {
        ChartJS.register(CategoryScale,LinearScale, PointElement, LineElement,zoomPlugin, Tooltip);
        downloadTraffic();
        validateSubscription(setSubscriptionPlan, setSubscriptionLoading, setDialogModalMessage, setDialogModalOpen, navigate);
    },[]);// <-- Have to pass in [] here or is called on every render
    
    // async function fetchSubscription(){
    //   const user = Cache.getItem('user');

    //   let refreshToken = {}
    //   try{
    //       var response;
    //       if(user){
    //           response = await API.graphql({
    //               query: getStripeCustomer
    //               ,variables : {"email": user.id, "stripeCustomerId": user.stripeId}
    //           })
  
    //           const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);
              
    //           if(stripeData["body"]["data"][0] && stripeData["body"]["data"][0]["product_name"]["name"]){
    //               setSubscriptionPlan(stripeData["body"]["data"][0]["product_name"]["name"]);
    //           }
    //       }
    //   }catch(exception){
    //     console.error('Error fetching subscription:', exception);
    //   } finally {
    //     setSubscriptionLoading(false);
    //   }
    // }

    async function downloadTraffic() {
      try{
        const market = Cache.getItem('marketCode');
        let url = market+ "_SalesAndTrafficReport/TrafficByDate.json";
        
        const result = await Storage.vault.get(url, { download: true });
        trafficByDate = await new Response(result.Body).json();
      }
      catch {
        trafficByDate = {};
      }
        await sortSTR();
        
    }

    function formatNumberWithCommas(number) {
      // Convert the number to a string
      let numberString = number.toString();
    
      // Use a regular expression to add commas every three digits
      numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    
      // Add spaces for better readability if needed
      // Example: 10 304 955 905
      numberString = numberString.replace(/,/g, ",");
    
      return numberString;
    }

    function findAverageCurrentData(array){
        var total = 0;
        var denominator = 0;
        
        for(var i = 0; i < array.length; i++) {
            total += Number(array[i]);
            
            if(Number(array[i]) != 0){
              denominator++;
            } 
        }

        let returnValue = (total / denominator).toFixed(2);
        if(returnValue == "NaN"){
          return 0;
        }
        return formatNumberWithCommas(returnValue);
    }

    function moveAsinView(e) {
      
      if(e.target.value == "asin"){
        changeView('asin');
      }
    }

    async function sortSTR(){
        
        let temp;
        
        for(let i in trafficByDate){
         
          averageOfferCount.push(trafficByDate[i].averageOfferCount)  
          averageParentItems.push(trafficByDate[i].averageParentItems)  
          browserPageViews.push(trafficByDate[i].browserPageViews)  
          browserSessions.push(trafficByDate[i].browserSessions)  
          _buyBoxPercentage.current.push(trafficByDate[i].buyBoxPercentage)  
          feedbackReceived.push(trafficByDate[i].feedbackReceived)  
          mobileAppPageViews.push(trafficByDate[i].mobileAppPageViews)  
          _mobileAppSessions.current.push(trafficByDate[i].mobileAppSessions)  
          negativeFeedbackReceived.push(trafficByDate[i].negativeFeedbackReceived)  
          orderItemSessionPercentage.push(trafficByDate[i].orderItemSessionPercentage)  
          orderItemSessionPercentageB2B.push(trafficByDate[i].orderItemSessionPercentageB2B)  
          _pageViews.current.push(trafficByDate[i].pageViews) 
          receivedNegativeFeedbackRate.push(trafficByDate[i].receivedNegativeFeedbackRate) 
          _sessions.current.push(trafficByDate[i].sessions) 
          unitSessionPercentage.push(trafficByDate[i].unitSessionPercentage) 
          unitSessionPercentageB2B.push(trafficByDate[i].unitSessionPercentageB2B)
          temp = Number(trafficByDate[i].unitSessionPercentage) - Number(trafficByDate[i].unitSessionPercentageB2B) 
          unitSessionPercentageNotB2B.push(temp)
          _strDates.current.push(moment(trafficByDate[i].date,"YYYY-MM-DD").format('MMM D, y'))

        
          _retailUnitSessionPercentage.current.push(trafficByDate[i].unitSessionPercentage) 
          _corporateUnitSessionPercentage.current.push(trafficByDate[i].unitSessionPercentageB2B)
          _retailMobileAppPageViews.current.push(trafficByDate[i].mobileAppPageViews)
          _corporateMobileAppPageViews.current.push(trafficByDate[i].mobileAppPageViewsB2B)
          _retailBrowserPageViews.current.push(trafficByDate[i].browserPageViews)
          _corporateBrowserPageViews.current.push(trafficByDate[i].browserPageViewsB2B)
          _totalRetailPageViews.current.push( Number(trafficByDate[i].browserPageViews) + Number(trafficByDate[i].mobileAppPageViews) )
          _totalBusinessPageViews.current.push( Number(trafficByDate[i].browserPageViewsB2B) + Number(trafficByDate[i].mobileAppPageViewsB2B) )
         
        }
    
        // if(_strDates.current.length < 30){
        //     _filterBy.current = 'lastSevenDays';
        //     _sessionType.current = 'sessions';
        //     filter();
        //         //TODO 
        //     //this.dynamicForm.controls.FilterBy.setValue( 'lastSevenDays')
        // }
        // else{
            _filterBy.current = 'lastThirtyDays';
            _sessionType.current = 'unitSessionPercentage';
            filter();
        // }
        
    }
 
    function setAverages(average1, average2){
      if(_sessionType.current == 'unitSessionPercentage'){
        setAverages1(<ItemCardNew width="50%" overrides={{Name:{children:"Average Retail Unit Session Percentage"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2(<ItemCardNew width="50%" overrides={{Name:{children:"Average Business Unit Session Percentage"}, Number:{children:average2}}}></ItemCardNew>)
        
      }
      else if(_sessionType.current == 'corporateUnitSessionPercentage'){
        setAverages1(<ItemCardNew width="100%" overrides={{Name:{children:"Average Business Unit Session Percentage"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2("")
      }
      else if(_sessionType.current == 'mobileAppPageViews'){
        setAverages1(<ItemCardNew  width="50%" overrides={{Name:{children:"Average Retail Mobile App Page Views"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2(<ItemCardNew width="50%" overrides={{Name:{children:"Average Business Mobile App Page Views"}, Number:{children:average2}}}></ItemCardNew>)
        
      }
      else if(_sessionType.current == 'corporateMobileAppPageViews'){
        setAverages1(<ItemCardNew width="100%" overrides={{Name:{children:"Average Business Mobile App Page Views"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2("")
      }
      else if(_sessionType.current == 'browserPageViews'){
        setAverages1(<ItemCardNew  width="50%" overrides={{Name:{children:"Average Retail Browser Page Views"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2(<ItemCardNew width="50%" overrides={{Name:{children:"Average Business Browser Page Views"}, Number:{children:average2}}}></ItemCardNew>)
      }
      else if(_sessionType.current == 'corporateBrowserPageViews'){
        setAverages1(<ItemCardNew width="100%" overrides={{Name:{children:"Average Business Browser Page Views"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2("")
      }
      else if(_sessionType.current == 'totalPageViews'){
        setAverages1(<ItemCardNew  width="50%" overrides={{Name:{children:"Average Retail Page Views"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2(<ItemCardNew  width="50%" overrides={{Name:{children:"Average Business Page Views"}, Number:{children:average2}}}></ItemCardNew>)
        
      }
      else if(_sessionType.current == 'totalBusinessPageViews'){
        setAverages1(<ItemCardNew  width="100%" overrides={{Name:{children:"Average Business Page Views"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2("")
      }
    }

    function filter(){
      
      setLineChartOptions( {
          responsive: true,
          showTooltips: true,
      //   scales: {
      //     yAxes:[  {
      //       //  scaleLabel: {
      //       //     display: true,
      //       //     labelString: 'USD'//this.market.selectedCurency
      //       //  },
      //       //  ticks:{
      //       //       callback: function(value, index, values) {
      //       //         return '$' + value;
      //       //     }
      //       //  }
            
      //       }
      //     ]
         
      //  },
          plugins: {
            tooltip: {
              callbacks: {
                  label: function (tooltipItem, data) {
                      return " "+tooltipItem.dataset.label + " "+tooltipItem.formattedValue;
                  }
              }
          },
          // zoom: {
          //   zoom: {
          //     wheel: {
          //       enabled: true,
          //     },
          //     pinch: {
          //       enabled: true
          //     },
          //     mode: 'xy',
          //   },
          //   pan: {
          //     enabled: true,
          //     mode: 'xy', // 'xy' for both x and y directions
          //   },
          // },
            legend: {
              display:true,
              title:{
                display: true
              }
            },
            title: {
              display: true,
              text: 'Chart.js Line Chart',
            },
          },
        });
      
        let filterBy = _filterBy.current;
        let sessionType = _sessionType.current;
        let dat = {};
        let av1 = "";
        let av2 = "";
        lineChartLabels = _strDates.current;
        switch(filterBy){
              
              case 'lastSevenDays':{
                  if (sessionType == "unitSessionPercentage"){
                      
                      dat = [
                          {
                            label: 'Retail Unit Session Percentage',
                            data: _retailUnitSessionPercentage.current.slice(Math.max(_retailUnitSessionPercentage.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Business Unit Session Percentage',
                            data: _corporateUnitSessionPercentage.current.slice(Math.max(_corporateUnitSessionPercentage.current.length - 7, 1)),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailUnitSessionPercentage.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                      av2=findAverageCurrentData(_corporateUnitSessionPercentage.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "corporateUnitSessionPercentage"){
                      
                      dat = [
                          {
                            label: 'Business Unit Session Percentage',
                            data: _corporateUnitSessionPercentage.current.slice(Math.max(_corporateUnitSessionPercentage.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateUnitSessionPercentage.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "mobileAppPageViews"){
                      
                    dat = [
                        {
                          label: 'Retail Mobile App Page Views',
                          data: _retailMobileAppPageViews.current.slice(Math.max(_retailMobileAppPageViews.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Mobile App Page Views',
                          data: _corporateMobileAppPageViews.current.slice(Math.max(_corporateMobileAppPageViews.current.length - 7, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailMobileAppPageViews.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                    av2=findAverageCurrentData(_corporateMobileAppPageViews.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "corporateMobileAppPageViews"){
                      
                    dat = [
                        {
                          label: 'Business Mobile App Page Views',
                          data: _corporateMobileAppPageViews.current.slice(Math.max(_corporateMobileAppPageViews.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateMobileAppPageViews.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "browserPageViews"){
                      
                    dat = [
                        {
                          label: 'Retail Browser Page Views',
                          data: _retailBrowserPageViews.current.slice(Math.max(_retailBrowserPageViews.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Browser Page Views',
                          data: _corporateBrowserPageViews.current.slice(Math.max(_corporateBrowserPageViews.current.length - 7, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailBrowserPageViews.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                    av2=findAverageCurrentData(_corporateBrowserPageViews.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "corporateBrowserPageViews"){
                      
                    dat = [
                        {
                          label: 'Business Browser Page Views',
                          data: _corporateBrowserPageViews.current.slice(Math.max(_corporateBrowserPageViews.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateBrowserPageViews.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "totalPageViews"){
                      
                    dat = [
                        {
                          label: 'Total Retail Page Views',
                          data: _totalRetailPageViews.current.slice(Math.max(_totalRetailPageViews.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Total Business Page Views',
                          data: _totalBusinessPageViews.current.slice(Math.max(_totalBusinessPageViews.current.length - 7, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_totalRetailPageViews.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                    av2=findAverageCurrentData(_totalBusinessPageViews.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "totalBusinessPageViews"){
                      
                    dat = [
                        {
                          label: 'Total Business Page Views',
                          data: _totalBusinessPageViews.current.slice(Math.max(_totalBusinessPageViews.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_totalBusinessPageViews.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  
      
                  const chartData = {
                      labels : lineChartLabels.slice(Math.max(lineChartLabels.length - 7, 1)),
                    datasets: dat,
                  };
                  setIsLoading(false);
                  setSelectValue("lastSevenDays");
                  setOrderedProducts(orderedProducts);
                  setChartData(chartData);
                  
                  setAverages(av1,av2);
                  break;
              }
              case 'lastThirtyDays':{
                  lineChartLabels = _strDates.current;
                  if (sessionType == "unitSessionPercentage"){
                        
                      dat = [
                          {
                            label: 'Retail Unit Session Percentage',
                            data: _retailUnitSessionPercentage.current.slice(Math.max(_retailUnitSessionPercentage.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Business Unit Session Percentage',
                            data: _corporateUnitSessionPercentage.current.slice(Math.max(_corporateUnitSessionPercentage.current.length - 30, 1)),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailUnitSessionPercentage.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                      av2=findAverageCurrentData(_corporateUnitSessionPercentage.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "corporateUnitSessionPercentage"){
                      
                      dat = [
                          {
                            label: 'Business Unit Session Percentage',
                            data: _corporateUnitSessionPercentage.current.slice(Math.max(_corporateUnitSessionPercentage.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateUnitSessionPercentage.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "mobileAppPageViews"){
                      
                    dat = [
                        {
                          label: 'Retail Mobile App Page Views',
                          data: _retailMobileAppPageViews.current.slice(Math.max(_retailMobileAppPageViews.current.length - 30, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Mobile App Page Views',
                          data: _corporateMobileAppPageViews.current.slice(Math.max(_corporateMobileAppPageViews.current.length - 30, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailMobileAppPageViews.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                    av2=findAverageCurrentData(_corporateMobileAppPageViews.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "corporateMobileAppPageViews"){
                      
                    dat = [
                        {
                          label: 'Business Mobile App Page Views',
                          data: _corporateMobileAppPageViews.current.slice(Math.max(_corporateMobileAppPageViews.current.length - 30, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateMobileAppPageViews.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "browserPageViews"){
                      
                    dat = [
                        {
                          label: 'Retail Browser Page Views',
                          data: _retailBrowserPageViews.current.slice(Math.max(_retailBrowserPageViews.current.length - 30, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Browser Page Views',
                          data: _corporateBrowserPageViews.current.slice(Math.max(_corporateBrowserPageViews.current.length - 30, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailBrowserPageViews.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                    av2=findAverageCurrentData(_corporateBrowserPageViews.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "corporateBrowserPageViews"){
                      
                    dat = [
                        {
                          label: 'Business Browser Page Views',
                          data: _corporateBrowserPageViews.current.slice(Math.max(_corporateBrowserPageViews.current.length - 30, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateBrowserPageViews.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "totalPageViews"){
                      
                    dat = [
                        {
                          label: 'Total Retail Page Views',
                          data: _totalRetailPageViews.current.slice(Math.max(_totalRetailPageViews.current.length - 30, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Total Business Page Views',
                          data: _totalBusinessPageViews.current.slice(Math.max(_totalBusinessPageViews.current.length - 30, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_totalRetailPageViews.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                    av2=findAverageCurrentData(_totalBusinessPageViews.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "totalBusinessPageViews"){
                      
                    dat = [
                        {
                          label: 'Total Business Page Views',
                          data: _totalBusinessPageViews.current.slice(Math.max(_totalBusinessPageViews.current.length - 30, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_totalBusinessPageViews.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  
      
                  const chartData = {
                      labels : lineChartLabels.slice(Math.max(lineChartLabels.length - 30, 1)),
                    datasets: dat,
                  };
                  setIsLoading(false);
                  setSelectValue("lastThirtyDays");
                  setOrderedProducts(orderedProducts);
                  setChartData(chartData);
                  setAverages(av1,av2);
                  break;
              }
              case 'thisWeek':{
              let filteredData: Number[] = [];
              let y = _strDates.current[_strDates.current.length-1]
              
              const lastDate = new Date(lineChartLabels[lineChartLabels.length-1].toString());
              const date = new Date(lineChartLabels[lineChartLabels.length-1].toString());
              const day = date.getDay();
              const diff = date.getDate() - day + (day === 0 ? -6 : 1);
              let x = new Date(date.setDate(diff));
              const firstDay = moment(x).format('MMM D, y')//this.datepipe.transform(x, 'MMM d, y');
              
              let index = _strDates.current.indexOf(firstDay);
              if(lastDate.getDay() === 1 && _strDates.current.length > 8)
              {
                  index -= 7;
              }
              if(index === -1)
              {
                  index = 0;
              }
              console.log(firstDay);
              console.log(lastDate);
              const lastDay = moment(lastDate).format('MMM D, y')
              let firstIndex = lineChartLabels.indexOf(firstDay);
              let lastIndex = lineChartLabels.indexOf(lastDay);
              
      
              lineChartLabels = _strDates.current;
      
                  if (sessionType == "unitSessionPercentage"){
                          
                      dat = [
                          {
                            label: 'Retail Unit Session Percentage',
                            data: _retailUnitSessionPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Business Unit Session Percentage',
                            data: _corporateUnitSessionPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailUnitSessionPercentage.current.slice(firstIndex,lastIndex));
                      av2=findAverageCurrentData(_corporateUnitSessionPercentage.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "corporateUnitSessionPercentage"){
                      
                      dat = [
                          {
                            label: 'Business Unit Session Percentage',
                            data: _corporateUnitSessionPercentage.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateUnitSessionPercentage.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "mobileAppPageViews"){
                      
                    dat = [
                        {
                          label: 'Retail Mobile App Page Views',
                          data: _retailMobileAppPageViews.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Mobile App Page Views',
                          data: _corporateMobileAppPageViews.current.slice(firstIndex,lastIndex),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailMobileAppPageViews.current.slice(firstIndex,lastIndex));
                    av2=findAverageCurrentData(_corporateMobileAppPageViews.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "corporateMobileAppPageViews"){
                      
                    dat = [
                        {
                          label: 'Business Mobile App Page Views',
                          data: _corporateMobileAppPageViews.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateMobileAppPageViews.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "browserPageViews"){
                      
                    dat = [
                        {
                          label: 'Retail Browser Page Views',
                          data: _retailBrowserPageViews.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Browser Page Views',
                          data: _corporateBrowserPageViews.current.slice(firstIndex,lastIndex),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailBrowserPageViews.current.slice(firstIndex,lastIndex));
                    av2=findAverageCurrentData(_corporateBrowserPageViews.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "corporateBrowserPageViews"){
                      
                    dat = [
                        {
                          label: 'Business Browser Page Views',
                          data: _corporateBrowserPageViews.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateBrowserPageViews.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "totalPageViews"){
                      
                    dat = [
                        {
                          label: 'Total Retail Page Views',
                          data: _totalRetailPageViews.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Total Business Page Views',
                          data: _totalBusinessPageViews.current.slice(firstIndex,lastIndex),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_totalRetailPageViews.current.slice(firstIndex,lastIndex));
                    av2=findAverageCurrentData(_totalBusinessPageViews.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "totalBusinessPageViews"){
                      
                    dat = [
                        {
                          label: 'Total Business Page Views',
                          data: _totalBusinessPageViews.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_totalBusinessPageViews.current.slice(firstIndex,lastIndex));
                  }
                  
      
                  const chartData = {
                      labels : lineChartLabels.slice(firstIndex,lastIndex),
                    datasets: dat,
                  };
                  setIsLoading(false);
                  setSelectValue("thisWeek");
                  setOrderedProducts(orderedProducts);
                  setChartData(chartData);
                  setAverages(av1,av2);
                  break;
              }
              case 'thisMonth':{
              
              let filteredData: Number[] = [];
              //console.log(lineChartLabels[lineChartLabels.length-1].toString())
              const lastDate = new Date(lineChartLabels[lineChartLabels.length-1].toString());
              const now = new Date(lineChartLabels[lineChartLabels.length-1].toString());
              const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1,)).format('MMM D, y')//this.datepipe.transform(new Date(now.getFullYear(), now.getMonth(), 1,), 'MMM d, y');
              console.log(firstDay);
              console.log(lastDate);
              let index = _strDates.current.indexOf(firstDay);
              console.log(firstDay);
              console.log(lastDate);
              const lastDay = moment(lastDate).format('MMM D, y')
              let firstIndex = lineChartLabels.indexOf(firstDay);
              let lastIndex = lineChartLabels.indexOf(lastDay);
              console.log(firstIndex);
              console.log(lastIndex);
              lineChartLabels = _strDates.current;
      
                if (sessionType == "unitSessionPercentage"){
                              
                    dat = [
                        {
                          label: 'Retail Unit Session Percentage',
                          data: _retailUnitSessionPercentage.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Unit Session Percentage',
                          data: _corporateUnitSessionPercentage.current.slice(firstIndex,lastIndex),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailUnitSessionPercentage.current.slice(firstIndex,lastIndex));
                    av2=findAverageCurrentData(_corporateUnitSessionPercentage.current.slice(firstIndex,lastIndex));
                }
                else if (sessionType == "corporateUnitSessionPercentage"){
                    
                    dat = [
                        {
                          label: 'Business Unit Session Percentage',
                          data: _corporateUnitSessionPercentage.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateUnitSessionPercentage.current.slice(firstIndex,lastIndex));
                }
                else if (sessionType == "mobileAppPageViews"){
                    
                  dat = [
                      {
                        label: 'Retail Mobile App Page Views',
                        data: _retailMobileAppPageViews.current.slice(firstIndex,lastIndex),
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Mobile App Page Views',
                        data: _corporateMobileAppPageViews.current.slice(firstIndex,lastIndex),
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(_retailMobileAppPageViews.current.slice(firstIndex,lastIndex));
                  av2=findAverageCurrentData(_corporateMobileAppPageViews.current.slice(firstIndex,lastIndex));
                }
                else if (sessionType == "corporateMobileAppPageViews"){
                    
                  dat = [
                      {
                        label: 'Business Mobile App Page Views',
                        data: _corporateMobileAppPageViews.current.slice(firstIndex,lastIndex),
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(_corporateMobileAppPageViews.current.slice(firstIndex,lastIndex));
                }
                else if (sessionType == "browserPageViews"){
                    
                  dat = [
                      {
                        label: 'Retail Browser Page Views',
                        data: _retailBrowserPageViews.current.slice(firstIndex,lastIndex),
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Browser Page Views',
                        data: _corporateBrowserPageViews.current.slice(firstIndex,lastIndex),
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(_retailBrowserPageViews.current.slice(firstIndex,lastIndex));
                  av2=findAverageCurrentData(_corporateBrowserPageViews.current.slice(firstIndex,lastIndex));
                }
                else if (sessionType == "corporateBrowserPageViews"){
                    
                  dat = [
                      {
                        label: 'Business Browser Page Views',
                        data: _corporateBrowserPageViews.current.slice(firstIndex,lastIndex),
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(_corporateBrowserPageViews.current.slice(firstIndex,lastIndex));
                }
                else if (sessionType == "totalPageViews"){
                    
                  dat = [
                      {
                        label: 'Total Retail Page Views',
                        data: _totalRetailPageViews.current.slice(firstIndex,lastIndex),
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Total Business Page Views',
                        data: _totalBusinessPageViews.current.slice(firstIndex,lastIndex),
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(_totalRetailPageViews.current.slice(firstIndex,lastIndex));
                  av2=findAverageCurrentData(_totalBusinessPageViews.current.slice(firstIndex,lastIndex));
                }
                else if (sessionType == "totalBusinessPageViews"){
                    
                  dat = [
                      {
                        label: 'Total Business Page Views',
                        data: _totalBusinessPageViews.current.slice(firstIndex,lastIndex),
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(_totalBusinessPageViews.current.slice(firstIndex,lastIndex));
                }
                  
                  
      
                  const chartData = {
                      labels : lineChartLabels.slice(firstIndex,lastIndex),
                    datasets: dat,
                  };
              
                  setIsLoading(false);
                  setSelectValue("thisMonth");
                  setOrderedProducts(orderedProducts);
                  setChartData(chartData);
                  setAverages(av1,av2);
                  break;
              }
              
              //ANDREW: THIS ALGO IS TO SHOW ALL DATES REGARDLESS IF THE VALUE IS ZERO
              case 'lastOneYear': {
                let filteredData = [];
                const now = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const lastYearDate = new Date(now);
                lastYearDate.setFullYear(now.getFullYear() - 1);
              
                const firstDayOfLastYear = moment(lastYearDate).format('MMM D, y');
                const lastDayOfYear = moment(now).format('MMM D, y');
              
                let firstIndex = lineChartLabels.indexOf(firstDayOfLastYear);
                let lastIndex = lineChartLabels.indexOf(lastDayOfYear);
              
                if (firstIndex === -1) firstIndex = 0;
                if (lastIndex === -1) lastIndex = lineChartLabels.length - 1;
              
                // Generate all dates from last year to now
                const allDates = generateDateRange(lastYearDate, now);
              
                // Initialize data arrays with zeros
                let sessionsData = Array(allDates.length).fill(0);
                let mobileAppSessionsData = Array(allDates.length).fill(0);
                //new data variables
                let retailUnitSessionPercentageData = Array(allDates.length).fill(0);
                let corporateUnitSessionPercentageData = Array(allDates.length).fill(0);
                let retailMobileAppPageViewsData = Array(allDates.length).fill(0);
                let corporateMobileAppPageViewsData = Array(allDates.length).fill(0);
                let retailBrowserPageViewsData = Array(allDates.length).fill(0);
                let corporateBrowserPageViewsData = Array(allDates.length).fill(0);
                let totalRetailPageViewsData = Array(allDates.length).fill(0);
                let totalBusinessPageViewsData = Array(allDates.length).fill(0);

                
              
                // Fill data arrays with actual data where available
                for (let i = 0; i < allDates.length; i++) {
                  let dateIndex = lineChartLabels.indexOf(allDates[i]);
                  if (dateIndex !== -1) {
                    sessionsData[i] = _sessions.current[dateIndex] || 0;
                    mobileAppSessionsData[i] = _mobileAppSessions.current[dateIndex] || 0;

                    retailUnitSessionPercentageData[i] = _retailUnitSessionPercentage.current[dateIndex] || 0;
                    corporateUnitSessionPercentageData[i] = _corporateUnitSessionPercentage.current[dateIndex] || 0;
                    retailMobileAppPageViewsData[i] = _retailMobileAppPageViews.current[dateIndex] || 0;
                    corporateMobileAppPageViewsData[i] = _corporateMobileAppPageViews.current[dateIndex] || 0;
                    retailBrowserPageViewsData[i] = _retailBrowserPageViews.current[dateIndex] || 0;
                    corporateBrowserPageViewsData[i] = _corporateBrowserPageViews.current[dateIndex] || 0;
                    totalRetailPageViewsData[i] = _totalRetailPageViews.current[dateIndex] || 0;
                    totalBusinessPageViewsData[i] = _totalBusinessPageViews.current[dateIndex] || 0;
                    
                  }
                }
              
                let dat;
                let av1, av2;


                if (sessionType == "unitSessionPercentage"){
                    dat = [
                        {
                          label: 'Retail Unit Session Percentage',
                          data:retailUnitSessionPercentageData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Unit Session Percentage',
                          data:corporateUnitSessionPercentageData,
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(retailUnitSessionPercentageData);
                    av2=findAverageCurrentData(corporateUnitSessionPercentageData);
                }
                else if (sessionType == "corporateUnitSessionPercentage"){
                    
                    dat = [
                        {
                          label: 'Business Unit Session Percentage',
                          data:corporateUnitSessionPercentageData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(corporateUnitSessionPercentageData);
                }
                else if (sessionType == "mobileAppPageViews"){
                    
                  dat = [
                      {
                        label: 'Retail Mobile App Page Views',
                        data: retailMobileAppPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Mobile App Page Views',
                        data: corporateMobileAppPageViewsData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(retailMobileAppPageViewsData);
                  av2=findAverageCurrentData(corporateMobileAppPageViewsData);
                }
                else if (sessionType == "corporateMobileAppPageViews"){
                    
                  dat = [
                      {
                        label: 'Business Mobile App Page Views',
                        data: corporateMobileAppPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(corporateMobileAppPageViewsData);
                }
                else if (sessionType == "browserPageViews"){
                    
                  dat = [
                      {
                        label: 'Retail Browser Page Views',
                        data: retailBrowserPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Browser Page Views',
                        data: corporateBrowserPageViewsData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(retailBrowserPageViewsData);
                  av2=findAverageCurrentData(corporateBrowserPageViewsData);
                }
                else if (sessionType == "corporateBrowserPageViews"){
                    
                  dat = [
                      {
                        label: 'Business Browser Page Views',
                        data: corporateBrowserPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(corporateBrowserPageViewsData);
                }
                else if (sessionType == "totalPageViews"){
                    
                  dat = [
                      {
                        label: 'Total Retail Page Views',
                        data: totalRetailPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Total Business Page Views',
                        data: totalBusinessPageViewsData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(totalRetailPageViewsData);
                  av2=findAverageCurrentData(totalBusinessPageViewsData);
                }

                else if (sessionType == "totalBusinessPageViews"){
                      
                  dat = [
                      {
                        label: 'Total Business Page Views',
                        data: totalBusinessPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(totalBusinessPageViewsData);
                }
                
              
                const chartData = {
                  labels: allDates,
                  datasets: dat,
                };
              
                setIsLoading(false);
                setSelectValue("lastOneYear");
                setOrderedProducts(orderedProducts);
                setChartData(chartData);
                setAverages(av1, av2);
                break;
              }
              
              case 'lastTwoYears': {
                let filteredData = [];
                const now = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const twoYearsAgoDate = new Date(now);
                twoYearsAgoDate.setFullYear(now.getFullYear() - 2);
              
                const firstDayOfTwoYearsAgo = moment(twoYearsAgoDate).format('MMM D, y');
                const lastDayOfYear = moment(now).format('MMM D, y');
              
                let firstIndex = lineChartLabels.indexOf(firstDayOfTwoYearsAgo);
                let lastIndex = lineChartLabels.indexOf(lastDayOfYear);
              
                if (firstIndex === -1) firstIndex = 0;
                if (lastIndex === -1) lastIndex = lineChartLabels.length - 1;
              
                // Generate all dates from two years ago to now
                const allDates = generateDateRange(twoYearsAgoDate, now);
              
                // Initialize data arrays with zeros
                let sessionsData = Array(allDates.length).fill(0);
                let mobileAppSessionsData = Array(allDates.length).fill(0);
                //new data variables
                let retailUnitSessionPercentageData = Array(allDates.length).fill(0);
                let corporateUnitSessionPercentageData = Array(allDates.length).fill(0);
                let retailMobileAppPageViewsData = Array(allDates.length).fill(0);
                let corporateMobileAppPageViewsData = Array(allDates.length).fill(0);
                let retailBrowserPageViewsData = Array(allDates.length).fill(0);
                let corporateBrowserPageViewsData = Array(allDates.length).fill(0);
                let totalRetailPageViewsData = Array(allDates.length).fill(0);
                let totalBusinessPageViewsData = Array(allDates.length).fill(0);
              
                // Fill data arrays with actual data where available
                for (let i = 0; i < allDates.length; i++) {
                  let dateIndex = lineChartLabels.indexOf(allDates[i]);
                  if (dateIndex !== -1) {
                    sessionsData[i] = _sessions.current[dateIndex] || 0;
                    mobileAppSessionsData[i] = _mobileAppSessions.current[dateIndex] || 0;
                    retailUnitSessionPercentageData[i] = _retailUnitSessionPercentage.current[dateIndex] || 0;
                    corporateUnitSessionPercentageData[i] = _corporateUnitSessionPercentage.current[dateIndex] || 0;
                    retailMobileAppPageViewsData[i] = _retailMobileAppPageViews.current[dateIndex] || 0;
                    corporateMobileAppPageViewsData[i] = _corporateMobileAppPageViews.current[dateIndex] || 0;
                    retailBrowserPageViewsData[i] = _retailBrowserPageViews.current[dateIndex] || 0;
                    corporateBrowserPageViewsData[i] = _corporateBrowserPageViews.current[dateIndex] || 0;
                    totalRetailPageViewsData[i] = _totalRetailPageViews.current[dateIndex] || 0;
                    totalBusinessPageViewsData[i] = _totalBusinessPageViews.current[dateIndex] || 0;
                  }
                }
              
                let dat;
                let av1, av2;


                if (sessionType == "unitSessionPercentage"){
                    dat = [
                        {
                          label: 'Retail Unit Session Percentage',
                          data:retailUnitSessionPercentageData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Unit Session Percentage',
                          data:corporateUnitSessionPercentageData,
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(retailUnitSessionPercentageData);
                    av2=findAverageCurrentData(corporateUnitSessionPercentageData);
                }
                else if (sessionType == "corporateUnitSessionPercentage"){
                    
                    dat = [
                        {
                          label: 'Business Unit Session Percentage',
                          data:corporateUnitSessionPercentageData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(corporateUnitSessionPercentageData);
                }
                else if (sessionType == "mobileAppPageViews"){
                    
                  dat = [
                      {
                        label: 'Retail Mobile App Page Views',
                        data: retailMobileAppPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Mobile App Page Views',
                        data: corporateMobileAppPageViewsData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(retailMobileAppPageViewsData);
                  av2=findAverageCurrentData(corporateMobileAppPageViewsData);
                }
                else if (sessionType == "corporateMobileAppPageViews"){
                    
                  dat = [
                      {
                        label: 'Business Mobile App Page Views',
                        data: corporateMobileAppPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(corporateMobileAppPageViewsData);
                }
                else if (sessionType == "browserPageViews"){
                    
                  dat = [
                      {
                        label: 'Retail Browser Page Views',
                        data: retailBrowserPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Browser Page Views',
                        data: corporateBrowserPageViewsData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(retailBrowserPageViewsData);
                  av2=findAverageCurrentData(corporateBrowserPageViewsData);
                }
                else if (sessionType == "corporateBrowserPageViews"){
                    
                  dat = [
                      {
                        label: 'Business Browser Page Views',
                        data: corporateBrowserPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(corporateBrowserPageViewsData);
                }
                else if (sessionType == "totalPageViews"){
                    
                  dat = [
                      {
                        label: 'Total Retail Page Views',
                        data: totalRetailPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Total Business Page Views',
                        data: totalBusinessPageViewsData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(totalRetailPageViewsData);
                  av2=findAverageCurrentData(totalBusinessPageViewsData);
                }

                else if (sessionType == "totalBusinessPageViews"){
                      
                  dat = [
                      {
                        label: 'Total Business Page Views',
                        data: totalBusinessPageViewsData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(totalBusinessPageViewsData);
                }
              
                const chartData = {
                  labels: allDates,
                  datasets: dat,
                };
              
                setIsLoading(false);
                setSelectValue("lastTwoYears");
                setOrderedProducts(orderedProducts);
                setChartData(chartData);
                setAverages(av1, av2);
                break;
              }
          }
        }

      function generateDateRange(startDate, endDate) {
        let dates = [];
        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
          dates.push(moment(currentDate).format('MMM D, y'));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
      }


      function handleDropdownChange (e){
    
          _filterBy.current = e.target.value;
          filter();
          
      }
      
      function handleDropdownChangeSessionType  (e){
          
          _sessionType.current = e.target.value;
          filter();
          
      }
    
    return (
        
       
                    <Flex
                        gap="32px"
                        direction="column"
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        overflow="hidden"
                        position="relative"
                        
                        >
                        <Flex
                            gap="8px"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            <Text
                            fontFamily="Inter"
                            fontSize="32px"
                            fontWeight="500"
                            color="rgba(13,26,38,1)"
                            lineHeight="40px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="Traffic Report"
                            
                            ></Text>
                        </Flex>
                        <Flex
                            gap="32px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="center"
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            
                                {averages1}
                                
                            
                           
                               {averages2}
                               
                            
                            {/* <Flex
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                            >
                                <div>Average for Ordered Product Sales Not B2B 
              {currency} {orderedProductSalesNotB2BModel}
            </div>
                            </Flex> */}
                        </Flex>

                        <Flex
                        gap="48px"
                        direction="column"
                        width="100%"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        position="relative"
                        alignSelf="stretch"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                    
                        >
                            <Flex
                            gap="13%" //TODO make it 32px if filter by date and asin is back
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"//TODO: revert back to space-between
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 7px 0px 40px"
                            >

                            {  /* <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="300px"
                                        height="unset"
                                        label="Type"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        onChange={moveAsinView}
                                    >
                                      <option value="date">By Date</option>
                                      <option value="asin">By ASIN</option>
                                    </SelectField>
                                </Flex>*/}

                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="300px"
                                        height="unset"
                                        label="Type"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        value={subscriptionPlan || 'loading'}
                                        label="Filter By Date" value={selectValue}  onChange={handleDropdownChange}
                                      >
                                        {subscriptionLoading ? (
                                            <option value="loading" disabled>
                                                <Loader size="small" /> Loading...
                                            </option>
                                        ) : (
                                            <>
                                              <option value="lastSevenDays">Last 7 Days</option>
                                              <option value="lastThirtyDays">Last 30 Days</option>
                                              <option value="thisWeek">This Week</option>
                                              <option value="thisMonth">This Month</option>
                                              {subscriptionPlan !== "Free Tier" && (
                                                <>
                                                  <option value="lastOneYear">Last 1 Year</option>
                                                  <option value="lastTwoYears">Last 2 Years</option>
                                                </>
                                              )}
                                          </>
                                        )}
                                      </SelectField>
                                </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Filter By"
                                        
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                    
                                        onChange={handleDropdownChange}
                                       
                                        label="Session Type"  onChange={handleDropdownChangeSessionType}
                                      >
                                          <option value="unitSessionPercentage">Unit Session Percentage</option>
                                          <option value="mobileAppPageViews">Mobile App Page Views</option>
                                          <option value="browserPageViews">Browser Page Views</option>
                                          <option value="totalPageViews">Total Page Views</option>
                                      </SelectField>
                                </Flex>
                               {/*  <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                     <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Sort By"
                                        placeholder="Unit Session %"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        
                                    ></SelectField> 
                                 </Flex> */}
                            </Flex>
                            
                             {isLoading ? <Flex/> : <Line options={lineChartOptions} data={chartData} width="400" height="100" />}
           
                            
            
                  
                        </Flex> 
                        <DialogModal open={dialogModalOpen} message={dialogModalMessage} onClose={handleCloseModal} />
                    </Flex>          
                
        
    );
}

export default withAuthenticator(TrafficReport);