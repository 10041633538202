import React, { useState } from 'react';
import { Flex, Text } from "@aws-amplify/ui-react";
import { getOverrideProps } from "./utils";
import ForecastsDataRowPagination from "./ForecastsDataRowPagination";

const Inventory = ({ data, itemsPerPage = 10, overrides = {} }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, totalPages));
  };

  const handlePrevPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  const rowHeight = 50; 
  const headerHeight = 65;
  const paginationHeight = 60;

  const tableHeight = headerHeight + (currentItems.length * rowHeight) + paginationHeight;

  return (
    <Flex
      gap="0"
      direction="column"
      width="100%"
      maxWidth="100%" 
      height={`${tableHeight}px`}
      justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      {...getOverrideProps(overrides, "Inventory")}
    >
      <Flex
        gap="0"
        direction="column"
        width="100%"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        overflow="hidden"
        grow="1"
        shrink="1"
        basis="0"
        position="relative"
        border="1px SOLID rgba(174,179,183,1)"
        borderRadius="12px"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "InventoryForecastsTablew/outfields")}
      >
        {/* Header Row */}
        <Flex
          gap="0"
          direction="row"
          width="100%"
          height="65px"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          border="1px SOLID rgba(174,179,183,1)"
          padding="0px 15px 0px 15px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "ForecastsDataRowTitle")}
        >
          <HeaderCell label="ASIN" overrides={overrides} />
          <HeaderCell label="SKU" overrides={overrides} />
          <HeaderCell label="Currently Available&#xA;Quantity" overrides={overrides} />
        </Flex>

        {/* Data Rows */}
        {currentItems.map((item, index) => (
          <DataRow key={index} item={item} overrides={overrides} />
        ))}

        {/* Pagination */}
        <ForecastsDataRowPagination
          overrides={{
            Details: {
              children: `${indexOfFirstItem + 1}-${indexOfLastItem} of ${data.length}`,
            },
            "Button38769536": {
              onClick: handlePrevPage,
              isDisabled: currentPage === 1,
            },
            "Button38769540": {
              onClick: handleNextPage,
              isDisabled: currentPage === totalPages,
            },
            ForecastsDataRowPagination: {
              width: "100%", 
            },
            ...overrides.ForecastsDataRowPagination
          }}
        />
      </Flex>
    </Flex>
  );
};

const HeaderCell = ({ label, overrides }) => (
  <Flex
    gap="0" // Remove gap
    direction="row"
    width="unset"
    height="unset"
    justifyContent="center" // Center text horizontally
    alignItems="center"
    grow="1"
    shrink="1"
    basis="0"
    alignSelf="stretch"
    position="relative"
    padding="10px 0px" // Padding top and bottom only
    {...getOverrideProps(overrides, `Field${label.replace(/\s+/g, '')}`)}
  >
    <Text
      fontFamily="Inter"
      fontSize="12px"
      fontWeight="400"
      color="rgba(13,26,38,1)"
      lineHeight="18px"
      textAlign="center" // Center text
      display="block"
      direction="column"
      justifyContent="unset"
      width="unset"
      height="unset"
      gap="unset"
      alignItems="unset"
      grow="1"
      shrink="1"
      basis="0"
      position="relative"
      padding="0px" // No padding in the text itself
      whiteSpace="pre-wrap"
      children={label}
      {...getOverrideProps(overrides, `label${label.replace(/\s+/g, '')}`)}
    />
  </Flex>
);

const DataRow = ({ item, overrides }) => (
  <Flex
    gap="0"
    direction="row"
    width="unset"
    height="unset"
    justifyContent="center" // Center text horizontally
    alignItems="center"
    grow="1"
    shrink="1"
    basis="0"
    alignSelf="stretch"
    position="relative"
    border="1px SOLID rgba(239,240,240,1)"
    padding="0px 15px 0px 15px"
    backgroundColor="rgba(255,255,255,1)"
    {...getOverrideProps(overrides, "ForecastsDataRow")}
  >
    <DataCell value={item.asin} overrides={overrides} />
    <DataCell value={item.sku} overrides={overrides} />
    <DataCell value={item.availableQuantity} overrides={overrides} />
  </Flex>
);

const DataCell = ({ value, overrides }) => (
  <Flex
    gap="0" // Remove gap
    direction="row"
    width="unset"
    height="unset"
    justifyContent="center" // Center text horizontally
    alignItems="center"
    grow="1"
    shrink="1"
    basis="0"
    alignSelf="stretch"
    position="relative"
    padding="10px 0px" // Padding top and bottom only
    {...getOverrideProps(overrides, `Field${value}`)}
  >
    <Text
      fontFamily="Inter"
      fontSize="14px"
      fontWeight="400"
      color="rgba(13,26,38,1)"
      lineHeight="24px"
      textAlign="center"  // Center text
      display="block"
      direction="column"
      justifyContent="unset"
      width="unset"
      height="unset"
      gap="unset"
      alignItems="unset"
      grow="1"
      shrink="1"
      basis="0"
      alignSelf="stretch"
      position="relative"
      padding="0px" // No padding in the text itself
      whiteSpace="pre-wrap"
      children={value}
      {...getOverrideProps(overrides, `label${value}`)}
    />
  </Flex>
);

export default Inventory;
