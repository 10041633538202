
/* eslint-disable */
import * as React from "react";
import { useState,useEffect,useRef } from 'react';
import { Amplify, Auth, API, Storage, Cache } from 'aws-amplify';
import LogoWithText from "../ui-components/LogoWithText";
import MyIcon from "../ui-components/MyIcon";
import { Flex, Icon, Image, Text, Menu, MenuButton, MenuItem, Link } from "@aws-amplify/ui-react";
import { getUser } from '../graphql/queries';
import  { useNavigate } from 'react-router'

export default function NavBar(props) {
  const { overrides } = props;
  const [region, setRegion] = useState("");
  const [market, setMarket] = useState("");  
  const [name, setName] = useState(""); 
  const [menuItems, setMenuItems] = useState<Element[]>([]);
  const navigate = useNavigate();
    useEffect(() => {
        fetchUser();
    },[]);// <-- Have to pass in [] here or is called on every render

    const handleHomePageRedirect = () => {
      navigate('/sales-report')
    };

    async function fetchUser() {
        
        if(Cache.getItem('marketCode')){
            console.log(Cache.getItem('marketCode'))
            setMarket("Select Marketplace: "+Cache.getItem('marketCountryCode'));
        }
        else {
            Cache.setItem('marketCode',"ATVPDKIKX0DER");
            Cache.setItem('marketCountryCode',"US");
            setMarket("Market: US");
        }

        let resultMarketplace;
        if(Cache.getItem('user')){
          let mp = Cache.getItem('user').Marketplace;
          if(mp){
            resultMarketplace = JSON.parse(mp);
          }
          
        }
        else {
          try{
            let cognitoUser = await Auth.currentAuthenticatedUser();
            const { attributes } = cognitoUser;
            const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});
            if(result['data'].getUser){
              let mp = result['data'].getUser.Marketplace;
              if(mp){
                  resultMarketplace = JSON.parse(mp);
              }

              Cache.setItem('user', result['data'].getUser);
            }
            else {
             
            }
          }catch (error) {
            console.log(error);
          }
        }
        const user = Cache.getItem('user');
        
        if(user && ('firstName' in user && 'lastName' in user)){
          setName(user['firstName']+' '+user['lastName']);
        }
        var componentList = [];
        
        // let tempList:String[] = [];
        // let tempListMarketIds:String[] = [];
        
        for(let i in resultMarketplace)
            {
            for(let j in resultMarketplace[i]){
                
                //tempList.push(resultMarketplace[i][j])
                componentList.push(
                  <MenuItem 
                    onClick={() => chooseMarket(resultMarketplace[i][j])} 
                    id={resultMarketplace[i][j].id} 
                    key={resultMarketplace[i][j].id}>{resultMarketplace[i][j].countryCode}
                  </MenuItem>)
            }
        }

        setMenuItems(componentList);
    }

    function chooseMarket(_market){
        Cache.setItem('marketCode', _market.id);
        Cache.setItem('marketCurrency', _market.defaultCurrencyCode);
        Cache.setItem('marketCountryCode', _market.countryCode);
        window.location.reload();
    }

  return (
    <Flex
      
      direction="row"
      width="100vw"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      boxShadow="0px 2px 6px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.15000000596046448)"
      padding="24px 32px 24px 32px"
      backgroundColor="rgba(255,255,255,1)"
      
    >
      
      <img src="horizontal-aitium-logo.png" width="10%" style={{ cursor: "pointer" }} onClick={handleHomePageRedirect}/>
    
      <Flex
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          
        >
          <MyIcon
            borderStyle ="unset"
            width="40px"
            height="40px"
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            overflow="hidden"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            type="Market"
            
          ></MyIcon>
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-end"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="4px"
            padding="8px 16px 8px 0px"
            
          >
            <Menu
    trigger={
      <MenuButton   
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0, 96, 122, 1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={market}
              
            >
      </MenuButton>
    }
  > {menuItems}
</Menu>
            <Icon
              width="24px"
              height="24px"
              display="block"
              gap="unset"
              alignItems="unset"
              justifyContent="unset"
              overflow="hidden"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              type="chevron_down"
              fontSize="24px"></Icon>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="unset"
          height="unset"
          justifyContent="flex-end"
          alignItems="center"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          
        >
         
          <Flex
            gap="10px"
            direction="row"
            width="unset"
            height="unset"
            justifyContent="flex-end"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            borderRadius="4px"
            padding="8px 16px 8px 16px"
            
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0, 96, 122, 1)"
              lineHeight="24px"
              textAlign="left"
              display="block"
              direction="column"
              justifyContent="unset"
              width="unset"
              height="unset"
              gap="unset"
              alignItems="unset"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={name}
              
            ></Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
