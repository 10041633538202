/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import MyIcon from "./MyIcon";
import { Flex, Icon, Text } from "@aws-amplify/ui-react";
export default function ButtonWithIcon2(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: {
        "Button Icon449513195": {},
        label: {},
        "Button Icon449513197": {},
        ButtonWithIcon2: {},
      },
      variantValues: { state: "Default", type: "Authorized" },
    },
    {
      overrides: {
        "Button Icon449513195": {},
        label: { children: "Authorize Now" },
        "Button Icon449513197": {},
        ButtonWithIcon2: { backgroundColor: "rgba(0,96,122,1)" },
      },
      variantValues: { state: "Default", type: "AuthorizeNow" },
    },
    {
      overrides: {
        "Button Icon449513195": { type: "Add" },
        label: { children: "New Forecast" },
        "Button Icon449513197": {},
        ButtonWithIcon2: { backgroundColor: "rgba(0,96,122,1)" },
      },
      variantValues: { state: "Default", type: "NewForecast" },
    },
    {
      overrides: {
        "Button Icon449513195": {},
        label: {},
        "Button Icon449513197": {},
        ButtonWithIcon2: { backgroundColor: "rgba(63,125,74,1)" },
      },
      variantValues: { state: "Hover", type: "Authorized" },
    },
    {
      overrides: {
        "Button Icon449513195": {},
        label: { children: "Authorize Now" },
        "Button Icon449513197": {},
        ButtonWithIcon2: { backgroundColor: "rgba(0,72,92,1)" },
      },
      variantValues: { state: "Hover", type: "AuthorizeNow" },
    },
    {
      overrides: {
        "Button Icon449513195": { type: "Add" },
        label: { children: "New Forecast" },
        "Button Icon449513197": {},
        ButtonWithIcon2: { backgroundColor: "rgba(0,72,92,1)" },
      },
      variantValues: { state: "Hover", type: "NewForecast" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="12px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="center"
      alignItems="center"
      position="relative"
      border="1px SOLID rgba(0,0,0,0)"
      borderRadius="4px"
      padding="7px 15px 7px 15px"
      backgroundColor="rgba(67,168,84,1)"
      display="flex"
      {...getOverrideProps(overrides, "ButtonWithIcon2")}
      {...rest}
    >
      <MyIcon
        width="22px"
        height="22px"
        display="block"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="checkmark"
        {...getOverrideProps(overrides, "Button Icon449513195")}
      ></MyIcon>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="700"
        color="rgba(255,255,255,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Authorized"
        {...getOverrideProps(overrides, "label")}
      ></Text>
      <Icon
        width="16px"
        height="16px"
        display="none"
        gap="unset"
        alignItems="unset"
        justifyContent="unset"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        type="none"
        fontSize="16px"
        {...getOverrideProps(overrides, "Button Icon449513197")}
      ></Icon>
    </Flex>
  );
}
