import React from 'react'
import { useState,useEffect,useRef } from 'react';
import { Amplify, Auth, API, Storage, Cache } from 'aws-amplify';
import {Flex, View, Button,Text,
    Divider,Radio,SelectField,TextField,Icon, Link} from '@aws-amplify/ui-react';
import  NavBar  from '../components/navbar.tsx';

import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';

import Selectedbutton from '../ui-components/SelectedButton';
import Partners from '../ui-components/Partners';
import CorporateAnalyticsReport from './corporate-analytics-report.tsx'
import CorporateAnalyticsReportAsin from './corporate-analytics-report-asin.tsx'
import GeneralFooter from '../ui-components/GeneralFooter.jsx';

Amplify.configure(awsconfig);

function CorporateAnalyticsReportContainer({signOut, user}) {
    
    const [dateOrAsin, setDateOrAsin] = useState(<Flex></Flex>);
    
    useEffect(() => {
      setDateOrAsin(<CorporateAnalyticsReport changeView={changeView}/> );
    },[]);// <-- Have to pass in [] here or is called on every render
    
    function changeView(view){
      
      if(view == 'asin'){
        setDateOrAsin(<CorporateAnalyticsReportAsin changeView={changeView}/> );
      }
      else {
        setDateOrAsin(<CorporateAnalyticsReport changeView={changeView}/> );
      }
    }

    return (
        <div  style={{ width: "100%" }}>
        <Flex 
            paddingTop={'20px'}
            paddingLeft={'30px'}
            paddingRight={'30px'}
            overflow={'auto'}>
                    {dateOrAsin}
                </Flex>
                <GeneralFooter/>
            </div>
    );
}

export default withAuthenticator(CorporateAnalyticsReportContainer);