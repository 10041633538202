import React from 'react'
import { useState,useEffect } from 'react';
import { Amplify, Auth, API,Storage  } from 'aws-amplify';

import { withAuthenticator, Flex, Text } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ChartDataset,LabelItem, Color, CategoryScale, LinearScale, PointElement, LineElement, Tooltip} from 'chart.js';
import moment from 'moment';
import papa from 'papaparse';
import ForecastChartLegend from '../ui-components/ForecastChartLegend';
import { getForecastJob, getIngestJob } from '../graphql/queries';
import zoomPlugin from 'chartjs-plugin-zoom';

Amplify.configure(awsconfig);

function ForecastView({forecastJobId, ingestJobId}) {
    
    const [isLoading, setIsLoading] = useState(true);
    const [chartData,setChartData]= useState({});
    const [ingestJ,setIngestJ]= useState({});
    let title: string = "";
    let data: any;
    let historicURL: any;
    let forecastURL: any;
    let historic: any;
    let forecast: any;
    let historicData = new Array<Number | undefined>();
    let pTenData = new Array<Number | undefined>();
    let pFiftyData = new Array<Number | undefined>();
    let pNinetyData = new Array<Number | undefined>();
    let metricDates = new Array<String>();
    let annoValue = ""
    let metric = ""

    let  lineChartData: any[] = [];
    let  lineChartLabels: String[] = [];
    let  lineChartOptions: any = {};
    // let  lineChartColors: Color[] = [
    //     {
    //     borderColor: '#3c3e3d',
    //     backgroundColor: 'rgba(0,0,0,0)',
    //     },
    //     {
    //     borderColor: '#007798',
    //     backgroundColor: 'rgba(255,255,255,0)',
    //     },
    //     {
    //     borderColor: '#427f3d',
    //     backgroundColor: 'rgba(0,255,0,0)',
    //     },
    //     {
    //     borderColor: '#007798',
    //     backgroundColor: 'rgba(0,0,255,0)',
    //     },
    // ];
    let  lineChartLegend = false;
    let  lineChartType = 'line';
    // let  lineChartPlugins = [{pluginAnnotation,}]

     const [headerDescriptionText, setHeaderDescriptionText] = useState("Daily Revenue Data");

    const forecastChartStyle = {
      header: {
        "font-weight": "bold",
        "font-family": "Inter",
        "font-size": "35px"
      },
      headerDescription: {
        "font-family": "Inter",
        "font-size": "18px",
        "font-weight": "bold",
        "color": "#304050"
      }
    };


    useEffect(() => {
        ChartJS.register(CategoryScale,LinearScale, PointElement, LineElement,zoomPlugin, Tooltip);
        
        historicData = new Array<Number | undefined>();
        pTenData = new Array<Number | undefined>();
        pFiftyData = new Array<Number | undefined>();
        pNinetyData = new Array<Number | undefined>();
        metricDates = new Array<String>();
        fetchForecast();
    },[]);// <-- Have to pass in [] here or is called on every render
    
    async function fetchForecast() {
        let user = await Auth.currentAuthenticatedUser()
        distributeData().then(() =>{
            
            lineChartData = [
                { data: historicData, label: 'Historic', borderColor: '#3c3e3d'},
                { data: pTenData, label: 'p10', borderColor: '#007798'},
                { data: pFiftyData, label: 'p50', borderColor: '#427f3d'},
                { data: pNinetyData, label: 'p90', borderColor: '#007798'},
            ];
            lineChartLabels = metricDates;
            console.log(lineChartData);
            const chartData = {
                labels : lineChartLabels,
                datasets: lineChartData,
            };
            setChartData(chartData);
            setIsLoading(false);
            });
    }

    //get the historic and forecast data and push the data into a local array
    async function distributeData(){
        const ff = await API.graphql({ query: getForecastJob , variables:{id:forecastJobId}});
        const forecastJob = ff["data"]["getForecastJob"];

        const ii = await API.graphql({ query: getIngestJob , variables:{id:ingestJobId}});
        const ingestJob = ii["data"]["getIngestJob"];
        setIngestJ(ingestJob);
        
        let forecastKey = forecastJob.id+"/"+forecastJob["resultFile"]["key"];
        let historicKey = ingestJob["resultFile"]["key"];
        
        const historicURL =  await Storage.vault.get(historicKey, { download: true });
        const forecastURL =  await Storage.vault.get(forecastKey, { download: true });
        const hist = await new Response(historicURL.Body);
        const fore = await new Response(forecastURL.Body);

        const histText = await hist.text()
        const foreText = await fore.text();

        historic = papa.parse(histText, {header: false});
        forecast = papa.parse(foreText, {header: true});
        
        for(let i in historic.data){
            if (+i+1 != historic.data.length){
                historicData.push(historic.data[i][2])
                
                metricDates.push(moment(new Date(historic.data[i][1])).format('MMM D, y'))
                pTenData.push(undefined) 
                pFiftyData.push(undefined)  
                pNinetyData.push(undefined)  
            }
        }
        
        for(let i in forecast.data){
            if (+i+1 != forecast.data.length){   
                metricDates.push(moment(new Date(forecast.data[i].date)).format('MMM D, y'))
                pTenData.push(forecast.data[i].p10) 
                pFiftyData.push(forecast.data[i].p50)  
                pNinetyData.push(forecast.data[i].p90) 
                historicData.push(undefined) 
            }
        }
        annoValue = moment(new Date(forecast.data[0].date)).format('MMM D, y');
        console.log(annoValue)
        // setYAxisCurrency(ingestJob["marketID"]);

        //TODO (Andrew): Need to get from Ekansh what will be the parameter to get the data from to distinguish the metric type if either Inventory or Revenue
        if(false)
          setHeaderDescriptionText("Daily Available Inventory");

        lineChartOptions = {
            responsive: true,
            showTooltips: true,
            
            scales: {
            yAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: "Revenue"
                },
                ticks:{
                callback: (value, index, values) => {
                    return setYAxisCurrency(value)
                },
            }
            }
            ]
        },
            annotation: {
            drawTime: 'afterDatasetsDraw',
            annotations: [
                {
                type: 'line',
                mode: 'vertical',
                scaleID: 'x-axis-0',
                value: annoValue,
                borderColor: 'orange',
                borderWidth: 2,
                label: {
                    position: 'center',
                    enabled: true,
                    color: 'orange',
                    content: 'Start of Forecast',
                    font: {
                    weight: 'bold'
                    }
                }
                }
            ],

            },
            plugins:{
              tooltip: {
                callbacks: {
                    label: function (tooltipItem, data) {
                        return " "+tooltipItem.dataset.label + " "+tooltipItem.formattedValue;
                    }
                }
            },
              // zoom: {
              //   zoom: {
              //     wheel: {
              //       enabled: true,
              //     },
              //     pinch: {
              //       enabled: true
              //     },
              //     mode: 'xy',
              //   }
              // },
            legend:{
                display:true,
                title:{
                display: true,
                text: "Example"
                }
            }
            },

        };
    }

    
    function setYAxisCurrency(value){
        //console.log(value)
        let market = ingestJob["marketID"];
        let currency, locale;
        switch(market){
          case 'BR':{
            locale='pt-BR'
            currency = 'BRL'
            break;
          }
          case 'MX':{
            locale='es-MX'
            currency = 'MXN'
            break;
          }
          case 'CA':{
            locale='en-CA'
            currency = 'CAD'
            break;
          }
          case 'ES':{
            locale='es-ES'
            currency = 'EUR'
            break;
          }
          case 'GB':{
            locale='en-GB'
            currency = 'GBP'
            break;
          }
          case 'FR':{
            locale='fr-FR'
            currency = 'EUR'
            break;
          }
          case 'NL':{
            locale='nl-NL'
            currency = 'EUR'
            break;
          }
          case 'DE':{
            locale='de-DE'
            currency = 'EUR'
            break;
          }
          case 'IT':{
            locale='it-IT'
            currency = 'EUR'
            break;
          }
          case 'SE':{
            locale='sv-SE'
            currency = 'SEK'
            break;
          }
          case 'PL':{
            locale='pl-PL'
            currency = 'PLN'
            break;
          }
          case 'EG':{
            locale='en-US'
            currency = 'EGP'
            break;
          }
          case 'TR':{
            locale='tr-TR'
            currency = 'TRY'
            break;
          }
          case 'SA':{
            locale='ar-SA'
            currency = 'SAR'
            break;
          }
          case 'AE':{
            locale='en-US'
            currency = 'AED'
            break;
          }
          case 'IN':{
            locale='en-IN'
            currency = 'INR'
            break;
          }
          case 'SG':{
            locale='en-US'
            currency = 'SGD'
            break;
          }
          case 'AU':{
            locale='en-AU'
            currency = 'AUD'
            break;
          }
          case 'JP':{
            locale='ja-JP'
            currency = 'JPY'
            break;
          }
          default:{
            locale='en-US'
            currency = 'USD'
          }
        }
        return value.toLocaleString( locale, {
          style: 'currency',
          currency: currency,
        })
      }

    return (
      isLoading ? <Flex /> : <Flex direction="column" alignItems="center">
      <img src="horizontal-aitium-logo.png" width="10%" />
      <Text style={forecastChartStyle.header} children="Visualize Forecast"></Text>
      <Text style={forecastChartStyle.headerDescription}  children={headerDescriptionText}></Text>
      <ForecastChartLegend />
      <Line options={lineChartOptions} data={chartData} width="400" height="100" />
    </Flex>
  );
}

export default withAuthenticator(ForecastView);



