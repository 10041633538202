/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";

export default function CustomContactSection(props) {
   
    const { overrides, ...rest } = props;


    const contactSection = {
        titleSection: {
          backgroundColor: "#F8F8F8",
          paddingLeft:'25px',
          paddingRight: '25px',
          paddingBottom: '40px'
        },
        container: {
          display: 'flex',
          justifyContent: 'space-between',
          fontFamily: 'Arial, sans-serif',
          padding: '40px',
          marginTop: '20px',
          maxWidth: '100%',
          margin: 'auto'
        },
        infoContainer: {
          flex: '1',
          marginLeft: '10%',
          display: 'block',
          marginRight: '50px'
        },
        formContainer: {
          flex: '1',
          padding: '20px',
          marginRight: '40px',
          display: 'block',
          borderRadius: '8px',
          padding: '45px',
          backgroundColor: '#f9f9f9'
        },
        header: {
          fontSize: '24px',
          fontWeight: 'bold',
          marginBottom: '10px'
        },
        subHeader: {
          fontSize: '16px',
          color: '#6c757d',
          marginBottom: '20px'
        },
        contactMethod: {
          marginBottom: '50px',
          display: 'block',
          alignItems: 'center',
          marginRight: '30px'
        },
        contactIcon: {
          marginRight: '10px'
        },
        contactText: {
          marginTop: "10px",
          display: 'flex',
          flexDirection: 'column',
          gap: '10px'
        },
        formGroup: {
          marginBottom: '15px',
          display: 'block',
          flex: '1'
        },
        label: {
          display: 'block',
          marginBottom: '5px',
          fontWeight: '500',
          color: "#304050"
        },
        input: {
          width: '100%',
          padding: '8px',
          border: '1px solid #ddd',
          borderRadius: '5px'
        },
        textArea: {
          width: '100%',
          padding: '8px',
          border: '1px solid #ddd',
          borderRadius: '5px',
          height: '100px'
        },
        checkboxLabel: {
          display: 'flex',
          alignItems: 'center',
          color: '#304050'
        },
        checkbox: {
          marginRight: '10px'
        },
        
        sendMessageButton: {
          padding: '10px 20px',
          backgroundColor: '#00607a',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: 'pointer',
          width: "100%",
          fontWeight: 'bold'
        },
        link: { 
          color: '#00607A',
          textDecoration: 'none'
        }
      };
    

  return (
    <Flex
        style={contactSection.container}
      {...getOverrideProps(overrides, "ContactSection")}
      {...rest}
    >
      <Flex
        style={contactSection.infoContainer}
        {...getOverrideProps(overrides, "InfoContainer")}
      >
        <Flex
         
          style={contactSection.contactMethod}
          {...getOverrideProps(overrides, "ContactMethodEmail")}
        >
          <Image
            src="email-icon.png"
            alt="email icon"
            style={contactSection.contactIcon}
            {...getOverrideProps(overrides, "ContactIconEmail")}
          />
          <Flex style={contactSection.contactText}>
            <Text
              fontWeight="500"
              fontSize="1.3em"
              {...getOverrideProps(overrides, "ContactMethodTitleEmail")}
            >
              By email
            </Text>
            <Text
              as="a"
              href="mailto:aitium@gaiiff.com"
              style={contactSection.link}
              {...getOverrideProps(overrides, "ContactMethodEmail")}
            >
              aitium@gaiiff.com
            </Text>
          </Flex>
        </Flex>

        <Flex
         
          style={contactSection.contactMethod}
          {...getOverrideProps(overrides, "ContactMethodPhone")}
        >
          <Image
            src="phone-icon.png"
            alt="phone icon"
            style={contactSection.contactIcon}
            {...getOverrideProps(overrides, "ContactIconPhone")}
          />
          <Flex direction="column" style={contactSection.contactText}>
            <Text
              fontWeight="500"
              fontSize="1.3em"
              {...getOverrideProps(overrides, "ContactMethodTitlePhone")}
            >
              By phone
            </Text>
            <Text
              as="a"
              href="tel:+11234567890"
              style={contactSection.link}
              {...getOverrideProps(overrides, "ContactMethodPhone")}
            >
              +1 571 200 5466
            </Text>
          </Flex>
        </Flex>

        <Flex
        
          style={contactSection.contactMethod}
          {...getOverrideProps(overrides, "ContactMethodLocation")}
        >
          <Image
            src="location-icon.png"
            alt="location icon"
            style={contactSection.contactIcon}
            {...getOverrideProps(overrides, "ContactIconLocation")}
          />
          <Flex direction="column" style={contactSection.contactText}>
            <Text
              fontWeight="500"
              fontSize="1.3em"
              {...getOverrideProps(overrides, "ContactMethodTitleLocation")}
            >
              In person
            </Text>
            <Text
              color="#5C6670"
              {...getOverrideProps(overrides, "ContactMethodLocation1")}
            >
              1350 Avenue of the Americas
            </Text>
            <Text
              color="#5C6670"
              {...getOverrideProps(overrides, "ContactMethodLocation2")}
            >
              Fl 2 -1040
            </Text>
            <Text
              color="#5C6670"
              {...getOverrideProps(overrides, "ContactMethodLocation3")}
            >
              New York NY 10019
            </Text>
            <Text
              color="#5C6670"
              {...getOverrideProps(overrides, "ContactMethodLocation3")}
            >
              United States
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex
       
        style={contactSection.formContainer}
        {...getOverrideProps(overrides, "FormContainer")}
      >
        <form>
          <Flex direction="row" width="100%">
            <Flex style={contactSection.formGroup}>
              <Text
                as="label"
                htmlFor="firstName"
                style={contactSection.label}
                {...getOverrideProps(overrides, "LabelFirstName")}
              >
                First name
              </Text>
              <input
                type="text"
                id="firstName"
                name="firstName"
                style={contactSection.input}
                {...getOverrideProps(overrides, "InputFirstName")}
              />
            </Flex>
            <Flex  style={contactSection.formGroup}>
              <Text
                as="label"
                htmlFor="lastName"
                style={contactSection.label}
                {...getOverrideProps(overrides, "LabelLastName")}
              >
                Last name
              </Text>
              <input
                type="text"
                id="lastName"
                name="lastName"
                style={contactSection.input}
                {...getOverrideProps(overrides, "InputLastName")}
              />
            </Flex>
          </Flex>
          <Flex style={contactSection.formGroup}>
            <Text
              as="label"
              htmlFor="email"
              style={contactSection.label}
              {...getOverrideProps(overrides, "LabelEmail")}
            >
              Email address
            </Text>
            <input
              type="email"
              id="email"
              name="email"
              style={contactSection.input}
              {...getOverrideProps(overrides, "InputEmail")}
            />
          </Flex>
          <Flex style={contactSection.formGroup}>
            <Text
              as="label"
              htmlFor="message"
              style={contactSection.label}
              {...getOverrideProps(overrides, "LabelMessage")}
            >
              Message
            </Text>
            <textarea
              id="message"
              name="message"
              style={contactSection.textArea}
              {...getOverrideProps(overrides, "TextAreaMessage")}
            />
          </Flex>
          <Flex style={contactSection.formGroup}>
            <label style={contactSection.checkboxLabel}>
              <input
                type="checkbox"
                id="privacyPolicy"
                name="privacyPolicy"
                style={contactSection.checkbox}
                {...getOverrideProps(overrides, "CheckboxPrivacyPolicy")}
              />
              You agree to our Privacy Policy
            </label>
          </Flex>
          <Button
            type="submit"
            style={contactSection.sendMessageButton}
            {...getOverrideProps(overrides, "SendMessageButton")}
          >
            Send message
          </Button>
        </form>
      </Flex>
    </Flex>
  );
}
