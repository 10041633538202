import React from 'react'
import { useState,useEffect,useRef } from 'react';
import { Amplify, Auth, API, Storage,Cache } from 'aws-amplify';
import {Flex, View, Button,Text,
    Divider,Radio,SelectField,TextField,Icon, Link} from '@aws-amplify/ui-react';
    import  NavBar  from '../components/navbar.tsx';
import studioTheme from '../ui-components/studioTheme';
import SideBar from '../ui-components/SideBar';
import AccountAccountInformation from '../ui-components/Forecasts'; 
import { withAuthenticator, Loader  } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
// import { getUser,getStripeCustomer } from '../graphql/queries';
import { createImportSpecifier } from 'typescript';
import Selectedbutton from '../ui-components/SelectedButton';
import ItemCard from '../ui-components/ItemCard';
import ItemCardNew from '../components/item-card.tsx';
import Partners from '../ui-components/Partners';
import moment from 'moment';
import SalesReportOptions from '../ui-components/SalesReportOptions';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ChartDataset,LabelItem, Color, CategoryScale, LinearScale, PointElement, LineElement, Tooltip} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { validateSubscription } from '../utils/subscriptionService.js';
import DialogModal from '../components/dialog-modal.tsx';
import  { useNavigate } from 'react-router'


Amplify.configure(awsconfig);

function ConversionReport({changeView}) {

    const navigate = useNavigate();
    
    const [isLoading, setIsLoading] = useState(true);
    const [lineChartOptions, setLineChartOptions] = useState({});
    const [asinIsLoading, setasinIsLoading] = useState(true);
    const [selectValue, setSelectValue] = useState("lastThirtyDays");
    const [orderedProducts, setOrderedProducts] = useState({});
    const [asinOrderedProducts, setAsinOrderedProducts] = useState({});
    const [chartData,setChartData]= useState({});
  
    const [averages1,setAverages1]= useState("");
    const [averages2,setAverages2]= useState("");

    const [subscriptionPlan,setSubscriptionPlan]= useState(null);
    const [subscriptionLoading, setSubscriptionLoading] = useState(true);
    
    const _orderedProductSales= useRef<Array<number>>([]);
    const _orderedProductSalesB2B = useRef<Array<number>>([]);
    const _orderedProductSalesNotB2B = useRef<Array<number>>([]);

    const _averageSalesPerOrderItem = useRef<Number[]>([]);
    const _averageSalesPerOrderItemB2B = useRef<Number[]>([]);
    const _averageSellingPrice = useRef<Number[]>([]);
    const _averageSellingPriceB2B = useRef<Number[]>([]);
    const _averageUnitsPerOrderItem = useRef<Number[]>([]);
    const _averageUnitsPerOrderItemB2B = useRef<Number[]>([]);
    const _claimsAmount = useRef<Number[]>([]);
    const _claimsGranted = useRef<Number[]>([]);
    
    const _ordersShipped = useRef<Number[]>([]);
    const _refundRate = useRef<Number[]>([]);
    const _shippedProductSales = useRef<Number[]>([]);
    const _totalOrderItems = useRef<Number[]>([]);
    const _totalOrderItemsB2B = useRef<Number[]>([]);
    const _totalOrderItemsNotB2B = useRef<Number[]>([]);
    const _unitsOrdered = useRef<Number[]>([]);
    const _unitsOrderedB2B= useRef<Number[]>([]);
    const _unitsOrderedNotB2B= useRef<Number[]>([]);
    const _unitsRefunded= useRef<Number[]>([]);
    const _unitsShipped = useRef<Number[]>([]);
    //TrafficByDate Points
    const _averageOfferCount = useRef<Number[]>([]);
    const _averageParentItems = useRef<Number[]>([]);
    const _browserPageViews = useRef<Number[]>([]);
    const _browserSessions = useRef<Number[]>([]);
    const _buyBoxPercentage = useRef<Number[]>([]);
    const _feedbackReceived = useRef<Number[]>([]);
    const _mobileAppPageViews = useRef<Number[]>([]);
    const _mobileAppSessions = useRef<Number[]>([]);
    const _negativeFeedbackReceived = useRef<Number[]>([]);
    const _orderItemSessionPercentage = useRef<Number[]>([]);
    const _orderItemSessionPercentageB2B = useRef<Number[]>([]);
    const _orderItemSessionPercentageNotB2B = useRef<Number[]>([]);
    const _pageViews = useRef<Number[]>([]);
    const _receivedNegativeFeedbackRate = useRef<Number[]>([]);
    const _sessions = useRef<Number[]>([]);
    
    const _salesPerBrowserSession = useRef<Number[]>([]);
  
    const _salesPerMobileAppPageViews = useRef<Number[]>([]);
    const _salesPerMobileAppPageViewsCorporate = useRef<Number[]>([]);
    const _sessionPercentage = useRef<Number[]>([]);
    const _pageViewPercentage = useRef<Number[]>([]);
    
    const _strDates = useRef<String[]>([]);
    const _filterBy = useRef<String>("");
    const _sessionType = useRef<String>("");

    const _unitSessionPercentage = useRef<Number[]>([]);
    const _unitSessionPercentageB2B = useRef<Number[]>([]);
    const _unitSessionPercentageNotB2B = useRef<Number[]>([]);
    const _pageViewConversionPercentage = useRef<Number[]>([]);
    const _pageViewConversionPercentageCorporate = useRef<Number[]>([]);
    const _salesPerBrowserPageViews = useRef<Number[]>([]);
    const _salesPerMobileAppSession = useRef<Number[]>([]);
    const _salesPerBrowserPageViewsCorporate = useRef<Number[]>([]);


    //new report variables
    const _retailMobileAppSession = useRef<Number[]>([]);
    const _corporateMobileAppSession = useRef<Number[]>([]);
    const _retailBrowserSession = useRef<Number[]>([]);
    const _corporateBrowserSession = useRef<Number[]>([]);
    const _retailOrderItemSessionPercent = useRef<Number[]>([]);
    const _corporateOrderItemSessionPercent = useRef<Number[]>([]);
    

    let lineChartData: Number[] = [];
    let lineChartLabels: String[] = [];
    
    let asinLineChartOptions: any = {};
    let asinLineChartLabels: String[] = [];

    let salesByDate = {};
    let trafficByDate = {};
    let asinParentList = {};
    let asinChildList = {};

    let asinSales = [];
    let asinStrDates  = [];

    let view = "sales";

    const [dialogModalOpen, setDialogModalOpen] = useState(false);
    const [dialogModalMessage, setDialogModalMessage] = useState('');
  
    const handleCloseModal = () => {
      setDialogModalOpen(false); 
    };
    
    useEffect(() => {
        ChartJS.register(CategoryScale,LinearScale, PointElement, LineElement,zoomPlugin, Tooltip);
        downloadTraffic();
        validateSubscription(setSubscriptionPlan, setSubscriptionLoading, setDialogModalMessage, setDialogModalOpen, navigate);
      
    },[]);// <-- Have to pass in [] here or is called on every render
    
    function moveAsinView(e) {
      if(e.target.value == "asin"){
        changeView('asin');
      }
    }

    // async function fetchSubscription(){
    //   const user = Cache.getItem('user');

    //   let refreshToken = {}
    //   try{
    //       var response;
    //       if(user){
    //           response = await API.graphql({
    //               query: getStripeCustomer
    //               ,variables : {"email": user.id, "stripeCustomerId": user.stripeId}
    //           })
  
    //           const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);
              
    //           if(stripeData["body"]["data"][0] && stripeData["body"]["data"][0]["product_name"]["name"]){
    //               setSubscriptionPlan(stripeData["body"]["data"][0]["product_name"]["name"]);
    //           }
    //       }
    //   }catch(exception){
    //     console.error('Error fetching subscription:', exception);
    //   } finally {
    //     setSubscriptionLoading(false);
    //   }
    // }

    async function downloadTraffic() {
    
      try{
        const market = Cache.getItem('marketCode');
        let url = market+ "_SalesAndTrafficReport/TrafficByDate.json";
        
        const result = await Storage.vault.get(url, { download: true });
        trafficByDate = await new Response(result.Body).json();
        
        //whichever is faster
        let urlSales = market+ "_SalesAndTrafficReport/SalesByDate.json";
    
        const resultSales = await Storage.vault.get(urlSales, { download: true });
        salesByDate = await new Response(resultSales.Body).json();
      }
      catch {
        trafficByDate = {};
        salesByDate = {};
      }
      await sortSTR();
        
    }

    function findAverageCurrentData(array){
        var total = 0;
        var denominator = 0;
        
        for(var i = 0; i < array.length; i++) {
            total += Number(array[i]);
            
            if(Number(array[i]) != 0){
              denominator++;
            } 
        }
        let returnValue = (total / denominator).toFixed(2);
        if(returnValue == "NaN"){
          return 0;
        }
        
        return returnValue;
    }

    async function sortSTR(){
        
      let temp;
      let unitSessionbtob;
      //console.log(salesByDate[0].date.replace(regex, '/'))
      //console.log(salesByDate)
      
      for(let i in salesByDate){
          _averageSellingPrice.current.push(salesByDate[i].averageSellingPrice)    
          //averageSellingPriceB2B.push(salesByDate[i].averageSellingPriceB2B.amount)   
          _averageUnitsPerOrderItem.current.push(salesByDate[i].averageUnitsPerOrderItem) 
          _averageUnitsPerOrderItemB2B.current.push(salesByDate[i].averageUnitsPerOrderItemB2B) 
          _claimsAmount.current.push(salesByDate[i].claimsAmount)   
          _claimsGranted.current.push(salesByDate[i].claimsGranted)
          //orderedProductSales.push(salesByDate[i].orderedProductSales.amount)    
          //orderedProductSalesB2B.push(salesByDate[i].orderedProductSalesB2B.amount)
          //temp = Number(salesByDate[i].orderedProductSales.amount) - Number(salesByDate[i].orderedProductSalesB2B.amount)
          _orderedProductSalesNotB2B.current.push(temp)  
          _ordersShipped.current.push(salesByDate[i].ordersShipped) 
          _refundRate.current.push(salesByDate[i].refundRate)
          _shippedProductSales.current.push(salesByDate[i].shippedProductSales) 
          _totalOrderItems.current.push(salesByDate[i].totalOrderItems) 
          _totalOrderItemsB2B.current.push(salesByDate[i].totalOrderItemsB2B)
          temp = Number(salesByDate[i].totalOrderItems) - Number(salesByDate[i].totalOrderItemsB2B)
          _totalOrderItemsNotB2B.current.push(temp)
          _unitsOrdered.current.push(salesByDate[i].unitsOrdered)
          _unitsOrderedB2B.current.push(salesByDate[i].unitsOrdered)
          temp = Number(salesByDate[i].unitsOrdered) - Number(salesByDate[i].unitsOrderedB2B)
          _unitsOrderedNotB2B.current.push(temp)
          _unitsRefunded.current.push(salesByDate[i].unitsRefunded)
          _unitsShipped.current.push(salesByDate[i].unitsShipped)
          
      
      }
      
      for(let i in trafficByDate){
          _averageOfferCount.current.push(trafficByDate[i].averageOfferCount)  
          _averageParentItems.current.push(trafficByDate[i].averageParentItems)  
          _browserPageViews.current.push(trafficByDate[i].browserPageViews)  
          _browserSessions.current.push(trafficByDate[i].browserSessions)  
          _buyBoxPercentage.current.push(trafficByDate[i].buyBoxPercentage)  
          _feedbackReceived.current.push(trafficByDate[i].feedbackReceived)  
          _mobileAppPageViews.current.push(trafficByDate[i].mobileAppPageViews)  
          _mobileAppSessions.current.push(trafficByDate[i].mobileAppSessions)  
          _negativeFeedbackReceived.current.push(trafficByDate[i].negativeFeedbackReceived)  
          _orderItemSessionPercentage.current.push(trafficByDate[i].orderItemSessionPercentage)  
          _orderItemSessionPercentageB2B.current.push(trafficByDate[i].orderItemSessionPercentageB2B) 
          temp = Number(trafficByDate[i].orderItemSessionPercentage) - Number(trafficByDate[i].orderItemSessionPercentageB2B) 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _orderItemSessionPercentageNotB2B.current.push(temp)  
          _pageViews.current.push(trafficByDate[i].pageViews) 
          _receivedNegativeFeedbackRate.current.push(trafficByDate[i].receivedNegativeFeedbackRate) 
          _sessions.current.push(trafficByDate[i].sessions) 
          _unitSessionPercentageNotB2B.current.push(trafficByDate[i].unitSessionPercentage) 
      
          _unitSessionPercentageB2B.current.push(trafficByDate[i].unitSessionPercentageB2B)
          _sessionPercentage.current.push(trafficByDate[i].sessionPercentage)
          _pageViewPercentage.current.push(trafficByDate[i].pageViewsPercentage)
          
          // temp = Number(trafficByDate[i].unitSessionPercentage) + Number(trafficByDate[i].unitSessionPercentageB2B) 
          // temp = (Math.round(temp * 100) / 100).toFixed(2);
          // unitSessionPercentage.push(temp)
          
          if(salesByDate[i] == null || salesByDate[i].unitsOrdered === 0 || trafficByDate[i].pageViews === 0)
          {
              temp = 0;
          } 
          else{
              temp = (Number(salesByDate[i].unitsOrdered) / Number(trafficByDate[i].pageViews))
             // temp >= 1 ? temp = 1 : temp;
              temp = temp * 100;
          }
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _pageViewConversionPercentage.current.push(temp)

          //---totalPageViewConversionPercentageCorporate
          if(salesByDate[i] == null || salesByDate[i].totalOrderItemsB2B === 0 || trafficByDate[i].pageViewsB2B === 0)
          {
              temp = 0;
          } 
          else{
              temp = (Number(salesByDate[i].totalOrderItemsB2B) / Number(trafficByDate[i].pageViewsB2B))
              if(temp >= 1) 
                temp = 1;

              temp = temp * 100;
          }
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _pageViewConversionPercentageCorporate.current.push(temp)
      
          //---salesPerBrowserSession---//
          if(salesByDate[i] == null || salesByDate[i].orderedProductSales.amount === 0 || trafficByDate[i].browserSessions === 0)
          {
          temp = 0;
          }
          else{
              temp = (Number(salesByDate[i].orderedProductSales.amount) / Number(trafficByDate[i].browserSessions))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _salesPerBrowserSession.current.push(temp)

          //---salesPerMobileAppSession---//
          if(salesByDate[i] == null ||salesByDate[i].orderedProductSales.amount === 0 || trafficByDate[i].mobileAppSessions === 0)
          {
              temp = 0;
          }
          else{
              temp = (Number(salesByDate[i].orderedProductSales.amount) / Number(trafficByDate[i].mobileAppSessions))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _salesPerMobileAppSession.current.push(temp)
          
          //---salesPerBrowserPageViews---//
          if(salesByDate[i] == null ||salesByDate[i].orderedProductSales.amount === 0 || trafficByDate[i].browserPageViews === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].orderedProductSales.amount) / Number(trafficByDate[i].browserPageViews))
          }
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _salesPerBrowserPageViews.current.push(temp)

          //---salesPerBrowserPageViewsCorporate---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItemsB2B === 0 || trafficByDate[i].browserPageViewsB2B === 0)
            {
            temp = 0;
            }
            else{
            temp = (Number(salesByDate[i].totalOrderItemsB2B) /  Number(trafficByDate[i].browserPageViewsB2B) )
            }
            console.log("JSON STRINGIFY "+ JSON.stringify(trafficByDate[i]))
            temp = (Math.round(temp * 100) / 100).toFixed(2);
            _salesPerBrowserPageViewsCorporate.current.push(temp)

          //---salesPerMobileAppPageViews---//
          if(salesByDate[i] == null ||salesByDate[i].orderedProductSales.amount === 0 || trafficByDate[i].mobileAppPageViews === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].orderedProductSales.amount) / Number(trafficByDate[i].mobileAppPageViews))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);
          _salesPerMobileAppPageViews.current.push(temp)


           //---salesPerMobileAppPageViewsCorporate---//
           if(salesByDate[i] == null ||salesByDate[i].totalOrderItemsB2B === 0 || trafficByDate[i].mobileAppPageViewsB2B === 0)
            {
            temp = 0;
            }
            else{
            temp =  Number(salesByDate[i].totalOrderItemsB2B) / Number(trafficByDate[i].mobileAppPageViewsB2B)
            } 
            temp = (Math.round(temp * 100) / 100).toFixed(2);
            _salesPerMobileAppPageViewsCorporate.current.push(temp)

          
          // REPORT DATA SET //TODO - TRIPLE CHECK FORMULA
          
          //---retailMobileAppSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItems === 0 || trafficByDate[i].mobileAppSessions === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItems) / Number(trafficByDate[i].mobileAppSessions))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);

          _retailMobileAppSession.current.push(temp)

          //---corporateMobileAppSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItemsB2B === 0 || trafficByDate[i].mobileAppSessionsB2B === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItemsB2B) / Number(trafficByDate[i].mobileAppSessionsB2B))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);

          _corporateMobileAppSession.current.push(temp)


          //---retailBrowserSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItems === 0 || trafficByDate[i].browserSessions === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItems) / Number(trafficByDate[i].browserSessions))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);

          _retailBrowserSession.current.push(temp)


          //---corporateBrowserSession---//
          if(salesByDate[i] == null ||salesByDate[i].totalOrderItemsB2B === 0 || trafficByDate[i].browserSessionsB2B === 0)
          {
          temp = 0;
          }
          else{
          temp = (Number(salesByDate[i].totalOrderItemsB2B) / Number(trafficByDate[i].browserSessionsB2B))
          } 
          temp = (Math.round(temp * 100) / 100).toFixed(2);

          _corporateBrowserSession.current.push(temp)

          //---retailOrderItemSessionPercent---//
          _retailOrderItemSessionPercent.current.push(trafficByDate[i].orderItemSessionPercentage)  
          //---corporateOrderItemSessionPercent---//
          _corporateOrderItemSessionPercent.current.push(trafficByDate[i].orderItemSessionPercentageB2B)

          //---END--//
        
          _strDates.current.push(moment(trafficByDate[i].date,"YYYY-MM-DD").format('MMM D, y'))
          
          // dates.push(moment(trafficByDate[i].date,"YYYY-MM-DD").format('MMM D, y'))


  
      }

  
      if(_strDates.current.length < 30){
          
          let diff = 30 - _strDates.current.length
          let firstDate = new Date (String(_strDates.current[0]))
          
          for(let i = diff; i > 0; i--)
          {
            firstDate.setDate(firstDate.getDate() - 1)
            // TODO _strDates.current.unshift(datepipe.transform(firstDate, 'MMM d, y' ))
            _orderItemSessionPercentage.current.unshift(0 as Number)
            _orderItemSessionPercentageB2B.current.unshift(0 as Number)
            _orderItemSessionPercentageNotB2B.current.unshift(0 as Number)
            // unitSessionPercentage.unshift(0 as ChartPoint)
            _unitSessionPercentageB2B.current.unshift(0 as Number)
            _unitSessionPercentageNotB2B.current.unshift(0 as Number)
            _pageViewConversionPercentage.current.unshift(0 as Number)
            _pageViewConversionPercentageCorporate.current.unshift(0 as Number)
            _sessionPercentage.current.unshift(0 as Number)
            _pageViewPercentage.current.unshift(0 as Number)
            _salesPerBrowserSession.current.unshift(0 as Number)
            _salesPerMobileAppSession.current.unshift(0 as Number)
            _salesPerBrowserPageViews.current.unshift(0 as Number)
            _salesPerBrowserPageViewsCorporate.current.unshift(0 as Number)
            _salesPerMobileAppPageViews.current.unshift(0 as Number)
            _salesPerMobileAppPageViewsCorporate.current.unshift(0 as Number)

            _retailMobileAppSession.current.unshift(0 as Number)
            _corporateMobileAppSession.current.unshift(0 as Number)
            _retailBrowserSession.current.unshift(0 as Number)
            _corporateBrowserSession.current.unshift(0 as Number)
            _retailOrderItemSessionPercent.current.unshift(0 as Number)
            _corporateOrderItemSessionPercent.current.unshift(0 as Number)
          }
      }
  
      // if(_strDates.current.length < 30){
      //     _filterBy.current = 'lastSevenDays';
      //     _sessionType.current = 'unitSessionPercent';
      //     filter();
      //         //TODO 
      //     //dynamicForm.controls.FilterBy.setValue( 'lastSevenDays')
      // }
      // else{
          _filterBy.current = 'lastThirtyDays';
          //_sessionType.current = 'unitSessionPercent'; //this was the value before changing it to pageviewconversionreport
          _sessionType.current = 'mobileAppSession';
          filter();
      // }
        
    }

    function fillMissingDates(dataArray, startDate, endDate) {
      let filledData = [];
      let dateLabels = [];
      let currentDate = moment(startDate);
      const lastDate = moment(endDate);
    
      while (currentDate <= lastDate) {
        let formattedDate = currentDate.format('MMM D, y');
        dateLabels.push(formattedDate);
    
        let index = lineChartLabels.indexOf(formattedDate);
        if (index !== -1) {
          filledData.push(dataArray[index]);
        } else {
          filledData.push(0);
        }
        currentDate = currentDate.add(1, 'days');
      }
    
      return { filledData, dateLabels };
    }

    function setAverages(average1, average2){
      
      if(_sessionType.current == 'mobileAppSession'){
        setAverages1(<ItemCardNew width="50%" overrides={{Name:{children:"Average Retail Mobile App Session"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2(<ItemCardNew width="50%"  overrides={{Name:{children:"Average Business Mobile App Session"}, Number:{children:average2}}}></ItemCardNew>)
      }
      else if(_sessionType.current == 'corporateMobileAppSession'){
        setAverages1(<ItemCardNew width="100%"  overrides={{Name:{children:"Average Business Mobile App Session"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("")
      }

      else if(_sessionType.current == 'browserSession'){
        setAverages1(<ItemCardNew width="50%"  overrides={{Name:{children:"Average Retail Browser Session"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2(<ItemCardNew width="50%"  overrides={{Name:{children:"Average Business Browser Session"}, Number:{children:average2}}}></ItemCardNew>)
        
      }
      else if(_sessionType.current == 'corporateBrowserSession'){
        setAverages1(<ItemCardNew width="100%"  overrides={{Name:{children:"Average Business Browser Session"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("");
      }
      else if(_sessionType.current == 'orderItemSessionPercent'){
        setAverages1(<ItemCardNew width="50%" overrides={{Name:{children:"Average Retail Order Item Session Percentage"}, Number:{children:average1}}}></ItemCardNew>)
        setAverages2(<ItemCardNew width="50%" overrides={{Name:{children:"Average Business Order Item Session Percentage"}, Number:{children:average2}}}></ItemCardNew>)
      }
      else if(_sessionType.current == 'corporateOrderItemSessionPercent'){
        setAverages1(<ItemCardNew width="100%" overrides={{Name:{children:"Average Business Order Item Session Percentage"}, Number:{children:average1}}}></ItemCardNew>)
        
        setAverages2("");
      }
    }

    function filter(){
      
      setLineChartOptions( {
          responsive: true,
          showTooltips: true,
      //   scales: {
      //     yAxes:[  {
      //       //  scaleLabel: {
      //       //     display: true,
      //       //     labelString: 'USD'//market.selectedCurency
      //       //  },
      //       //  ticks:{
      //       //       callback: function(value, index, values) {
      //       //         return '$' + value;
      //       //     }
      //       //  }
            
      //       }
      //     ]
         
      //  },
          plugins: {
            tooltip: {
              callbacks: {
                  label: function (tooltipItem, data) {
                      return " "+tooltipItem.dataset.label + " "+tooltipItem.formattedValue;
                  }
              }
          },
         
            // zoom: {
            //   zoom: {
            //     wheel: {
            //       enabled: true,
            //     },
            //     pinch: {
            //       enabled: true
            //     },
            //     mode: 'xy',
            //   },
            //   pan: {
            //     enabled: true,
            //     mode: 'xy', // 'xy' for both x and y directions
            //   },
            // },
            legend: {
              display:true,
              title:{
                display: true
              }
            },
            title: {
              display: true,
              text: 'Chart.js Line Chart',
            },
          },
        });
      
        let filterBy = _filterBy.current;
        let sessionType = _sessionType.current;
        let dat = {};
        let av1 = "";
        let av2 = "";
        
          switch(filterBy){
              
              case 'lastSevenDays':{
                lineChartLabels = _strDates.current;
                  
                  if(sessionType == "mobileAppSession"){
                      dat = [
                          {
                            label: 'Retail Mobile App Session',
                            data: _retailMobileAppSession.current.slice(Math.max(_retailMobileAppSession.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Business Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(Math.max(_corporateMobileAppSession.current.length - 7, 1)),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailMobileAppSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                      av2=findAverageCurrentData(_corporateMobileAppSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                  else if (sessionType == "corporateMobileAppSession"){
                      dat = [
                          {
                            label: 'Business Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(Math.max(_corporateMobileAppSession.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateMobileAppSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }

                  else if (sessionType == "browserSession"){
                    dat = [
                        {
                          label: 'Retail Browser Session',
                          data: _retailBrowserSession.current.slice(Math.max(_retailBrowserSession.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Browser Session',
                          data: _corporateBrowserSession.current.slice(Math.max(_corporateBrowserSession.current.length - 7, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailBrowserSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                    av2=findAverageCurrentData(_corporateBrowserSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                }
                  else if (sessionType == "corporateBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Business Browser Session',
                            data: _corporateBrowserSession.current.slice(Math.max(_corporateBrowserSession.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateBrowserSession.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }

                  else if (sessionType == "orderItemSessionPercent"){
                        
                      dat = [
                          {
                            label: 'Retail Order Item Session Percentage',
                            data: _retailOrderItemSessionPercent.current.slice(Math.max(_retailOrderItemSessionPercent.current.length - 7, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Business Order Item Session Percentage',
                            data: _corporateOrderItemSessionPercent.current.slice(Math.max(_corporateOrderItemSessionPercent.current.length - 7, 1)),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailOrderItemSessionPercent.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                      av2=findAverageCurrentData(_corporateOrderItemSessionPercent.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
                

                  else if (sessionType == "corporateOrderItemSessionPercent"){
                        
                    dat = [
                        {
                          label: 'Business Order Item Session Percentage',
                          data: _corporateOrderItemSessionPercent.current.slice(Math.max(_corporateOrderItemSessionPercent.current.length - 7, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateOrderItemSessionPercent.current.slice(Math.max(lineChartLabels.length - 7, 1)));
                  }
              
                 
      
                  const chartData = {
                      labels : lineChartLabels.slice(Math.max(lineChartLabels.length - 7, 1)),
                    datasets: dat,
                  };
                  setIsLoading(false);
                  setSelectValue("lastSevenDays");
                  setChartData(chartData);
                  
                  setAverages(av1,av2);
                  break;
              }
              case 'lastThirtyDays':{
                  lineChartLabels = _strDates.current;

                  if(sessionType == "mobileAppSession"){
                      dat = [
                          {
                            label: 'Retail Mobile App Session',
                            data: _retailMobileAppSession.current.slice(Math.max(_retailMobileAppSession.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Business Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(Math.max(_corporateMobileAppSession.current.length - 30, 1)),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailMobileAppSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                      av2=findAverageCurrentData(_corporateMobileAppSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                  else if (sessionType == "corporateMobileAppSession"){
                      dat = [
                          {
                            label: 'Business Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(Math.max(_corporateMobileAppSession.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateMobileAppSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }

                  else if (sessionType == "browserSession"){
                    dat = [
                        {
                          label: 'Retail Browser Session',
                          data: _retailBrowserSession.current.slice(Math.max(_retailBrowserSession.current.length - 30, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Browser Session',
                          data: _corporateBrowserSession.current.slice(Math.max(_corporateBrowserSession.current.length - 30, 1)),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailBrowserSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                    av2=findAverageCurrentData(_corporateBrowserSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                }
                  else if (sessionType == "corporateBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Business Browser Session',
                            data: _corporateBrowserSession.current.slice(Math.max(_corporateBrowserSession.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateBrowserSession.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }

                  else if (sessionType == "orderItemSessionPercent"){
                        
                      dat = [
                          {
                            label: 'Retail Order Item Session Percentage',
                            data: _retailOrderItemSessionPercent.current.slice(Math.max(_retailOrderItemSessionPercent.current.length - 30, 1)),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Business Order Item Session Percentage',
                            data: _corporateOrderItemSessionPercent.current.slice(Math.max(_corporateOrderItemSessionPercent.current.length - 30, 1)),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailOrderItemSessionPercent.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                      av2=findAverageCurrentData(_corporateOrderItemSessionPercent.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
                

                  else if (sessionType == "corporateOrderItemSessionPercent"){
                        
                    dat = [
                        {
                          label: 'Business Order Item Session Percentage',
                          data: _corporateOrderItemSessionPercent.current.slice(Math.max(_corporateOrderItemSessionPercent.current.length - 30, 1)),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateOrderItemSessionPercent.current.slice(Math.max(lineChartLabels.length - 30, 1)));
                  }
              
      
      
                  const chartData = {
                      labels : lineChartLabels.slice(Math.max(lineChartLabels.length - 30, 1)),
                    datasets: dat,
                  };
                  setIsLoading(false);
                  setSelectValue("lastThirtyDays");
                  setChartData(chartData);
                  
                  setAverages(av1,av2);
                  break;
              }
              case 'thisWeek':{
              lineChartLabels = _strDates.current;
              let filteredData: Number[] = [];
              let y = _strDates.current[_strDates.current.length-1]
              
              const lastDate = new Date(lineChartLabels[lineChartLabels.length-1].toString());
              const date = new Date(lineChartLabels[lineChartLabels.length-1].toString());
              const day = date.getDay();
              const diff = date.getDate() - day + (day === 0 ? -6 : 1);
              let x = new Date(date.setDate(diff));
              const firstDay = moment(x).format('MMM D, y')//datepipe.transform(x, 'MMM d, y');
              
              let index = _strDates.current.indexOf(firstDay);
              if(lastDate.getDay() === 1 && _strDates.current.length > 8)
              {
                  index -= 7;
              }
              if(index === -1)
              {
                  index = 0;
              }
              console.log(firstDay);
              console.log(lastDate);
              const lastDay = moment(lastDate).format('MMM D, y')
              let firstIndex = lineChartLabels.indexOf(firstDay);
              let lastIndex = lineChartLabels.indexOf(lastDay);
              
                
                  if(sessionType == "mobileAppSession"){
                      dat = [
                          {
                            label: 'Retail Mobile App Session',
                            data: _retailMobileAppSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Business Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(firstIndex,lastIndex),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailMobileAppSession.current.slice(firstIndex,lastIndex));
                      av2=findAverageCurrentData(_corporateMobileAppSession.current.slice(firstIndex,lastIndex));
                  }
                  else if (sessionType == "corporateMobileAppSession"){
                      dat = [
                          {
                            label: 'Business Mobile App Session',
                            data: _corporateMobileAppSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateMobileAppSession.current.slice(firstIndex,lastIndex));
                  }

                  else if (sessionType == "browserSession"){
                    dat = [
                        {
                          label: 'Retail Browser Session',
                          data: _retailBrowserSession.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Browser Session',
                            data: _corporateBrowserSession.current.slice(firstIndex,lastIndex),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailBrowserSession.current.slice(firstIndex,lastIndex));
                    av2=findAverageCurrentData(_corporateBrowserSession.current.slice(firstIndex,lastIndex));
                }
                  else if (sessionType == "corporateBrowserSession"){
                      
                      dat = [
                          {
                            label: 'Business Browser Session',
                            data: _corporateBrowserSession.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          }
                        ];
                      av1=findAverageCurrentData(_corporateBrowserSession.current.slice(firstIndex,lastIndex));
                  }

                  else if (sessionType == "orderItemSessionPercent"){
                        
                      dat = [
                          {
                            label: 'Retail Order Item Session Percentage',
                            data: _retailOrderItemSessionPercent.current.slice(firstIndex,lastIndex),
                            borderColor: '3c3e3d',
                            backgroundColor: '3c3e3d',
                            lineTension: 0.4
                          },
                          {
                            label: 'Business Order Item Session Percentage',
                            data: _corporateOrderItemSessionPercent.current.slice(firstIndex,lastIndex),
                            borderColor: '#007798',
                            backgroundColor: '#007798',
                            lineTension: 0.4
                          },
                        ];
                      av1=findAverageCurrentData(_retailOrderItemSessionPercent.current.slice(firstIndex,lastIndex));
                      av2=findAverageCurrentData(_corporateOrderItemSessionPercent.current.slice(firstIndex,lastIndex));
                  }
                

                  else if (sessionType == "corporateOrderItemSessionPercent"){
                        
                    dat = [
                        {
                          label: 'Business Order Item Session Percentage',
                          data: _corporateOrderItemSessionPercent.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateOrderItemSessionPercent.current.slice(firstIndex,lastIndex));
                  }
      
                  const chartData = {
                      labels : lineChartLabels.slice(firstIndex,lastIndex),
                    datasets: dat,
                  };
              
                  setIsLoading(false);
                  setSelectValue("thisWeek");
                  setChartData(chartData);
                  
                  setAverages(av1,av2);
                  break;
              }
              case 'thisMonth':{
                lineChartLabels = _strDates.current;
                let filteredData: Number[] = [];
                const lastDate = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const now = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format('MMM D, y');
                console.log(firstDay);
                console.log(lastDate);
                let index = _strDates.current.indexOf(firstDay);
                if (index === -1) {
                    index = 0;
                }
                const lastDay = moment(lastDate).format('MMM D, y');
                let firstIndex = lineChartLabels.indexOf(firstDay);
                let lastIndex = lineChartLabels.indexOf(lastDay);
                if (firstIndex === -1) {
                    firstIndex = 0;
                }
                if (lastIndex === -1) {
                    lastIndex = lineChartLabels.length - 1;
                }
              
                if(sessionType == "mobileAppSession"){
                    dat = [
                        {
                          label: 'Retail Mobile App Session',
                          data: _retailMobileAppSession.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Mobile App Session',
                          data: _corporateMobileAppSession.current.slice(firstIndex,lastIndex),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailMobileAppSession.current.slice(firstIndex,lastIndex));
                    av2=findAverageCurrentData(_corporateMobileAppSession.current.slice(firstIndex,lastIndex));
                }
                else if (sessionType == "corporateMobileAppSession"){
                    dat = [
                        {
                          label: 'Business Mobile App Session',
                          data: _corporateMobileAppSession.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateMobileAppSession.current.slice(firstIndex,lastIndex));
                }

                else if (sessionType == "browserSession"){
                  dat = [
                      {
                        label: 'Retail Browser Session',
                        data: _retailBrowserSession.current.slice(firstIndex,lastIndex),
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Browser Session',
                          data: _corporateBrowserSession.current.slice(firstIndex,lastIndex),
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(_retailBrowserSession.current.slice(firstIndex,lastIndex));
                  av2=findAverageCurrentData(_corporateBrowserSession.current.slice(firstIndex,lastIndex));
              }
                else if (sessionType == "corporateBrowserSession"){
                    
                    dat = [
                        {
                          label: 'Business Browser Session',
                          data: _corporateBrowserSession.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(_corporateBrowserSession.current.slice(firstIndex,lastIndex));
                }

                else if (sessionType == "orderItemSessionPercent"){
                      
                    dat = [
                        {
                          label: 'Retail Order Item Session Percentage',
                          data: _retailOrderItemSessionPercent.current.slice(firstIndex,lastIndex),
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Order Item Session Percentage',
                          data: _corporateOrderItemSessionPercent.current.slice(firstIndex,lastIndex),
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(_retailOrderItemSessionPercent.current.slice(firstIndex,lastIndex));
                    av2=findAverageCurrentData(_corporateOrderItemSessionPercent.current.slice(firstIndex,lastIndex));
                }
              

                else if (sessionType == "corporateOrderItemSessionPercent"){
                      
                  dat = [
                      {
                        label: 'Business Order Item Session Percentage',
                        data: _corporateOrderItemSessionPercent.current.slice(firstIndex,lastIndex),
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(_corporateOrderItemSessionPercent.current.slice(firstIndex,lastIndex));
                }
                
                  
      
                const chartData = {
                    labels : lineChartLabels.slice(firstIndex,lastIndex),
                  datasets: dat,
                };
                console.log(chartData);
                setIsLoading(false);
                setSelectValue("thisMonth");
                setChartData(chartData);
                
                setAverages(av1,av2);break;
              }
              //ANDREW: THIS ALGO IS TO SHOW ALL DATES REGARDLESS IF THE VALUE IS ZERO
              case 'lastOneYear': {
                lineChartLabels = _strDates.current;
                let filteredData = [];
                const lastDate = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const firstDayOfYear = moment(new Date(lastDate.getFullYear() - 1, lastDate.getMonth(), lastDate.getDate())).format('MMM D, y');
              
                let firstIndex = lineChartLabels.indexOf(firstDayOfYear);
                let lastIndex = lineChartLabels.length;
              
                if (firstIndex === -1) {
                  firstIndex = 0;
                }
              
                const { filledData: b2bData, dateLabels } = fillMissingDates(_retailMobileAppSession.current, firstDayOfYear, lastDate);

                if(sessionType == "mobileAppSession"){
                  const { filledData: retailMobileAppSessionData } = fillMissingDates(_retailMobileAppSession.current, firstDayOfYear, lastDate);
                  const { filledData: corporateMobileAppSessionData } = fillMissingDates(_corporateMobileAppSession.current, firstDayOfYear, lastDate);
                  
                  dat = [
                      {
                        label: 'Retail Mobile App Session',
                        data: retailMobileAppSessionData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Mobile App Session',
                        data:corporateMobileAppSessionData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(retailMobileAppSessionData);
                  av2=findAverageCurrentData(corporateMobileAppSessionData);
                }
                else if (sessionType == "corporateMobileAppSession"){
                  const { filledData: corporateMobileAppSessionData } = fillMissingDates(_corporateMobileAppSession.current, firstDayOfYear, lastDate);
                  dat = [
                        {
                          label: 'Business Mobile App Session',
                          data:corporateMobileAppSessionData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(corporateMobileAppSessionData);
                }

                else if (sessionType == "browserSession"){
                  const { filledData: retailBrowserSessionData } = fillMissingDates(_retailBrowserSession.current, firstDayOfYear, lastDate);
                  const { filledData: corporateBrowserSessionData } = fillMissingDates(_corporateBrowserSession.current, firstDayOfYear, lastDate);
                  
                  dat = [
                      {
                        label: 'Retail Browser Session',
                        data: retailBrowserSessionData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Browser Session',
                        data: corporateBrowserSessionData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(retailBrowserSessionData);
                  av2=findAverageCurrentData(corporateBrowserSessionData);
              }
                else if (sessionType == "corporateBrowserSession"){
                    
                  const { filledData: corporateBrowserSessionData } = fillMissingDates(_corporateBrowserSession.current, firstDayOfYear, lastDate);
                  dat = [
                        {
                          label: 'Business Browser Session',
                          data: corporateBrowserSessionData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(corporateBrowserSessionData);
                }

                else if (sessionType == "orderItemSessionPercent"){
                    const { filledData: retailOrderItemSessionPercentData } = fillMissingDates(_retailOrderItemSessionPercent.current, firstDayOfYear, lastDate);
                    const { filledData: corporateOrderItemSessionPercentData } = fillMissingDates(_corporateOrderItemSessionPercent.current, firstDayOfYear, lastDate);
                  
                    dat = [
                        {
                          label: 'Retail Order Item Session Percentage',
                          data: retailOrderItemSessionPercentData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Order Item Session Percentage',
                          data: corporateOrderItemSessionPercentData,
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(retailOrderItemSessionPercentData);
                    av2=findAverageCurrentData(corporateOrderItemSessionPercentData);
                }
              

                else if (sessionType == "corporateOrderItemSessionPercent"){
                  const { filledData: corporateOrderItemSessionPercentData } = fillMissingDates(_corporateOrderItemSessionPercent.current, firstDayOfYear, lastDate);
                  dat = [
                      {
                        label: 'Business Order Item Session Percentage',
                        data: corporateOrderItemSessionPercentData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(corporateOrderItemSessionPercentData);
                }
    
              
                const chartData = {
                  labels: dateLabels,
                  datasets: dat,
                };
                console.log(chartData);
                setIsLoading(false);
                setSelectValue("lastOneYear");
                setChartData(chartData);
                setAverages(av1, av2);
                break;
              }
              
              case 'lastTwoYears': {
                lineChartLabels = _strDates.current;
                let filteredData = [];
                const lastDate = new Date(lineChartLabels[lineChartLabels.length - 1].toString());
                const firstDayOfTwoYearsAgo = moment(new Date(lastDate.getFullYear() - 2, lastDate.getMonth(), lastDate.getDate())).format('MMM D, y');
              
                let firstIndex = lineChartLabels.indexOf(firstDayOfTwoYearsAgo);
                let lastIndex = lineChartLabels.length;
              
                if (firstIndex === -1) {
                  firstIndex = 0;
                }
              
                const { filledData: b2bData, dateLabels } = fillMissingDates(_retailMobileAppSession.current, firstDayOfTwoYearsAgo, lastDate);

                if(sessionType == "mobileAppSession"){
                  const { filledData: retailMobileAppSessionData } = fillMissingDates(_retailMobileAppSession.current, firstDayOfTwoYearsAgo, lastDate);
                  const { filledData: corporateMobileAppSessionData } = fillMissingDates(_corporateMobileAppSession.current, firstDayOfTwoYearsAgo, lastDate);
                  
                  dat = [
                      {
                        label: 'Retail Mobile App Session',
                        data: retailMobileAppSessionData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Mobile App Session',
                        data:corporateMobileAppSessionData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(retailMobileAppSessionData);
                  av2=findAverageCurrentData(corporateMobileAppSessionData);
                }
                else if (sessionType == "corporateMobileAppSession"){
                  const { filledData: corporateMobileAppSessionData } = fillMissingDates(_corporateMobileAppSession.current, firstDayOfTwoYearsAgo, lastDate);
                  dat = [
                        {
                          label: 'Business Mobile App Session',
                          data:corporateMobileAppSessionData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(corporateMobileAppSessionData);
                }

                else if (sessionType == "browserSession"){
                  const { filledData: retailBrowserSessionData } = fillMissingDates(_retailBrowserSession.current, firstDayOfTwoYearsAgo, lastDate);
                  const { filledData: corporateBrowserSessionData } = fillMissingDates(_corporateBrowserSession.current, firstDayOfTwoYearsAgo, lastDate);
                  
                  dat = [
                      {
                        label: 'Retail Browser Session',
                        data: retailBrowserSessionData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      },
                      {
                        label: 'Business Browser Session',
                        data: corporateBrowserSessionData,
                        borderColor: '#007798',
                        backgroundColor: '#007798',
                        lineTension: 0.4
                      },
                    ];
                  av1=findAverageCurrentData(retailBrowserSessionData);
                  av2=findAverageCurrentData(corporateBrowserSessionData);
              }
                else if (sessionType == "corporateBrowserSession"){
                    
                  const { filledData: corporateBrowserSessionData } = fillMissingDates(_corporateBrowserSession.current, firstDayOfTwoYearsAgo, lastDate);
                  dat = [
                        {
                          label: 'Business Browser Session',
                          data: corporateBrowserSessionData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        }
                      ];
                    av1=findAverageCurrentData(corporateBrowserSessionData);
                }

                else if (sessionType == "orderItemSessionPercent"){
                    const { filledData: retailOrderItemSessionPercentData } = fillMissingDates(_retailOrderItemSessionPercent.current, firstDayOfTwoYearsAgo, lastDate);
                    const { filledData: corporateOrderItemSessionPercentData } = fillMissingDates(_corporateOrderItemSessionPercent.current, firstDayOfTwoYearsAgo, lastDate);
                  
                    dat = [
                        {
                          label: 'Retail Order Item Session Percentage',
                          data: retailOrderItemSessionPercentData,
                          borderColor: '3c3e3d',
                          backgroundColor: '3c3e3d',
                          lineTension: 0.4
                        },
                        {
                          label: 'Business Order Item Session Percentage',
                          data: corporateOrderItemSessionPercentData,
                          borderColor: '#007798',
                          backgroundColor: '#007798',
                          lineTension: 0.4
                        },
                      ];
                    av1=findAverageCurrentData(retailOrderItemSessionPercentData);
                    av2=findAverageCurrentData(corporateOrderItemSessionPercentData);
                }
              

                else if (sessionType == "corporateOrderItemSessionPercent"){
                  const { filledData: corporateOrderItemSessionPercentData } = fillMissingDates(_corporateOrderItemSessionPercent.current, firstDayOfTwoYearsAgo, lastDate);
                  dat = [
                      {
                        label: 'Business Order Item Session Percentage',
                        data: corporateOrderItemSessionPercentData,
                        borderColor: '3c3e3d',
                        backgroundColor: '3c3e3d',
                        lineTension: 0.4
                      }
                    ];
                  av1=findAverageCurrentData(corporateOrderItemSessionPercentData);
                }
              
                const chartData = {
                  labels: dateLabels,
                  datasets: dat,
                };
                console.log(chartData);
                setIsLoading(false);
                setSelectValue("lastTwoYears");
                setChartData(chartData);
                setAverages(av1, av2);
                break;
              }
              

          }
      }

      function handleDropdownChange (e){
    
          _filterBy.current = e.target.value;
          filter();
          
      }
      
      function handleDropdownChangeSessionType  (e){
          
          _sessionType.current = e.target.value;
          filter();
          
      }
    
    return (
        
        
                    <Flex
                        gap="32px"
                        direction="column"
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        overflow="hidden"
                        position="relative"
                        
                        >
                        <Flex
                            gap="8px"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            <Text
                            fontFamily="Inter"
                            fontSize="32px"
                            fontWeight="500"
                            color="rgba(13,26,38,1)"
                            lineHeight="40px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="Conversion Report"
                            
                            ></Text>
                        </Flex>
                        <Flex
                            gap="32px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="center"
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            
                                {averages1}
                                
                            
                               {averages2}
                               
                            
                            {/* <Flex
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                            >
                                <div>Average for Ordered Product Sales Not B2B 
              {currency} {orderedProductSalesNotB2BModel}
            </div>
                            </Flex> */}
                        </Flex>
                        
                    
                        <Flex
                        gap="48px"
                        direction="column"
                        width="100%"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                    
                        >
                            <Flex
                                gap="13%" //TODO make it 32px if filter by date and asin is back
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="flex-start"//TODO: revert back to space-between
                                alignItems="flex-start"
                                shrink="0"
                                alignSelf="stretch"
                                position="relative"
                                padding="0px 7px 0px 40px"
                                
                            >
                            
                            { /*<Flex
                            gap="4px"
                            direction="column"
                            width="unset"
                            height="72px"
                            justifyContent="flex-start"
                            alignItems="center"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                            >
                                <SelectField
                                    width="300px"
                                    height="unset"
                                    label="Type"
                                    shrink="0"
                                    size="default"
                                    isDisabled={false}
                                    labelHidden={false}
                                    variation="default"
                                    onChange={moveAsinView}
                                    defaultValue="date"
                                >
                                  <option value="date">By Date</option>
                                  <option value="asin">By ASIN</option>
                                </SelectField>
                            </Flex> */ }
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                 <SelectField
                                        width="300px"
                                        height="unset"
                                        label="Type"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        value={subscriptionPlan || 'loading'} 
                                        label="Filter By Date" value={selectValue}  onChange={handleDropdownChange}
                                      >
                                          
                                      {subscriptionLoading ? (
                                          <option value="loading" disabled>
                                              <Loader size="small" /> Loading...
                                          </option>
                                      ) : (
                                          <>
                                            <option value="lastSevenDays">Last 7 Days</option>
                                            <option value="lastThirtyDays">Last 30 Days</option>
                                            <option value="thisWeek">This Week</option>
                                            <option value="thisMonth">This Month</option>
                                            {subscriptionPlan !== "Free Tier" && (
                                              <>
                                                <option value="lastOneYear">Last 1 Year</option>
                                                <option value="lastTwoYears">Last 2 Years</option>
                                              </>
                                            )}
                                        </>
                                      )}
                                      </SelectField>
                                </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Filter By"
                                        
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"

                                        onChange={handleDropdownChange}
                                        value={_sessionType.current}
                                        label="Session Type"  onChange={handleDropdownChangeSessionType}
                                      >

                                        <option value="mobileAppSession">Mobile App Session</option>
                                        <option value="browserSession">Browser Session</option>
                                        <option value="orderItemSessionPercent">Order Item Session %</option>
                                      </SelectField>
                                </Flex>
                               {/* <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Sort By"
                                        placeholder="Unit Session %"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        
                                    ></SelectField>
                                </Flex>  */}
                            </Flex>
                            
                             {isLoading ? <Flex/> : <Line options={lineChartOptions} data={chartData} width="400" height="100" />}
           
                            
            
                  
                        </Flex> 
                        <DialogModal open={dialogModalOpen} message={dialogModalMessage} onClose={handleCloseModal} />
      
                    </Flex>          
                
        
    );
}

export default withAuthenticator(ConversionReport);