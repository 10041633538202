import React from 'react'
import { useState,useEffect,useRef } from 'react';
import { Amplify, Auth, API, Storage,Cache } from 'aws-amplify';
import {Flex, View, Button,Text,
    Divider,Radio,SelectField,SearchField,TextField,Icon, Link} from '@aws-amplify/ui-react';
    import  NavBar  from '../components/navbar.tsx';
import studioTheme from '../ui-components/studioTheme';
import SideBar from '../ui-components/SideBar';
import AccountAccountInformation from '../ui-components/Forecasts'; 
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import { getUser } from '../graphql/queries';
import { createImportSpecifier } from 'typescript';
import Selectedbutton from '../ui-components/SelectedButton';
import ItemCard from '../ui-components/ItemCard';
import ButtonWithIcon2 from '../our-components/ButtonWithIcon2';
import { getOverrideProps } from "../our-components/utils";
import Partners from '../ui-components/Partners';
import moment from 'moment';
import SalesReportOptions from '../ui-components/SalesReportOptions';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ChartDataset,LabelItem, Color, CategoryScale, LinearScale, PointElement, LineElement, Tooltip} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

Amplify.configure(awsconfig);

function TrafficReportAsin({changeView}) {
    
    const [isLoading, setIsLoading] = useState(true);
    const [lineChartOptions, setLineChartOptions] = useState({});
    const [asinIsLoading, setasinIsLoading] = useState(true);
    const [selectValue, setSelectValue] = useState("time-lastThirtyDays");
    const [orderedProducts, setOrderedProducts] = useState({});
    const [asinOrderedProducts, setAsinOrderedProducts] = useState({});
    const [chartData,setChartData]= useState({});
  
    const [averages1,setAverages1]= useState("");
    const [averages2,setAverages2]= useState("");

    const [timePeriod, setTimePeriod] = useState("");
    const [sortBy, setSortBy] = useState(""); 

    const [asin, setAsin] = useState("");

    const _asinTraffic = useRef({});
    const _asinStrDates = useRef<String[]>([]);
    const _asinChartDates = useRef<String[]>([]);
    const _timePeriod = useRef("lastThirtyDays");
    const _sortBy = useRef("sessions");
    
    const _orderedProductSales= useRef<Array<number>>([]);
    const _orderedProductSalesB2B = useRef<Array<number>>([]);
    const _orderedProductSalesNotB2B = useRef<Array<number>>([]);

    let averageOfferCount = new Array<Number>();
    let averageParentItems = new Array<Number>();
    let browserPageViews = new Array<Number>();
    let browserSessions = new Array<Number>();
    
    let feedbackReceived = new Array<Number>();
    let mobileAppPageViews = new Array<Number>();
    
    let negativeFeedbackReceived = new Array<Number>();
    let orderItemSessionPercentage = new Array<Number>();
    let orderItemSessionPercentageB2B = new Array<Number>();
    
    let receivedNegativeFeedbackRate = new Array<Number>();
    
    let unitSessionPercentage = new Array<Number>();
    let unitSessionPercentageB2B = new Array<Number>();
    let unitSessionPercentageNotB2B = new Array<Number>();

    let asinSessions = new Array<Number>();
    let asinMobileAppSessions = new Array<Number>();
    let asinPageViews = new Array<Number>();
    let asinBuyBoxPercentage = new Array<Number>();
    let asinUnitSessionPercentage = new Array<Number>();
    let asinUnitSessionPercentageB2B = new Array<Number>();
    let asinUnitSessionPercentageNotB2B = new Array<Number>();

    const _filterBy = useRef<String>("");
    const _sessionType = useRef<String>("");
    const _pageViews = useRef<Number[]>([]);
    const _sessions = useRef<Number[]>([]);
    const _buyBoxPercentage = useRef<Number[]>([]);
    const _mobileAppSessions = useRef<Number[]>([]);

    let lineChartData: Number[] = [];
    let lineChartLabels: String[] = [];
    
    let asinLineChartOptions: any = {};
    let asinLineChartLabels: String[] = [];

    let trafficByDate = {};
    let asinParentList = {};
    let asinChildList = {};

    let asinStrDates = [];

    let asinSales = [];

    let view = "sales";
    
    useEffect(() => {
        ChartJS.register(CategoryScale,LinearScale, PointElement, LineElement,zoomPlugin, Tooltip);
        
    },[]);// <-- Have to pass in [] here or is called on every render
    

    function moveAsinView(e) {
      if(e.target.value == "date"){
        changeView('date');
      }
    }

    function onASINChange(event){
      setAsin(event.target.value);
    };
  
    // It is your responsibility to set up onClear
    function onASINClear(){
      console.log('clear');
    };
  
    function onASINClick(event){
      console.log(event.target.value);
    };

    function onASINSubmit(){
      if(asin.length > 3){
        downloadSingleAsin(asin);
      }
    };

    async function downloadSingleAsin(aa:String) {
      _asinStrDates.current = [];
      const market = Cache.getItem('marketCode');
      let url = market+ "_SalesAndTrafficReport/AsinTraffic/"+aa+".json";
      const result = await Storage.vault.get(url, { download: true });
      const asin = await new Response(result.Body).json();
      _asinTraffic.current = asin;
      sortAsinSTR();
    }

    function findAverageCurrentData(array){
        var total = 0;
        for(var i = 0; i < array.length; i++) {
            total += array[i];
        }
        let returnValue = (total / array.length).toFixed(2);
        if(returnValue == "NaN"){
          return 0;
        }
        return returnValue;
    }

    function setupData(){
      
      _asinChartDates.current = [..._asinStrDates.current];
      
      let temp;
      asinSessions = new Array<Number>();
      asinMobileAppSessions = new Array<Number>();
      asinPageViews = new Array<Number>();
      asinBuyBoxPercentage = new Array<Number>();
      asinUnitSessionPercentage = new Array<Number>();
      asinUnitSessionPercentageB2B = new Array<Number>();
      asinUnitSessionPercentageNotB2B = new Array<Number>();
      
      for(let i in _asinTraffic.current){
        asinSessions.push(_asinTraffic.current[i].sessions) 
        asinMobileAppSessions.push(_asinTraffic.current[i].mobileAppSessions)
        asinPageViews.push(_asinTraffic.current[i].pageViews)
        asinBuyBoxPercentage.push(_asinTraffic.current[i].buyBoxPercentage)
        asinUnitSessionPercentage.push(_asinTraffic.current[i].unitSessionPercentage) 
        asinUnitSessionPercentageB2B.push(_asinTraffic.current[i].unitSessionPercentageB2B)
        temp = Number(_asinTraffic.current[i].unitSessionPercentage) - Number(_asinTraffic.current[i].unitSessionPercentageB2B) 
        asinUnitSessionPercentageNotB2B.push(temp)  
        _asinChartDates.current.push(moment(_asinTraffic.current[i].date,"YYYY-MM-DD").format('MMM D, y'))
      
      }
      
      if(_asinChartDates.current.length < 30){
        
        let diff = 30 - _asinChartDates.current.length
        let firstDate = new Date (String(_asinChartDates.current[0]))
        
        for(let i = diff; i > 0; i--)
        {
          firstDate.setDate(firstDate.getDate() - 1)
          _asinChartDates.current.unshift(moment(firstDate).format('MMM D, y'))
          asinSessions.unshift(0 as Number)
          asinMobileAppSessions.unshift(0 as Number)
          asinPageViews.unshift(0 as Number)
          asinBuyBoxPercentage.unshift(0 as Number)
          asinUnitSessionPercentage.unshift(0 as Number)
          asinUnitSessionPercentageB2B.unshift(0 as Number)
          asinUnitSessionPercentageNotB2B.unshift(0 as Number)
        }
      }
      
    }

    async function sortAsinSTR(){
      
      filter();
    }

    function setAverages(average1, average2){
      
      if(_sortBy.current == 'sessions'){
        setAverages1(<Flex
          width="unset"
          height="unset"
          justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      border="1px SOLID rgba(174,179,183,1)"
      borderRadius="12px"
      padding="31px 31px 31px 31px"
      backgroundColor="rgba(255,255,255,1)"
          ><ItemCard overrides={{Name:{children:"Average for Sessions"}, Number:{children:average1}}}></ItemCard></Flex>)
        setAverages2(<Flex
          width="unset"
          height="unset"
          justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      border="1px SOLID rgba(174,179,183,1)"
      borderRadius="12px"
      padding="31px 31px 31px 31px"
      backgroundColor="rgba(255,255,255,1)"
          ><ItemCard overrides={{Name:{children:"Average for Mobile Sessions"}, Number:{children:average2}}}></ItemCard></Flex>)
        
        
      }
      else if(_sortBy.current == 'pageViews'){
        setAverages1(<Flex
          width="unset"
          height="unset"
          justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      border="1px SOLID rgba(174,179,183,1)"
      borderRadius="12px"
      padding="31px 31px 31px 31px"
      backgroundColor="rgba(255,255,255,1)"
          ><ItemCard overrides={{Name:{children:"Average for Page Views"}, Number:{children:average1}}}></ItemCard></Flex>)
      
        setAverages2("")
      }
      else if(_sortBy.current == 'buyBoxPercent'){
        setAverages1(<Flex
          width="unset"
          height="unset"
          justifyContent="flex-start"
      alignItems="flex-start"
      position="relative"
      border="1px SOLID rgba(174,179,183,1)"
      borderRadius="12px"
      padding="31px 31px 31px 31px"
      backgroundColor="rgba(255,255,255,1)"
          ><ItemCard overrides={{Name:{children:"Average for Buy Box %"}, Number:{children:average1}}}></ItemCard></Flex>)
        
       
        setAverages2("");
      }
    }

    function sliceArrayToTimePeriod(inputArray){
      let returnArray;
      const now = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());

      if(_timePeriod.current == "lastThirtyDays"){
        returnArray = inputArray.slice(Math.max(inputArray.length - 30, 1));
      }
      else if(_timePeriod.current == "lastSevenDays"){
        returnArray =inputArray.slice(Math.max(inputArray.length - 7, 1));
      }
      else if(_timePeriod.current == "thisWeek"){
        let y = _asinChartDates.current[_asinChartDates.current.length-1]
        // console.log(_asinStrDates.current)
        
        const lastDate = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());
        const date = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        let x = new Date(date.setDate(diff));
        const firstDay = moment(x).format('MMM D, y')
      
        let index = _asinChartDates.current.indexOf(firstDay);
        if(lastDate.getDay() === 1 && _asinChartDates.current.length > 8)
        {
          index -= 7;
        }
        if(index === -1)
        {
          index = 0;
        }
        
        const lastDay = moment(lastDate).format('MMM D, y')
        let firstIndex = asinLineChartLabels.indexOf(firstDay);
        let lastIndex = asinLineChartLabels.indexOf(lastDay);
        
        returnArray =inputArray.slice(firstIndex,lastIndex);
      }
      else if(_timePeriod.current == "thisMonth"){
        const lastDate = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());
        const now = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());
        const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1,)).format('MMM D, y')//this.datepipe.transform(new Date(now.getFullYear(), now.getMonth(), 1,), 'MMM d, y');
        // console.log(firstDay);
        // console.log(lastDate);
        let index = _asinChartDates.current.indexOf(firstDay);
        // console.log(firstDay);
        // console.log(lastDate);
        const lastDay = moment(lastDate).format('MMM D, y')
        let firstIndex = _asinChartDates.current.indexOf(firstDay);
        let lastIndex = _asinChartDates.current.indexOf(lastDay);
        // console.log(firstIndex);
        // console.log(lastIndex);
        // console.log(inputArray.slice(firstIndex,lastIndex));
        returnArray =inputArray.slice(firstIndex,lastIndex);
      }
      else if (_timePeriod.current === "lastOneYear" || _timePeriod.current === "lastTwoYears") {
        const yearsToSubtract = _timePeriod.current === "lastOneYear" ? 1 : 2;
        const startDate = new Date(now.getFullYear() - yearsToSubtract, now.getMonth(), now.getDate());
        
        returnArray = [];
        let currentDate = new Date(startDate);
        
        while (currentDate <= now) {
          const formattedDate = moment(currentDate).format('MMM D, y');
          const index = _asinChartDates.current.indexOf(formattedDate);
          
          if (index !== -1) {
            // If the date exists in the original data, use the original value
            returnArray.push(inputArray[index]);
          } else {
            // If the date doesn't exist, add a placeholder based on data type
            if (typeof inputArray[0] === 'number') {
              returnArray.push(0);
            } else if (inputArray[0] instanceof Date || typeof inputArray[0] === 'string') {
              returnArray.push(formattedDate);
            } else {
              // For any other type, push null or an appropriate placeholder
              returnArray.push(null);
            }
          }
          
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
    
      return returnArray;
  }

    function filter(){
      setupData();
      setLineChartOptions( {
          responsive: true,
          showTooltips: true,
      //   scales: {
      //     yAxes:[  {
      //       //  scaleLabel: {
      //       //     display: true,
      //       //     labelString: 'USD'//market.selectedCurency
      //       //  },
      //       //  ticks:{
      //       //       callback: function(value, index, values) {
      //       //         return '$' + value;
      //       //     }
      //       //  }
            
      //       }
      //     ]
         
      //  },
          plugins: {
            tooltip: {
              callbacks: {
                  label: function (tooltipItem, data) {
                      return " "+tooltipItem.dataset.label + " "+tooltipItem.formattedValue;
                  }
              }
          },
            // zoom: {
            //   zoom: {
            //     wheel: {
            //       enabled: true,
            //     },
            //     pinch: {
            //       enabled: true
            //     },
            //     mode: 'xy',
            //   }
            // },
            legend: {
              display:true,
              title:{
                display: true
              }
            },
            title: {
              display: true,
              text: 'Chart.js Line Chart',
            },
          },
        });
      
        asinLineChartLabels = _asinChartDates.current;
        
        switch(_sortBy.current){
          case 'sessions':{
            asinLineChartOptions = {
              responsive: true,
            //   tooltips: {
            //     callbacks: {
            //         label: function (tooltipItem, data) {
            //             return Number(tooltipItem.yLabel);
            //         }
            //     }
            // },
            //   scales: {
            //     yAxes:[  {
            //         scaleLabel: {
            //           display: true,
            //           labelString: "Sessions"
            //         }
                  
            //   }
            // ]
                
            //   },
              plugins:{
                legend:{
                  display:true,
                  title:{
                    display: true,
                    text: "Example"
                  }
                }
              },
          
            };
            // console.log(asinSessions)
            // console.log(sliceArrayToTimePeriod(asinSessions))
            // console.log(sliceArrayToTimePeriod(asinMobileAppSessions))
            const asinOrderedProducts = {
              labels : sliceArrayToTimePeriod(asinLineChartLabels),
              datasets: [
                {
                  data:sliceArrayToTimePeriod(asinSessions),
                  label:"Sessions", 
                  borderColor: '#80956e',
                  
                  backgroundColor: '80956e',
                  lineTension: 0.4
                },
                {
                  data:sliceArrayToTimePeriod(asinMobileAppSessions),
                  label:"Mobile Sessions", 
                  borderColor: '#80956e',
                  backgroundColor: '#80956e',
                  lineTension: 0.4
                }
              ],
            };
            let av1=findAverageCurrentData(sliceArrayToTimePeriod(asinSessions));
            let av2=findAverageCurrentData(sliceArrayToTimePeriod(asinMobileAppSessions));
            setAverages(av1,av2);
            setLineChartOptions(asinLineChartOptions);
            setIsLoading(false);
            setChartData(asinOrderedProducts);
            
            break;
          }
          case 'pageViews':{
            asinLineChartOptions = {
              responsive: true,
            //   tooltips: {
            //     callbacks: {
            //         label: function (tooltipItem, data) {
            //             return Number(tooltipItem.yLabel);
            //         }
            //     }
            // },
            //   scales: {
            //     yAxes:[  {
            //         scaleLabel: {
            //           display: true,
            //           labelString: "Page Views"
            //         }
                  
            //   }
            // ]
                
            //   },
              plugins:{
                legend:{
                  display:true,
                  title:{
                    display: true,
                    text: "Example"
                  }
                }
              },
          
            };
            const asinOrderedProducts = {
              labels : sliceArrayToTimePeriod(asinLineChartLabels),
              datasets: [
                {
                  data:sliceArrayToTimePeriod(asinPageViews),
                  label:"Page Views", 
                  borderColor: '#80956e',
                  
                  backgroundColor: '80956e',
                  lineTension: 0.4
                }
              ],
            };
            let av1=findAverageCurrentData(sliceArrayToTimePeriod(asinPageViews));
            let av2=0;
            setAverages(av1,av2);
            setLineChartOptions(asinLineChartOptions);
            setIsLoading(false);
            setChartData(asinOrderedProducts);
            
            break;
          }
          case 'buyBoxPercent':{
            asinLineChartOptions = {
              responsive: true,
            //   tooltips: {
            //     callbacks: {
            //         label: function (tooltipItem, data) {
            //             return Number(tooltipItem.yLabel);
            //         }
            //     }
            // },
            //   scales: {
            //     yAxes:[  {
            //         scaleLabel: {
            //           display: true,
            //           labelString: "Percent"
            //         }
                  
            //   }
            // ]
                
            //   },
              plugins:{
                legend:{
                  display:true,
                  title:{
                    display: true,
                    text: "Example"
                  }
                }
              },
          
            };
            const asinOrderedProducts = {
              labels : sliceArrayToTimePeriod(asinLineChartLabels),
              datasets: [
                {
                  data:sliceArrayToTimePeriod(asinBuyBoxPercentage),
                  label:"Buy Box %", 
                  borderColor: '#80956e',
                  
                  backgroundColor: '80956e',
                  lineTension: 0.4
                }
              ],
            };
            let av1=findAverageCurrentData(sliceArrayToTimePeriod(asinBuyBoxPercentage));
            let av2=0;
            setAverages(av1,av2);
            setLineChartOptions(asinLineChartOptions);
            setIsLoading(false);
            setChartData(asinOrderedProducts);
            
            break;
          }
          case 'unitSessionPercent':{    

            //!! TODO IS this case even used

            asinLineChartOptions = {
              responsive: true,
            //   tooltips: {
            //     callbacks: {
            //         label: function (tooltipItem, data) {
            //             return Number(tooltipItem.yLabel);
            //         }
            //     }
            // },
            //   scales: {
            //     yAxes:[  {
            //         scaleLabel: {
            //           display: true,
            //           labelString: "Percent"
            //         }
                  
            //   }
            // ]
                
            //   },
              plugins:{
                legend:{
                  display:true,
                  title:{
                    display: true,
                    text: "Example"
                  }
                }
              },
          
            };
            const asinOrderedProducts = {
              labels : sliceArrayToTimePeriod(asinLineChartLabels),
              datasets: [
                {
                  data:sliceArrayToTimePeriod(asinUnitSessionPercentage),
                  label:"Unit Session Percentage", 
                  borderColor: '#80956e',
                  
                  backgroundColor: '#80956e',
                  lineTension: 0.4
                },
                {
                  data:sliceArrayToTimePeriod(asinUnitSessionPercentageB2B),
                  label:"Unit Session Percentage Corporate", 
                  borderColor: '#0000ff',
                  
                  backgroundColor: '#0000ff',
                  lineTension: 0.4
                },
                {
                  data:sliceArrayToTimePeriod(asinUnitSessionPercentageNotB2B),
                  label:"Unit Session Percentage Retail", 
                  borderColor: '#00ff00',
                  
                  backgroundColor: '#00ff00',
                  lineTension: 0.4
                }
              ],
            };
            let av1=findAverageCurrentData(sliceArrayToTimePeriod(asinBuyBoxPercentage));
            let av2=0;
            setAverages(av1,av2);
            setLineChartOptions(asinLineChartOptions);
            setIsLoading(false);
            setChartData(asinOrderedProducts);
            
            break;
          }
        }
        
      }


      function handleDropdownChange (e){
        let sort = sortBy;
        let time = timePeriod;
        // console.log(e.target.value);
        if(e.target.value.includes("time-")){
          setSelectValue(e.target.value);
          time = e.target.value.replace("time-","");
          setTimePeriod(time);
          _timePeriod.current = time;
        }
        else {
          sort = e.target.value.replace("sort-","");
          // console.log(sort);
          setSortBy(sort);
          _sortBy.current = sort;
        }
        filter();
          
      }
    
    return (
        
        
      <Flex
      gap="32px"
      direction="column"
      width="1194px"
      height="842px"
      justifyContent="flex-start"
      alignItems="flex-start"
      overflow="hidden"
      position="relative"
      padding="32px 32px 32px 32px"
      
      >
                        <Flex
                            gap="8px"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            <Text
                            fontFamily="Inter"
                            fontSize="32px"
                            fontWeight="500"
                            color="rgba(13,26,38,1)"
                            lineHeight="40px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="Traffic Report By ASIN"
                            
                            ></Text>
                            <SearchField
          label="Search"
          placeholder="Enter ASIN"
          onChange={onASINChange}
          onClear={onASINClear}
          onClick={onASINClick}
          onSubmit={onASINSubmit}
          size="large"
        />
                        </Flex>
                        <Flex
                            gap="32px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="center"
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            
                                {averages1}
                                
                           
                            
                               {averages2}
                               
                            
                            {/* <Flex
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                            >
                                <div>Average for Ordered Product Sales Not B2B 
              {currency} {orderedProductSalesNotB2BModel}
            </div>
                            </Flex> */}
                        </Flex>
                        
                    
                        <Flex
                        gap="48px"
                        direction="column"
                        width="1130px"
                        height="535px"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                    
                        >
                            <Flex
                                gap="32px"
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="center"
                                alignItems="flex-start"
                                shrink="0"
                                alignSelf="stretch"
                                position="relative"
                                padding="0px 20px 0px 20px"
                                
                            ><Flex
                            gap="4px"
                            direction="column"
                            width="unset"
                            height="72px"
                            justifyContent="flex-start"
                            alignItems="center"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                            >
                                <SelectField
                                    width="300px"
                                    height="unset"
                                    label="Type"
                                    shrink="0"
                                    size="default"
                                    isDisabled={false}
                                    labelHidden={false}
                                    variation="default"
                                    onChange={moveAsinView}
                                    defaultValue="asin"
                                >
                                  <option value="date" >By Date</option>
                                  <option value="asin">By ASIN</option>
                                </SelectField>
                            </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="300px"
                                        height="unset"
                                        label="Type"
                                        placeholder="By Date"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        
                                       
                                        label="Filter By Date" value={selectValue}  onChange={handleDropdownChange}
                                      >
                                          
                                          <option value="time-lastSevenDays">Last 7 Days</option>
                                          <option value="time-lastThirtyDays">Last 30 Days</option>
                                          <option value="time-thisWeek">This Week</option>
                                          <option value="time-thisMonth">This Month</option>
                                          <option value="time-lastOneYear">Last 1 Year</option>
                                          <option value="time-lastTwoYears">Last 2 Years</option>
                                      </SelectField>
                                </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Filter By"
                                        
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                    
                                        onChange={handleDropdownChange}
                                       
                                        label="Session Type"  onChange={handleDropdownChange}
                                      >
                                          <option value="sort-sessions">Sessions</option>
                                          <option value="sort-pageViews">Page Views</option>
                                          <option value="sort-buyBoxPercent">Buy Box %</option>
                                      </SelectField>
                                </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    {/* <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Sort By"
                                        placeholder="Unit Session %"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        
                                    ></SelectField> */}
                                </Flex>
                            </Flex>
                            
                             {isLoading ? <Flex/> : <Line options={lineChartOptions} data={chartData} width="400" height="100" />}
           
                            
            
                  
                        </Flex> 
                    </Flex>          
                
        
    );
}

export default withAuthenticator(TrafficReportAsin);