import React from 'react'
import { useState,useEffect,useRef } from 'react';
import { Amplify, Auth, API, Storage, Cache } from 'aws-amplify';
import {Flex, View, Button,Text,
    Divider,Radio,SelectField,TextField,Icon, Link, SearchField} from '@aws-amplify/ui-react';
import  NavBar  from '../components/navbar.tsx';
import studioTheme from '../ui-components/studioTheme';
import SideBar from '../ui-components/SideBar';
import AccountAccountInformation from '../ui-components/Forecasts'; 
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import { getUser } from '../graphql/queries';
import { createImportSpecifier } from 'typescript';
import Selectedbutton from '../ui-components/SelectedButton';
import ItemCard from '../ui-components/ItemCard';
import Partners from '../ui-components/Partners';
import moment from 'moment';
import SalesReportOptions from '../ui-components/SalesReportOptions';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ChartDataset,LabelItem, Color, CategoryScale, LinearScale, PointElement, LineElement, Tooltip} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

Amplify.configure(awsconfig);

function SalesReportAsin({changeView}) {
    // testing asin  B0026GV5IW
    const [isLoading, setIsLoading] = useState(true);
    const [lineChartOptions, setLineChartOptions] = useState({});
    const [asinIsLoading, setasinIsLoading] = useState(true);
    const [selectValue, setSelectValue] = useState("time-lastThirtyDays");
    const [orderedProducts, setOrderedProducts] = useState({});
    const [asinOrderedProducts, setAsinOrderedProducts] = useState({});
    const [currency, setCurrency] = useState("USD");
    
    const [averageAsinOrderedProductSales,setAverageAsinOrderedProductSales] = useState(0);
    const [orderedAsinProductSalesB2B,setOrderedAsinProductSalesB2B] = useState(0);
    const [orderedAsinProductSalesNotB2B,setOrderedAsinProductSalesNotB2B] = useState(0);

    const [timePeriod, setTimePeriod] = useState("");
    const [sortBy, setSortBy] = useState(""); 

    const [asin, setAsin] = useState("");
    
    const _asinSales = useRef({});
    const _asinStrDates = useRef<String[]>([]);
    const _timePeriod = useRef("lastThirtyDays");
    const _sortBy = useRef("orderedProductSales");

    const [averages1,setAverages1]= useState("");
    const [averages2,setAverages2]= useState("");
    const [averages3,setAverages3]= useState("");
    
    let averageSalesPerOrderItem: Array<number> = [];
    let averageSalesPerOrderItemB2B: Array<number> = [];
    let averageSellingPrice: Array<number> = [];
    let averageSellingPriceB2B: Array<number> = [];
    let averageUnitsPerOrderItem: Array<number> = [];
    let averageUnitsPerOrderItemB2B: Array<number> = [];
    let claimsAmount: Array<number> = [];
    let claimsGranted: Array<number> = [];
    let ordersShipped: Array<number> = [];
    let refundRate: Array<number> = [];
    let shippedProductSales: Array<number> = [];
    let totalOrderItems: Array<number> = [];
    let totalOrderItemsB2B: Array<number> = [];
    let totalOrderItemsNotB2B: Array<number> = [];
    let unitsOrdered: Array<number> = [];
    let unitsOrderedB2B: Array<number> = [];
    let unitsOrderedNotB2B: Array<number> = [];
    let unitsRefunded: Array<number> = [];
    let unitsShipped: Array<number> = [];

    //asin items
    let asinOrderedProductSales: Array<number> = [];
    let asinOrderedProductSalesB2B: Array<number> = [];
    let asinOrderedProductSalesNotB2B: Array<number> = [];
    let asinUnitsOrdered: Array<number> = [];
    let asinUnitsOrderedB2B: Array<number> = [];
    let asinUnitsOrderedNotB2B: Array<number> = [];
    let asinTotalOrderItems: Array<number> = [];
    let asinTotalOrderItemsB2B: Array<number> = [];
    let asinTotalOrderItemsNotB2B: Array<number> = [];
    

    let lineChartData: Number[] = [];
    let lineChartLabels: String[] = [];
    
    let asinLineChartOptions: any = {};
    let asinLineChartLabels: String[] = [];

    let salesByDate = {};
    let asinParentList = {};
    let asinChildList = {};


    let view = "sales";
    
    useEffect(() => {
        ChartJS.register(CategoryScale,LinearScale, PointElement, LineElement,zoomPlugin, Tooltip);
    },[]);// <-- Have to pass in [] here or is called on every render
    
    function moveAsinView(e) {
      if(e.target.value == "date"){
        changeView('date');
      }
    }

    function onASINChange(event){
      setAsin(event.target.value);
    };
  
    // It is your responsibility to set up onClear
    function onASINClear(){
      console.log('clear');
    };
  
    function onASINClick(event){
      console.log(event.target.value);
    };

    function onASINSubmit(){
      if(asin.length > 3){
        downloadSingleAsin(asin);
      }
    };

    async function downloadSingleAsin(aa:String) {
      _asinStrDates.current = [];
      const market = Cache.getItem('marketCode');
      let url = market+ "_SalesAndTrafficReport/AsinSales/"+aa+".json";
      const result = await Storage.vault.get(url, { download: true });
      const asin = await new Response(result.Body).json();
      _asinSales.current = asin;
      sortAsinSTR();
    }

    function setupData(){
      let temp;
      for(let i in _asinSales.current){
        //console.log(_asinSales.current[i]);
        asinOrderedProductSales.push(_asinSales.current[i].orderedProductSales.amount)    
        asinOrderedProductSalesB2B.push(_asinSales.current[i].orderedProductSalesB2B.amount)
        temp = Number(_asinSales.current[i].orderedProductSales.amount) - Number(_asinSales.current[i].orderedProductSalesB2B.amount)
        asinOrderedProductSalesNotB2B.push(temp)  
    
        asinTotalOrderItems.push(_asinSales.current[i].totalOrderItems) 
        asinTotalOrderItemsB2B.push(_asinSales.current[i].totalOrderItemsB2B)
        temp = Number(_asinSales.current[i].totalOrderItems) - Number(_asinSales.current[i].totalOrderItemsB2B)
        asinTotalOrderItemsNotB2B.push(temp)
        asinUnitsOrdered.push(_asinSales.current[i].unitsOrdered)
        asinUnitsOrderedB2B.push(_asinSales.current[i].unitsOrderedB2B)
        temp = Number(_asinSales.current[i].unitsOrdered) - Number(_asinSales.current[i].unitsOrderedB2B)
        asinUnitsOrderedNotB2B.push(temp)
        
        _asinStrDates.current.push(moment(_asinSales.current[i].date,"YYYY-MM-DD").format('MMM D, y'))
      }
    }
  
    function sortAsinSTR(){
      
      let temp;
      
      setupData();
      
      //TODO should this be in setupdata()
      if(_asinStrDates.current.length < 30){
        
        let diff = 30 - _asinStrDates.current.length
        let firstDate = new Date (String(_asinStrDates.current[0]))
        
        for(let i = diff; i > 0; i--)
        {
          firstDate.setDate(firstDate.getDate() - 1)
          //moment(salesByDate[i].date,"YYYY-MM-DD").format('MMM D, y')
          _asinStrDates.current.unshift(moment(firstDate).format('MMM D, y'))
          asinOrderedProductSales.unshift(0)
          asinOrderedProductSalesB2B.unshift(0)
          asinOrderedProductSalesNotB2B.unshift(0)
          asinUnitsOrdered.unshift(0)
          asinUnitsOrderedB2B.unshift(0)
          asinUnitsOrderedNotB2B.unshift(0)
          asinTotalOrderItems.unshift(0)
          asinTotalOrderItemsB2B.unshift(0)
          asinTotalOrderItemsNotB2B.unshift(0)
        }
      }
      
      asinLineChartOptions = {
        responsive: true,
        showTooltips: true,
        scales: {
          y: {
              ticks: {
                  // Include a dollar sign in the ticks
                  callback: function(value, index, ticks) {
                      return currency + value;
                  }
              }
          }
      },
        plugins: {
          tooltip: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return " "+tooltipItem.dataset.label + " "+currency+tooltipItem.formattedValue;
                }
            }
        },
          // zoom: {
          //   zoom: {
          //     wheel: {
          //       enabled: true,
          //     },
          //     pinch: {
          //       enabled: true
          //     },
          //     mode: 'xy',
          //   }
          // },
          legend: {
            display:true,
            title:{
              display: true
            }
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
        },
      };
    
      asinLineChartLabels = _asinStrDates.current;
      const asinOrderedProducts = {
        labels : asinLineChartLabels.slice(Math.max(asinLineChartLabels.length - 30, 1)),
        datasets: [
          {
            label: 'Ordered Product Sales',
            data: asinOrderedProductSales.slice(Math.max(asinOrderedProductSales.length - 30, 1)),
            borderColor: '3c3e3d',
            backgroundColor: '3c3e3d',
            lineTension: 0.4
          },
          {
            label: 'Ordered Product Sales Corporate',
            data: asinOrderedProductSalesB2B.slice(Math.max(asinOrderedProductSalesB2B.length - 30, 1)),
            borderColor: '#007798',
            backgroundColor: '#007798',
            lineTension: 0.4
          },
          {
            label: 'Ordered Product Sales Retail',
            data: asinOrderedProductSalesNotB2B.slice(Math.max(asinOrderedProductSalesNotB2B.length - 30, 1)),
            borderColor: '#427f3d',
            backgroundColor: '#427f3d',
            lineTension: 0.4
          }
        ],
      };
      setLineChartOptions(asinLineChartOptions);
      setIsLoading(false);
      setAsinOrderedProducts(asinOrderedProducts);
      setSelectValue("time-lastThirtyDays");
      setTimePeriod("lastThirtyDays");
      setSortBy("orderedProductSales");
      
      let av1 = (findAverageCurrentData(asinOrderedProductSales.slice(Math.max(asinOrderedProductSales.length - 30, 1))));
      let av2 =(findAverageCurrentData(asinOrderedProductSalesB2B.slice(Math.max(asinOrderedProductSalesB2B.length - 30, 1))));
      let av3 =(findAverageCurrentData(asinOrderedProductSalesNotB2B.slice(Math.max(asinOrderedProductSalesNotB2B.length - 30, 1))));
      setAverages(av1,av2,av3);
    }

    function generateDateArray(startDate, endDate) {
      let dateArray = [];
      let currentDate = moment(startDate);
    
      while (currentDate <= moment(endDate)) {
        dateArray.push(currentDate.format('MMM D, y'));
        currentDate.add(1, 'days');
      }
    
      return dateArray;
    }

    function fillDataWithCompleteDates(dataArray, startDate, endDate, allLabels) {
      const completeDates = generateDateArray(startDate, endDate);
      let filledData = [];
    
      completeDates.forEach(date => {
        let index = allLabels.indexOf(date);
        if (index !== -1) {
          filledData.push(dataArray[index]);
        } else {
          filledData.push(0); // Fill missing dates with 0
        }
      });
    
      return { filledData, completeDates };
    }

    function setAverages(average1, average2, average3){
      
     
      
      if(_sortBy.current == 'orderedProductSales'){
        setAverages1(<ItemCard overrides={{Name:{children:"Average for Ordered Product Sales"}, Number:{children:average1}}}></ItemCard>)
        setAverages2(<ItemCard overrides={{Name:{children:"Average Sales Corporate"}, Number:{children:average2}}}></ItemCard>)
        setAverages3(<ItemCard overrides={{Name:{children:"Average Sales Retail"}, Number:{children:average3}}}></ItemCard>)
        
      }
      else if(_sortBy.current == 'unitsOrdered'){
        setAverages1(<ItemCard overrides={{Name:{children:"Average for Units Ordered"}, Number:{children:average1}}}></ItemCard>)
        setAverages2(<ItemCard overrides={{Name:{children:"Average Sales Corporate"}, Number:{children:average2}}}></ItemCard>)
        setAverages3(<ItemCard overrides={{Name:{children:"Average Sales Retail"}, Number:{children:average3}}}></ItemCard>)
        
      }
      else if(_sortBy.current == 'totalOrderItems'){
        setAverages1(<ItemCard overrides={{Name:{children:"Average Total Order Items"}, Number:{children:average1}}}></ItemCard>)
        setAverages2(<ItemCard overrides={{Name:{children:"Average Sales Corporate"}, Number:{children:average2}}}></ItemCard>)
        setAverages3(<ItemCard overrides={{Name:{children:"Average Sales Retail"}, Number:{children:average3}}}></ItemCard>)
        
      }
      
    }

    function findAverageCurrentData(array){
        var total = 0;
        for(var i = 0; i < array.length; i++) {
          total += (array[i] !== undefined ? array[i] : 0);
        }
        let returnValue = (total / array.length).toFixed(2);
    
        if(returnValue == "NaN"){
          return 0;
        }
        return returnValue;
    }

    function sliceArrayToTimePeriod(inputArray){
        let returnArray;
        const now = new Date(_asinStrDates.current[_asinStrDates.current.length-1].toString());

        if(_timePeriod.current == "lastThirtyDays"){
          returnArray = inputArray.slice(Math.max(inputArray.length - 30, 1));
        }
        else if(_timePeriod.current == "lastSevenDays"){
          returnArray =inputArray.slice(Math.max(inputArray.length - 7, 1));
        }
        else if(_timePeriod.current == "thisWeek"){
          let y = _asinStrDates.current[_asinStrDates.current.length-1]
          // console.log(_asinStrDates.current)
          
          const lastDate = new Date(_asinStrDates.current[_asinStrDates.current.length-1].toString());
          const date = new Date(_asinStrDates.current[_asinStrDates.current.length-1].toString());
          const day = date.getDay();
          const diff = date.getDate() - day + (day === 0 ? -6 : 1);
          let x = new Date(date.setDate(diff));
          const firstDay = moment(x).format('MMM D, y')
        
          let index = _asinStrDates.current.indexOf(firstDay);
          if(lastDate.getDay() === 1 && _asinStrDates.current.length > 8)
          {
            index -= 7;
          }
          if(index === -1)
          {
            index = 0;
          }
          
          const lastDay = moment(lastDate).format('MMM D, y')
          let firstIndex = asinLineChartLabels.indexOf(firstDay);
          let lastIndex = asinLineChartLabels.indexOf(lastDay);
          
          returnArray =inputArray.slice(firstIndex,lastIndex);
        }
        else if(_timePeriod.current == "thisMonth"){
          const lastDate = new Date(_asinStrDates.current[_asinStrDates.current.length-1].toString());
          const now = new Date(_asinStrDates.current[_asinStrDates.current.length-1].toString());
          const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1,)).format('MMM D, y')//this.datepipe.transform(new Date(now.getFullYear(), now.getMonth(), 1,), 'MMM d, y');
          // console.log(firstDay);
          // console.log(lastDate);
          let index = _asinStrDates.current.indexOf(firstDay);
          // console.log(firstDay);
          // console.log(lastDate);
          const lastDay = moment(lastDate).format('MMM D, y')
          let firstIndex = _asinStrDates.current.indexOf(firstDay);
          let lastIndex = _asinStrDates.current.indexOf(lastDay);
          // console.log(firstIndex);
          // console.log(lastIndex);
          // console.log(inputArray.slice(firstIndex,lastIndex));
          returnArray =inputArray.slice(firstIndex,lastIndex);
        }
        else if (_timePeriod.current === "lastOneYear" || _timePeriod.current === "lastTwoYears") {
          const yearsToSubtract = _timePeriod.current === "lastOneYear" ? 1 : 2;
          const startDate = new Date(now.getFullYear() - yearsToSubtract, now.getMonth(), now.getDate());
          
          returnArray = [];
          let currentDate = new Date(startDate);
          
          while (currentDate <= now) {
            const formattedDate = moment(currentDate).format('MMM D, y');
            const index = _asinStrDates.current.indexOf(formattedDate);
            
            if (index !== -1) {
              // If the date exists in the original data, use the original value
              returnArray.push(inputArray[index]);
            } else {
              // If the date doesn't exist, add a placeholder based on data type
              if (typeof inputArray[0] === 'number') {
                returnArray.push(0);
              } else if (inputArray[0] instanceof Date || typeof inputArray[0] === 'string') {
                returnArray.push(formattedDate);
              } else {
                // For any other type, push null or an appropriate placeholder
                returnArray.push(null);
              }
            }
            
            currentDate.setDate(currentDate.getDate() + 1);
          }
        }
        return returnArray;
    }

    function handleDropdownChange(e) {
      
      let sort = sortBy;
      let time = timePeriod;
      // console.log(e.target.value);
      if(e.target.value.includes("time-")){
        setSelectValue(e.target.value);
        time = e.target.value.replace("time-","");
        setTimePeriod(time);
        
        _timePeriod.current = time;
      }
      else {
        sort = e.target.value.replace("sort-","");
        // console.log(sort);
        setSortBy(sort);
        _sortBy.current = sort;
      }

      
      setupData();
      
      asinLineChartOptions = {
        responsive: true,
        // tooltips: {
        //   callbacks: {
        //       label: function (tooltipItem, data) {
        //           return Number(tooltipItem.yLabel);
        //       }
        //   }
        // },
    //   scales: {
    //     yAxes:[  {
    //       //  scaleLabel: {
    //       //     display: true,
    //       //     labelString: 'USD'//market.selectedCurency
    //       //  },
    //       //  ticks:{
    //       //       callback: function(value, index, values) {
    //       //         return '$' + value;
    //       //     }
    //       //  }
          
    //       }
    //     ]
       
    //  },
        plugins: {
          legend: {
            display:true,
            title:{
              display: true
            }
          },
          title: {
            display: true,
            text: 'Chart.js Line Chart',
          },
        },
      };

      asinLineChartLabels = _asinStrDates.current;
      
      switch(_sortBy.current){
        case 'orderedProductSales':{
          
          const asinOrderedProducts = {
            labels : sliceArrayToTimePeriod(asinLineChartLabels),
            datasets: [
              {
                label: 'Ordered Product Sales',
                data: sliceArrayToTimePeriod(asinOrderedProductSales),
                borderColor: '3c3e3d',
                backgroundColor: '3c3e3d',
                lineTension: 0.4
              },
              {
                label: 'Ordered Product Sales Corporate',
                data: sliceArrayToTimePeriod(asinOrderedProductSalesB2B),
                borderColor: '#007798',
                backgroundColor: '#007798',
                lineTension: 0.4
              },
              {
                label: 'Ordered Product Sales Retail',
                data: sliceArrayToTimePeriod(asinOrderedProductSalesNotB2B),
                borderColor: '#427f3d',
                backgroundColor: '#427f3d',
                lineTension: 0.4
              }
            ],
          };
          setLineChartOptions(asinLineChartOptions);
          setIsLoading(false);
          setAsinOrderedProducts(asinOrderedProducts);
          let av1 =(findAverageCurrentData(sliceArrayToTimePeriod(asinOrderedProductSales)));
          let av2 =(findAverageCurrentData(sliceArrayToTimePeriod(asinOrderedProductSalesB2B)));
          let av3 =(findAverageCurrentData(sliceArrayToTimePeriod(asinOrderedProductSalesNotB2B)));

       
          setAverages(av1,av2,av3);
          break;
        }
        case 'unitsOrdered':{
          
          const asinOrderedProducts = {
            labels : sliceArrayToTimePeriod(asinLineChartLabels),
            datasets: [
              {
                label: 'Units Ordered',
                data: sliceArrayToTimePeriod(asinUnitsOrdered),
                borderColor: '3c3e3d',
                backgroundColor: '3c3e3d',
                lineTension: 0.4
              },
              {
                label: 'Units Ordered Corporate',
                data: sliceArrayToTimePeriod(asinUnitsOrderedB2B),
                borderColor: '#007798',
                backgroundColor: '#007798',
                lineTension: 0.4
              },
              {
                label: 'Units Ordered Retail',
                data: sliceArrayToTimePeriod(asinUnitsOrderedNotB2B),
                borderColor: '#427f3d',
                backgroundColor: '#427f3d',
                lineTension: 0.4
              }
            ],
          };
          setLineChartOptions(asinLineChartOptions);
          setIsLoading(false);
          setAsinOrderedProducts(asinOrderedProducts);
          
          
          let av1 =(findAverageCurrentData(sliceArrayToTimePeriod(asinUnitsOrdered)));
          let av2 =(findAverageCurrentData(sliceArrayToTimePeriod(asinUnitsOrderedB2B)));
          let av3 =(findAverageCurrentData(sliceArrayToTimePeriod(asinUnitsOrderedNotB2B)));
          setAverages(av1,av2,av3);
          break;
          
        }
        case 'sessionPercent':{
          break;
        }
        case 'pageViewsPercent':{
          break;
        }
        case 'totalOrderItems':{
          
          const asinOrderedProducts = {
            labels : sliceArrayToTimePeriod(asinLineChartLabels),
            datasets: [
              {
                label: 'Total Order Items',
                data: sliceArrayToTimePeriod(asinTotalOrderItems),
                borderColor: '3c3e3d',
                backgroundColor: '3c3e3d',
                lineTension: 0.4
              },
              {
                label: 'Total Order Items Corporate',
                data: sliceArrayToTimePeriod(asinTotalOrderItemsB2B),
                borderColor: '#007798',
                backgroundColor: '#007798',
                lineTension: 0.4
              },
              {
                label: 'Total Order Items Retail',
                data: sliceArrayToTimePeriod(asinTotalOrderItemsNotB2B),
                borderColor: '#427f3d',
                backgroundColor: '#427f3d',
                lineTension: 0.4
              }
            ],
          };
          setLineChartOptions(asinLineChartOptions);
          setIsLoading(false);
          setAsinOrderedProducts(asinOrderedProducts);
          // console.log(asinOrderedProducts);
          let av1 =(findAverageCurrentData(sliceArrayToTimePeriod(asinTotalOrderItems)));
          let av2 =(findAverageCurrentData(sliceArrayToTimePeriod(asinTotalOrderItemsB2B)));
          let av3 =(findAverageCurrentData(sliceArrayToTimePeriod(asinTotalOrderItemsNotB2B)));
          setAverages(av1,av2,av3);
          break;
        }
      }
    }

   
      
    return (
        
       
                    <Flex
                        gap="32px"
                        direction="column"
                        width="1194px"
                        height="842px"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        overflow="hidden"
                        position="relative"
                        padding="32px 32px 32px 32px"
                        
                        >
                          
                        <Flex
                            gap="8px"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            <Text
                            fontFamily="Inter"
                            fontSize="32px"
                            fontWeight="500"
                            color="rgba(13,26,38,1)"
                            lineHeight="40px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="Sales Report By ASIN "
                            
                            ></Text>
                             <SearchField
          label="Search"
          placeholder="Enter ASIN"
          onChange={onASINChange}
          onClear={onASINClear}
          onClick={onASINClick}
          onSubmit={onASINSubmit}
          size="large"
        />
                        </Flex>
                        <Flex
                            gap="32px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="center"
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                             
                            {averages1}
                            {averages2}
                            {averages3}
                                
                           
                        </Flex>
                        
                    
                        <Flex
                        gap="48px"
                        direction="column"
                        width="1130px"
                        height="535px"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                    
                        >
                            <Flex
                                gap="32px"
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="center"
                                alignItems="flex-start"
                                shrink="0"
                                alignSelf="stretch"
                                position="relative"
                                padding="0px 20px 0px 20px"
                                
                            >
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="300px"
                                        height="unset"
                                        label="Type"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        onChange={moveAsinView}
                                        defaultValue="asin"
                                    >
                                      <option value="date" >By Date</option>
                                      <option value="asin">By ASIN</option>
                                    </SelectField>
                                </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Filter By Date"
                                        
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        value={selectValue}

                                        onChange={handleDropdownChange}
                                        >
                                            <option value="time-reset">-</option>
                                            <option value="time-lastSevenDays">Last 7 Days</option>
                                            <option value="time-lastThirtyDays">Last 30 Days</option>
                                            <option value="time-thisWeek">This Week</option>
                                            <option value="time-thisMonth">This Month</option>
                                            <option value="time-lastOneYear">Last 1 Year</option>
                                            <option value="time-lastTwoYears">Last 2 Years</option>
                                        </SelectField>
                                </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Sort By"
                                        
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        onChange={handleDropdownChange}
                                        
                                    >
                                            <option value="sort-orderedProductSales">Ordered Product Sales</option>
                                            <option value="sort-totalOrderItems">Total Order Items</option>
                                            <option value="sort-unitsOrdered">Units Ordered</option>

                                    </SelectField>
                                </Flex>
                            </Flex>
                            
                             {isLoading ? <Flex/> : <Line options={asinLineChartOptions} data={asinOrderedProducts} width="400" height="100" />} 
                            
            
                  
                        </Flex> 
                    </Flex>          
                
        
    );
}

export default withAuthenticator(SalesReportAsin);