import React from 'react';

const ForecastChartLegend = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      fontFamily: 'Inter',
      
      fontSize: '18px',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '15px',
    },
    rowItems: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '20px',
    },
    dot: (color) => ({
      height: '10px',
      width: '10px',
      borderRadius: '50%',
      backgroundColor: color,
      marginRight: '5px',
    }),
    label: {
      marginRight: '10px',
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.row}>
        <span style={styles.label}>Historical Data:</span>
        <div style={styles.rowItems}>
          <div style={styles.dot('#003C4D')}></div>
          <span>Historical</span>
        </div>
        <div style={styles.rowItems}>
          <div style={styles.dot('#BFBF40')}></div>
          <span>Start of Forecast</span>
        </div>
      </div>
      <div style={styles.row}>
        <span style={styles.label}>Confidence Intervals:</span>
        <div style={styles.rowItems}>
          <div style={styles.dot('#66AEC2')}></div>
          <span>p10</span>
        </div>
        <div style={styles.rowItems}>
          <div style={styles.dot('#437F3D')}></div>
          <span>p50</span>
        </div>
        <div style={styles.rowItems}>
          <div style={styles.dot('#007899')}></div>
          <span>p90</span>
        </div>
      </div>
    </div>
  );
};

export default ForecastChartLegend;
