import { getUser, getStripeCustomer } from '../graphql/queries';
import { updateUser } from '../graphql/mutations.js';
import { API,Cache, Auth } from 'aws-amplify';

async function authChecker(setDialogModalMessage, setDialogModalOpen, navigate) {
    const refreshToken = Cache.getItem('user')?.refreshToken ?? false;

    let cognitoUser = await Auth.currentAuthenticatedUser();
      
    const { attributes } = cognitoUser;

    const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});

    if(result['data'].getUser !=null && result['data'].getUser.refreshToken !=null){
        console.log("Refresh Token isn't existing at all");
    }else{
        setDialogModalMessage("Aitium requires authorization on Amazon Seller Central. Redirecting to authorization page...");
        setDialogModalOpen(true);
        navigate("/authorize");
        return false;
    }

}

export const validateSubscription = async (setSubscriptionPlan, setSubscriptionLoading, setDialogModalMessage, setDialogModalOpen, navigate) => {
    const user = Cache.getItem('user');
    const userData = await API.graphql({ query: getUser , variables: { id: user.id }});
    
    if (!userData.stripeId) {
        try {
            const variables = { "email": user.id };
            const response = await API.graphql({
                query: getStripeCustomer,
                variables: variables
            });

            const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);
            if (stripeData["body"]["data"][0]) {
                const customerData = stripeData["body"]["data"][0];
                const stripeCustomerId = customerData["customer"];
                const productName = customerData["product_name"]?.["name"];

                if (productName) {
                    setSubscriptionPlan(productName);
                }

                if (stripeCustomerId) {
                
                    
                    const updateUserPayload = {
                        email: user.id,
                        username: user.id,
                        id: user.id,
                        stripeId: stripeCustomerId
                    };
                
                    await API.graphql({ query: updateUser, variables: { input: updateUserPayload }});

                    const updatedUser = { ...user, stripeId: stripeCustomerId };
                    Cache.setItem('user', updatedUser);

                    authChecker(setDialogModalMessage, setDialogModalOpen, navigate);
                } else {
                    navigate('/products');
                }
            }
        } catch (error) {
            console.error('Error fetching customer ID or subscription:', error);
        } finally {
            setSubscriptionLoading(false);
        }
    } else {
        
        authChecker(setDialogModalMessage, setDialogModalOpen, navigate);
        setSubscriptionLoading(false);
    }
};