/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";

export default function CustomPricingPlanList(props) {
  const { pricingRef, navigateToSignup, overrides, ...rest } = props;

  const plans = [
    {
      name: 'Free plan',
      price: '$0/mo',
      pricingDetails: 'Free forever, always',
      features: ['Sales and Traffic Reporting', 'Amazon Business Analytics', 'Business Buyers Conversion Report']
    },
    {
      name: 'Business plan',
      price: '$79/mo',
      pricingDetails: 'Billed monthly',
      features: ['Forecasting of Inventory (beta)', 'Corporate Analytics Reporting', '2 years (maximum) Analytics']
    },
    {
      name: 'Enterprise plan',
      price: '$109/mo',
      pricingDetails: 'Billed monthly',
      features: ['Forecasting of Amazon Revenue', 'Custom Model Selection', 'Add up to 23 Amazon Marketplaces']
    }
  ];


  const pricingStyle = {
    perFeature: {
      display: 'contents'
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontFamily: 'Arial, sans-serif',
      paddingLeft: '80px',
      paddingRight: '80px',
      paddingBottom: '50px',
      backgroundColor: '#F8F8F8',
      lineHeight: 'normal'
    },
    plansContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      gap: '20px',
      marginTop: '20px',
    
    },
    plan: {
      borderRadius: '8px',
      padding: '35px',
      width: '33.33%',
      textAlign: 'center',
      display: 'block',
      backgroundColor: 'white',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    price: {
      
      fontSize: '1.9em',
      fontWeight: 'bold',
      margin: '10px 0'
    },
    button: {
      marginTop: '20px',
      padding: '10px 20px',
      backgroundColor: '#00607a',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      width: '100%',
      cursor: 'pointer'
    },
    featuresList: {
      textAlign: 'left',
      paddingLeft: '0',
      listStyleType: 'none',
      marginTop: '30px',
      display: 'block'
    },
    featureItem: {
      display: 'flex',
      marginBottom: '15px',
      alignItems: 'center'
    },
    pricingText: {
      fontSize: '1.2em',
      marginTop:'30px',
      fontWeight: '700',
      color: '#00607A'
    },

    choosePlanText: {
      marginTop: '10px',
      fontWeight: 'bold',
      fontSize: '1.9em',
    },
    choosePlanDescription: {
      marginTop: '10px',
      fontSize: '1.0em',
      color: '#5C6670',
      marginBottom: '15px',
    },
    planName: {
      color: '#00607A',
      fontWeight: 'bold',
      fontSize: '1.0em',
    },
    pricingDetails: {
      color: '#5C6670',
    },
  };

  
  const logoStyle = {
    height: '40px'
};

  return (
    <Flex
      style={pricingStyle.container}
      ref={pricingRef}
      {...getOverrideProps(overrides, "PricingSection")}
      {...rest}
    >
      <Text
        style={pricingStyle.pricingText}
        children="Pricing"
        {...getOverrideProps(overrides, "PricingText")}
      />
      <Text
        style={pricingStyle.choosePlanText}
        children="Choose your plan"
        {...getOverrideProps(overrides, "ChoosePlanText")}
      />
      <Text
        fontFamily="Inter"
        style={pricingStyle.choosePlanDescription}
        children=""
        {...getOverrideProps(overrides, "ChoosePlanDescription")}
      />
      <Flex
        style={pricingStyle.plansContainer}
      >
        {plans.map((plan, index) => (
          <Flex
            key={index}
           
            style={pricingStyle.plan}
            {...getOverrideProps(overrides, `Plan-${index}`)}
          >
            <Text
              as="span"
              fontFamily="Inter"
              style={pricingStyle.planName}
              children={plan.name}
              {...getOverrideProps(overrides, `PlanName-${index}`)}
            />
            <Text
              fontFamily="Inter"
              style={pricingStyle.price}
              children={plan.price}
              {...getOverrideProps(overrides, `Price-${index}`)}
            />
            <Text
              fontFamily="Inter"
              style={pricingStyle.pricingDetails}
              children={plan.pricingDetails}
              {...getOverrideProps(overrides, `PricingDetails-${index}`)}
            />
            <Flex
                as="ul"
              style={pricingStyle.featuresList}
            >
              {plan.features.map((feature, i) => (
                <Flex
                  key={i}
                  as="li"
                  style={pricingStyle.featureItem}
                  {...getOverrideProps(overrides, `FeatureItem-${index}-${i}`)}
                >
                    <Text
                    fontFamily="Inter"
                    key={i}
                    as="span"
                    style={pricingStyle.perFeature}
                  >

                        <Image
                            src="feature-check-icon.png"
                            alt=""
                            height="40px"
                             style={logoStyle} 
                            {...getOverrideProps(overrides, `FeatureIcon-${index}-${i}`)}
                        />
                        <Text
                            fontFamily="Inter"
                            as="span"
                            style={{ paddingLeft: '20px' }}
                            children={feature}
                            {...getOverrideProps(overrides, `FeatureText-${index}-${i}`)}
                        />


                  </Text>
                 
                </Flex>
              ))}
            </Flex>
            <Button
              style={pricingStyle.button}
              onClick={navigateToSignup}
              children="Get started"
              {...getOverrideProps(overrides, `GetStartedButton-${index}`)}
            />
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
