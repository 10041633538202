import React from 'react';
import { Flex, View, Button, Divider } from '@aws-amplify/ui-react';

const DialogModal = ({ open, message, onClose }) => {
  if (!open) return null;

  return (
    <View
      position="fixed"
      top="0"
      left="0"
      width="100vw"
      height="100vh"
      backgroundColor="rgba(0,0,0,0.5)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex="1000"
    >
      <Flex
        direction="column"
        backgroundColor="white"
        padding="20px"
        borderRadius="8px"
        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
        minWidth="300px"
      >
        <View padding="10px">
          <h3>Notice</h3>
          <Divider />
        </View>

        <View padding="10px">
          <p>{message}</p>
        </View>

        <Divider />

        <Flex justifyContent="flex-end" padding="10px">
          <Button onClick={onClose} variation="primary">
            Ok
          </Button>
        </Flex>
      </Flex>
    </View>
  );
};

export default DialogModal;