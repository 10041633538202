/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex } from "@aws-amplify/ui-react";

export default function LandingPageFeatureList(props) {
  const { overrides, productsRef, ...rest } = props;

  
  const featureDescriptionData = [
    { title: 'B2B Analytics', description: 'Unlock the full potential of your business with the power of B2B analytics! By harnessing comprehensive data insights, you can transform decision-making, supercharge customer relationships, and streamline your operations. Imagine more effective marketing campaigns, optimized supply chains, and a proactive approach to risk management. Stay ahead of the competition with a deep understanding of market trends on Amazon marketplace.' },
    { title: 'Sales and Traffic Reporting', description: "Harness the power of sales and traffic reporting to elevate your business to new heights. From informed decision-making and optimized marketing strategies to enhanced customer insights and increased sales performance, these reports are your roadmap to success. Stay ahead of the competition, improve your Amazon store's performance, and plan for a prosperous future with confidence. Let sales and traffic reporting be the driving force behind your business’s growth and success." },
    { title: 'Forecasting', description: 'Transform your business strategy with the magic of FP&A (Financial Planning & Analysis)! Imagine having a crystal ball that guides your financial decisions, empowering you to navigate the future with confidence and precision. Here’s why Amazon Forecasting is the ultimate game-changer for your business.' },
    { title: 'AI tools', description: 'Integrating AI tools into your business is not just about keeping up with technology; it’s about leading the way. From enhancing decision-making and boosting productivity to delivering personalized customer experiences and reducing costs, AI tools offer unparalleled benefits. Embrace AI to transform your operations, drive innovation, and secure a competitive advantage in today’s dynamic market. Let AI be the catalyst for your business’s growth and success.' },
  ];

  const logoStyle = {
    height: '40px'
};

  
  const Card = ({ title, description }) => {
    const cardStyle = {
        display: 'block',
        alignItems: 'center',
        padding: '40px',
        margin: '10px',
        borderRadius: '5px',
        backgroundColor: 'white',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        width: '35%'
    };

    const iconStyle = {
        height: '40px',
        width: '40px',
        backgroundColor: '#E0F7FA',
        borderRadius: '50%',
        display: 'block',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '20px',
        marginBottom:'15px'
    };

    const checkmarkStyle = {
        height: '20px',
        width: '20px',
        backgroundColor: '#007B8F',
        borderRadius: '50%'
    };

    const contentStyle = {
        textAlign: 'left'
    };

    const titleStyle = {
        fontSize: '1.4em',
        fontWeight: 'bold',
        marginBottom: '10px'
    };

    const descriptionStyle = {
        fontSize: '1em',
        color: '#555'
    };

    return (
        <div style={cardStyle}>
            <div style={iconStyle}>
              <img src="feature-check-icon.png" alt="" style={logoStyle} />
            </div>
            <div style={contentStyle}>
                <div style={titleStyle}>{title}</div>
                <div style={descriptionStyle}>{description}</div>
            </div>
        </div>
    );
};


const featuresListContainer = {
    display: 'flex',
    flexWrap: 'wrap',
    // justifyContent: 'space-around',
    justifyContent: 'center',
    paddingTop: '50px',
    paddingBottom:'50px',
    backgroundColor: '#F8F8F8'
};

  return (
    <Flex
 
      style={featuresListContainer}
      ref={productsRef}
      {...getOverrideProps(overrides, "FeaturesList")}
      {...rest}
    >
      {featureDescriptionData.map((card, index) => (
        <Card 
          key={index} 
          title={card.title} 
          description={card.description} 
          {...getOverrideProps(overrides, `Card-${index}`)} 
        />
      ))}
    </Flex>
  );
}