/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text } from "@aws-amplify/ui-react";
export default function ForecastsDataRowPagination(props) {
  const { overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      width="1130px"
      height="unset"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      padding="16px 16px 16px 16px"
      backgroundColor="rgba(255,255,255,1)"
      {...getOverrideProps(overrides, "ForecastsDataRowPagination")}
      {...rest}
    >
      <Text
        fontFamily="Inter"
        fontSize="14px"
        fontWeight="500"
        color="rgba(52,64,84,1)"
        lineHeight="20px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="1-8 of 36"
        {...getOverrideProps(overrides, "Details")}
      ></Text>
      <Flex
        gap="10px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="flex-end"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Buttons")}
      >
        <Button
          width="unset"
          height="unset"
          shrink="0"
          backgroundColor="rgba(255,255,255,1)"
          size="small"
          isDisabled={false}
          variation="default"
          children="Previous"
          {...getOverrideProps(overrides, "Button38769536")}
        ></Button>
        <Button
          width="unset"
          height="unset"
          shrink="0"
          backgroundColor="rgba(255,255,255,1)"
          size="small"
          isDisabled={false}
          variation="default"
          children="Next"
          {...getOverrideProps(overrides, "Button38769540")}
        ></Button>
      </Flex>
    </Flex>
  );
}
