import * as React from "react";
import * as ReactDOM from "react-dom/client";
import './index.css';

import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import {
  BrowserRouter
} from "react-router-dom";
import App from "./App.tsx";

Amplify.configure(awsconfig);


ReactDOM.createRoot(document.getElementById("root")!).render(
  
    <BrowserRouter>
      <App />
    </BrowserRouter>
  
    

);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
