/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text } from "@aws-amplify/ui-react";

export default function ContactUsHeader(props) {
  const { contactRef, overrides, ...rest } = props;

  const contactSection = {
    titleSection: {
      backgroundColor: "#F8F8F8",
      paddingLeft:'25px',
      paddingRight: '25px',
      paddingBottom: '40px'
    }
  };

  return (
    <Flex
      ref={contactRef}
      style={contactSection.titleSection}
      {...getOverrideProps(overrides, "ContactTitleSection")}
      {...rest}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        paddingLeft="50px"
        paddingRight="50px"
        {...getOverrideProps(overrides, "ContactHeader")}
      >
        <Flex direction="column" display="block">
          <Text
            color="#304050"
            fontSize="2.5em"
            paddingTop="20px"
            {...getOverrideProps(overrides, "ContactTitle")}
          >
            Contact us
          </Text>
          <Text
            paddingTop="5px"
            fontSize="1.4em"
            color="#5C6670"
            {...getOverrideProps(overrides, "ContactSubtitle")}
          >
            We love to hear from our customers.
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
