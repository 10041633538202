import React from 'react'
import { useState,useEffect} from 'react';
import { Amplify} from 'aws-amplify';
import {Flex} from '@aws-amplify/ui-react';

import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import SalesReport from './sales-report.tsx'
import SalesReportAsin from './sales-report-asin.tsx'
import GeneralFooter from '../ui-components/GeneralFooter';

Amplify.configure(awsconfig);

function SalesReportContainer() {
    
    const [dateOrAsin, setDateOrAsin] = useState(<Flex></Flex>);
    
    useEffect(() => {
      setDateOrAsin(<SalesReport changeView={changeView}/> );
    },[]);// <-- Have to pass in [] here or is called on every render
    
    function changeView(view){
      if(view == 'asin'){
        setDateOrAsin(<SalesReportAsin changeView={changeView}/> );
      }
      else {
        setDateOrAsin(<SalesReport changeView={changeView}/> );
      }
    }

    return (
      <div  style={{ width: "100%" }}>
        <Flex
                paddingTop={'20px'}
                paddingLeft={'30px'}
                paddingRight={'30px'}
                overflow={'auto'}>
        {dateOrAsin}
        </Flex>
        <GeneralFooter />
      </div>  
    );
}

export default withAuthenticator(SalesReportContainer);