/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Text, Image, Button, Divider } from "@aws-amplify/ui-react";

const GeneralFooter = (props) => {
    const {  overrides, ...rest } = props;


  const newsletter = {
    container: {
      backgroundColor: "#F9F9F9",
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px',
      marginTop: '0px',
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      color: '#333',
    },
    form: {
      display: 'block',
      alignItems: 'center',
      paddingTop: "40px"
    },
    input: {
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      marginRight: '10px',
      fontSize: '1.1em',
      width: "300px",
      outline: 'none',
    },
    button: {
      padding: '10px 20px',
      border: '1px solid rgb(204, 204, 204)',
      backgroundColor: '#FFF',
      color: '#000',
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '1.1em',
      fontWeight: 'bold'
    },
    footer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginTop: '30px',
      paddingBottom: '10px',
      paddingLeft:'30px',
      paddingRight: '30px'
    },
    logo: {
    },
    logoImage: {
      height: '40px',
      marginRight: '5px',
    },
    copyright: {
      fontSize: '1.1em',
      color: '#777',
    }
  };
  return (
    <Flex
      style={newsletter.container}
      {...getOverrideProps(overrides, "NewsletterContainer")}
    >
 
      <Flex
        style={newsletter.footer}
        {...getOverrideProps(overrides, "NewsletterFooter")}
      >
       
        <Text
            style={newsletter.copyright}
          {...getOverrideProps(overrides, "NewsletterCopyright")}
        >
          © 2020-2024 Aitium®. All rights reserved.
        </Text>

        <Text
            style={newsletter.copyright}
          {...getOverrideProps(overrides, "NewsletterCopyright")}
        >
          Contact us by phone +1 571 200 5466
        </Text>
      </Flex>
    </Flex>
  );
};

export default GeneralFooter;