import React from 'react';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  textHeader: {
    fontWeight: "bold",
    fontSize: "1.2em"
  },
  content: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    border: '1px solid #ccc',
    backgroundColor: '#fff',
    borderRadius: '4px',
    maxWidth: '500px',
    width: '90%',
    inset: 'auto'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '20px'
  },
  button: {
    padding: '10px 20px',
    border: '2px solid #AEB3B7',
    borderRadius: '4px',
    backgroundColor: "white",
    cursor: 'pointer',
    marginLeft: '10px',
    fontWeight: 600
  },
  primaryButton: {
    backgroundColor: '#005f73',
    color: '#fff',
    border: '1px solid #005f73',
    fontWeight: 600
  }
};

const DeleteModal = ({ isOpen, onRequestClose, onDelete }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
      contentLabel="Delete Confirmation"
    >
      <div>
        <p style={customStyles.textHeader}>Are you sure you wish to delete this forecast? This cannot be undone.</p>
      </div>
      <div style={customStyles.buttonContainer}>
        <button style={customStyles.button} onClick={onRequestClose}>Go back</button>
        <button 
          style={{ ...customStyles.button, ...customStyles.primaryButton }} 
          onClick={onDelete}
        >
          Yes
        </button>
      </div>
    </Modal>
  );
};

export default DeleteModal;
