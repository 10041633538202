
import '@aws-amplify/ui-react/styles.css';
import  { useNavigate } from 'react-router'
import { Auth, Cache, API } from 'aws-amplify';
import { useState,useEffect,useRef } from 'react';
import React from "react";
import {Flex, View, Divider } from '@aws-amplify/ui-react';
import {Outlet,  NavLink, Link, useLocation} from 'react-router-dom';
import { getUser,getIngestJob, getForecastJob, forecastsByDate,getStripeCustomer } from '../graphql/queries';
import DialogModal from './dialog-modal.tsx'; 

import Selectedbutton from '../ui-components/SelectedButton';
import  NavBar  from '../components/navbar.tsx';
import Partners from '../ui-components/Partners';


export default function Sidebar(){
  const navigate = useNavigate();
  const location = useLocation();
  const stripe= useRef(new Array());
  const [subscriptionPlan,setSubscriptionPlan]= useState('Free Tier');
  const [isSubscribedToEnterprisePlan,setIsSubscribedToEnterprisePlan ] = useState(false);
  const [isSubscribedToFreePlan,setIsSubscribedToFreePlan ] = useState(true);

  const [dialogModalOpen, setDialogModalOpen] = useState(false);
  const [dialogModalMessage, setDialogModalMessage] = useState('');

  useEffect(() => {
    fetchJobs();
  }, [location]);

  const handleCloseModal = () => {
    setDialogModalOpen(false); 
  };
  
  async function fetchJobs() {
   
    try {
      let user = await Auth.currentAuthenticatedUser()
    
        console.log(user.attributes.email)
        const result = await API.graphql({ query: getUser , variables:{id:user.attributes.email}});
      
        if(result['data'] && result['data'].getUser){
            
            var response;
            
            response = await API.graphql({
                query: getStripeCustomer
                ,variables : {"email": user.attributes.email, "stripeCustomerId": user.attributes.stripeId}
            })

            const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);

            var subscriber = null;
            //find the exact plan they are on currently
            if(stripeData['body'] && stripeData['body']['data']){
            for(var i = 0; i < stripeData['body']['data'].length;i++){
                let currentCustomer = stripeData['body']['data'][i];
                if(currentCustomer['customer'] == result['data'].getUser["stripeId"]){
                //found the current plan 
                // currentPlan = stripeData['body']['data'][i]['plan'];
                subscriber =  stripeData["body"]["data"][i];
                
                var current = new Date(); 
                var fiveMinutes = new Date(current.getTime() + 300000);
                console.log("five mins ",fiveMinutes)
                stripe.current = subscriber
                console.log("stripe",stripe)
                
                }
          }
            }
            // if(!subscriber){
            //   navigate("/accounts");
            // }
            // if(stripeData['body']['data'] != null && stripeData['body']['data'][0] !=null){
            //   console.log('stripeData',stripeData);
            //   let plan = stripeData['body']['data'][0]['plan']['product']
            //   result['data'].getUser['plan'] = plan;
            
            
            // }

            if(stripeData["body"]["data"][0] && stripeData["body"]["data"][0]["product_name"]["name"]){
              setSubscriptionPlan(stripeData["body"]["data"][0]["product_name"]["name"]);
            }

            setIsSubscribedToEnterprisePlan (
              stripe.current &&
              stripe.current.plan &&
              (stripe.current.plan.product === "prod_PESLbIINOuZkuk") &&
              (stripe.current.status === "active" || stripe.current.status === "trialing")
            ) 

            setIsSubscribedToFreePlan (
              stripe.current &&
              stripe.current.plan &&
              (stripe.current.plan.product === "prod_PES93aNw9NuQWh" ) &&
              (stripe.current.status === "active" || stripe.current.status === "trialing")
            ) 
        }

    } catch (err) {
      console.log('error fetching forecasts',err);
    }
  }

  async function handleCorporateReportClick() {


    let cognitoUser = await Auth.currentAuthenticatedUser();
      
    const { attributes } = cognitoUser;

    const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});
    if(isSubscribedToFreePlan){
      setDialogModalMessage("Corporate Analytics Report requires an upgrade from Free Tier. Please upgrade your plan.");
      setDialogModalOpen(true);
      return false;
    }
    else if(result['data'].getUser !=null && result['data'].getUser.refreshToken !=null){
      return true;
    }else{
      setDialogModalMessage("Aitium requires authorization on Amazon Seller Central. Redirecting to authorization page...");
      setDialogModalOpen(true);
      navigate('/authorize');
      return false;
    }

}

  async function checkAuthorization() {
    let cognitoUser = await Auth.currentAuthenticatedUser();
      
    const { attributes } = cognitoUser;

    const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});

    if(result['data'].getUser !=null && result['data'].getUser.refreshToken !=null){
      return true;
    }else{
      setDialogModalMessage("Aitium requires authorization on Amazon Seller Central. Redirecting to authorization page...");
      setDialogModalOpen(true);
      navigate('/authorize');
      return false;
    }
  }

  async function handleSignOut() {
    try {
      Cache.setItem('user',null);
      Cache.setItem('marketCode',null);
      Cache.setItem('marketCountryCode',null);
      console.log('siging out');
      await Auth.signOut();
      navigate("/")
    } catch (error) {
      console.log('error signing out: ', error);
      navigate("/")
    }
  }

  let forecastsLink = <NavLink style={({ isActive }) => ({
    textDecoration: isActive ? 'underline' : 'none'
  })} to='/forecasts'><Selectedbutton  state='Unselected'  overrides={{MyIcon:{type:"Forecasts"},"label":{children:"Forecasts"},"icon":{backgroundColor:'white'}}}/></NavLink>

  let salesLink = (
    <NavLink
      to='/sales-report'
      onClick={async (e) => {
        e.preventDefault(); 
        const proceed = await checkAuthorization(); 
        if (proceed) {
          navigate('/sales-report'); 
        }
      }}
      style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })}
    >
      <Selectedbutton state='Unselected' overrides={{ MyIcon: { type: 'SalesReport' }, label: { children: 'Sales Report' }, icon: { backgroundColor: 'white' } }} />
    </NavLink>
  );

  let trafficLink = (
    <NavLink
      to='/traffic-report'
      onClick={async (e) => {
        e.preventDefault(); 
        const proceed = await checkAuthorization(); 
        if (proceed) {
          navigate('/traffic-report'); 
        }
      }}
      style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })}
    >
      <Selectedbutton state='Unselected' overrides={{ MyIcon: { type: 'TrafficReport' }, label: { children: 'Traffic Report' }, icon: { backgroundColor: 'white' } }} />
    </NavLink>
  );

  let conversionLink = (
    <NavLink
      to='/conversion-report'
      onClick={async (e) => {
        e.preventDefault(); 
        const proceed = await checkAuthorization(); 
        if (proceed) {
          navigate('/conversion-report'); 
        }
      }}
      style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })}
    >
      <Selectedbutton state='Unselected' overrides={{ MyIcon: { type: 'ConversionReport' }, label: { children: 'Conversion Report' }, icon: { backgroundColor: 'white' } }} />
    </NavLink>
  );

  let corporateAnalyticsLink =  null; 
  
  let inventoryLink = (
    <NavLink
      to='/inventory'
      onClick={async (e) => {
        e.preventDefault(); 
        const proceed = await checkAuthorization(); 
        if (proceed) {
          navigate('/inventory'); 
        }
      }}
      style={({ isActive }) => ({ textDecoration: isActive ? 'underline' : 'none' })}
    >
      <Selectedbutton state='Unselected' overrides={{ MyIcon: { type: 'Inventory' }, label: { children: 'Inventory' }, icon: { backgroundColor: 'white' } }} />
    </NavLink>
  );
  

  let divider =  <Divider/>
    
    // if(Cache.getItem('user') && Cache.getItem('user')["stripeId"] == null || Cache.getItem('user') && Cache.getItem('user')["refreshToken"] == null){ 
    //   salesLink = <Flex></Flex>
    //   forecastsLink = <Flex></Flex>
    //   trafficLink = <Flex></Flex>
    //   conversionLink = <Flex></Flex>
    //   divider = <Flex></Flex>
    // }

  if(!isSubscribedToFreePlan){
    corporateAnalyticsLink = <NavLink 
    onClick={async (e) => {
      e.preventDefault(); 
      const proceed = await handleCorporateReportClick(); 
      if (proceed) {
        navigate('/corporate-analytics-report'); 
      }
    }}
    style={({ isActive }) => ({
      textDecoration: isActive ? 'underline' : 'none'
    })}  to='/corporate-analytics-report'><Selectedbutton  state='Unselected' overrides={{MyIcon:{type:"CorporateAnalyticsReport"},"label":{children:"Corporate Analytics Report"},"icon":{backgroundColor:'white'}}}/></NavLink>
    
  }

    
    return (
      <div>
        <NavBar width="100vw"/>
          
          <Flex style={{marginTop: '40px', paddingLeft: "25px", paddingRight: "25px"}}>
            <View>
              {salesLink}
              {forecastsLink}
              {trafficLink}
              {conversionLink}
              {corporateAnalyticsLink}
              {inventoryLink}
              <div style={{marginTop: "100px"}}></div>
                      <Flex 
                padding="20px 20px 20px 15px">
                   {divider}
                    </Flex>
                    <Flex>
                        <Partners overrides={{
                            "amazon-software_partners-badge-768x768":{src:"softwarepartner.png"},
                            "qualified software449513778":{src:"qualified.png"},
                            "qualified software449513781":{src:"partner.png"},
                            "Software Partner": {
                                children: (
                                  <a
                                    href="https://gaiiff.com/aitium-is-verified-by-aws"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none", color: "rgba(0,120,153,1)" }}
                                  >
                                    Software Partner
                                  </a>
                                ),
                              },
                              "Qualified Software": {
                                children: (
                                  <a
                                    href="https://gaiiff.com/aitium-is-verified-by-aws"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none", color: "rgba(0,120,153,1)" }}
                                  >
                                    Qualified Software
                                  </a>
                                ),
                              },
                              "Partner Services": {
                                children: (
                                  <a
                                    href="https://gaiiff.com/aitium-is-verified-by-aws"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ textDecoration: "none", color: "rgba(0,120,153,1)" }}
                                  >
                                    Partner Services
                                  </a>
                                ),
                              }
                        }}/>
                    </Flex>
                    <Flex 
                padding="20px 20px 20px 15px">
                    <Divider/>
                    </Flex>
                     <a
                      href="https://billing.stripe.com/p/login/fZeg2s3Mhac36IMdQQ"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'none'
                      }}
                    >
                      <Selectedbutton
                        state="Unselected"
                        overrides={{
                          MyIcon: { type: "Account" },
                          label: { children: "Account" },
                          icon: { backgroundColor: 'white' }
                        }}
                      />
                    </a>
                  {/* <NavLink style={({ isActive }) => ({
    textDecoration: isActive ? 'underline' : 'none'
  })}  to='/account'><Selectedbutton state='Unselected' overrides={{MyIcon:{type:"Account"},"label":{children:"Account"},"icon":{backgroundColor:'white'}}}/></NavLink> */ }
                      <NavLink style={({ isActive }) => ({
    textDecoration: isActive ? 'underline' : 'none'
  })}  to='/authorize'><Selectedbutton state='Unselected' overrides={{MyIcon:{type:"AuthorizeRegion"},"label":{children:"Authorize Region"},"icon":{backgroundColor:'white'}}}/></NavLink>
                      <NavLink onClick={handleSignOut} style={({ isActive }) => ({
    textDecoration: 'none'
  })} to="#" ><Selectedbutton state='Unselected' overrides={{MyIcon:{type:"LogOut"},"label":{children:"Logout"},"icon":{backgroundColor:'white'}}}/></NavLink>
                        
                   
                
            </View>
            <Flex style={{backgroundColor: "#FAFAFA", marginLeft:"50px", width:"100%"}}>
              <Outlet/>
            </Flex>
          </Flex>
          <DialogModal open={dialogModalOpen} message={dialogModalMessage} onClose={handleCloseModal} />
      </div>
    );
  }
  function refreshUser(){
    console.log(Auth.currentAuthenticatedUser().then((res) => console.log('res', res)))
  }