import React from 'react'
import  { useNavigate } from 'react-router'
import { useState,useEffect } from 'react';
import { Amplify, Auth, API,Cache } from 'aws-amplify';
import {Flex, View, Divider,Link,Alert} from '@aws-amplify/ui-react';
import  NavBar  from '../components/navbar.tsx';
import studioTheme from '../ui-components/studioTheme';
import SideBar from '../ui-components/SideBar';
import AccountAccountInformation from '../ui-components/AccountAccountInformation'; 
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from './../aws-exports';
import { getUser,getStripeCustomer } from '../graphql/queries';
import { createImportSpecifier } from 'typescript';
import Selectedbutton from '../ui-components/SelectedButton';
import Partners from '../ui-components/Partners';
import GeneralFooter from '../ui-components/GeneralFooter.jsx';
const { DateTime } = require("luxon");


Amplify.configure(awsconfig);

function Account() {
    const [appUser, setAppUser] = useState({name:"", startDate:"", endDate:"",plan:"",countryCodes:"",authRegions:""});
    const [name, setName] = useState(""); 
    const [alert, setAlert] = useState();
    const navigate = useNavigate();
    useEffect(() => {
        let user = fetchUser();
    },[]);// <-- Have to pass in [] here or is called on every render
    
    function viewSubscription(){
        navigate('/products');
    }

    function viewRegion(){
        navigate('/authorize');
    }

    async function fetchUser() {
    
        const user = Cache.getItem('user');

        if(user && ('firstName' in user && 'lastName' in user)){
            setName(user['firstName']+' '+user['lastName']);
        }

        let refreshToken = {}
        // if(dbUser.data.getUser.refreshToken){
        //     refreshToken = JSON.parse(dbUser.data.getUser.refreshToken);
        // }
        // let sellingPartnerIds = {}
        // if(dbUser.data.getUser.sellingPartnerIds){
        //     sellingPartnerIds = JSON.parse(dbUser.data.getUser.sellingPartnerIds);
        // }
        // console.log(refreshToken);

        // const stripeId = user.stripeId;
        try{
            var response;
            if(user){
                response = await API.graphql({
                    query: getStripeCustomer
                    ,variables : {"email": user.id, "stripeCustomerId": user.stripeId}
                })
    
                const stripeData = JSON.parse(response["data"]["getStripeCustomer"]);
                console.log(stripeData);
                const currentPeriodStart = stripeData["body"]["data"][0]["current_period_start"];
                const currentPeriodStartDate = DateTime.fromSeconds(currentPeriodStart).toLocaleString(DateTime.DATE_MED);
    
                const currentPeriodEnd = stripeData["body"]["data"][0]["current_period_end"];
                const currentPeriodEndDate = DateTime.fromSeconds(currentPeriodEnd).toLocaleString(DateTime.DATE_MED);
                
                var subscriber = stripeData["body"]["data"][0]["plan"]["active"];
                if(!subscriber){
                    setAlert(<Alert variation="error">You will need to <a href="/products">update your subscription</a> to continue using aitium</Alert>)
           
                }
                var trial_period =  (Date.now() >= stripeData["body"]["data"][0]["trial_start"]) && 
                                    (Date.now() <= stripeData["body"]["data"][0]["trial_end"]);
                let resultMarketplace;
                let mp = user.Marketplace;
                let auth = {}
                auth["NA"] = "AMER";
                auth["EU"] = "EMEA";
                auth["FE"] = "APAC";
    
                let countryCode = "";
                let authRegion = "Please click edit to choose a region to authorize";
                let plan = "Please click edit to choose a subscription plan"
                if(mp){
                    resultMarketplace = JSON.parse(mp);
                    
                    let keys = Object.keys(resultMarketplace);
                    if(keys.length > 0){
                        authRegion = ""
                    }
                    for(let i in keys){
                        authRegion = authRegion + auth[keys[i]]+" ";
                    }  
                    for(let i in resultMarketplace){
                        for(let j in resultMarketplace[i]){
                            countryCode = countryCode + resultMarketplace[i][j].countryCode+" ";
                        }
                    }  
                }
    
                if(stripeData["body"]["data"][0] && stripeData["body"]["data"][0]["product_name"]["name"]){
                    plan = stripeData["body"]["data"][0]["product_name"]["name"];
                }
                
                
                setAppUser({
                    name:user['firstName']+' '+user['lastName'],
                    countryCodes:countryCode,
                    authRegions: authRegion,
                    startDate:currentPeriodStartDate.toLocaleString(DateTime.DATE_SHORT),
                    endDate:currentPeriodEndDate.toLocaleString(DateTime.DATE_SHORT) ,
                    plan: plan 
                })
            }
        }catch(exception){

        }
       
      }

    const accountOverrides = {
        "Hello [aitium] team": {
            children: "Hello "+name,
        },
        "AccountAccountInformation": {
            width: "100%"
        },
        "10/17/2023":{
            children:appUser?.startDate
        },
        "11/17/2023":{
            children:appUser?.endDate
        },
        Free:{
            children:appUser?.plan
        },
        "AccountDataRow ":{
            overrides:{
                US:{
                    children:appUser?.countryCodes  
                }   
            }
        },
        Americas:{
            children:appUser?.authRegions
        },
        Eurasia:{
            children:""
        },
        Button393311356:{
            onClick: () => viewSubscription()
            
        },
        // "AccountDataRow ":{

        // },
        Button39508663:{
            onClick: () => viewRegion()
        },
    }
    const sidebarOverrides = {
            property1:"Account"
    }
    return (
        <div className="Account" style={{width: "100%"}}>
            {alert}
            <Flex overflow={'auto'}>
               
                <Flex position={'relative'} overflow={'hidden'} grow={1} style={{width:"100%"}}>
                
                    <AccountAccountInformation  overrides={accountOverrides}></AccountAccountInformation>
                </Flex>
                
            </Flex>
            <GeneralFooter/>
        </div>
    );
}

export default withAuthenticator(Account);



