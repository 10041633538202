import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Amplify, Auth, API, Cache } from 'aws-amplify';
import { Flex, Text, View, TextField, CheckboxField, Button, Image } from '@aws-amplify/ui-react';
import Modal from 'react-modal';
import awsconfig from './../aws-exports';
import { getUser } from '../graphql/queries';
import { createUser } from '../graphql/mutations';

Amplify.configure(awsconfig);

const SignupPage = ({ setUser }) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);
  const [confirmationCodeError, setConfirmationCodeError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    Modal.setAppElement('body'); // For accessibility, set the app element
    checkAccount();
  }, []);

  const checkAccount = async () => {
    try {
      let cognitoUser = await Auth.currentAuthenticatedUser();
      const { attributes } = cognitoUser;
      const result = await API.graphql({ query: getUser, variables: { id: attributes.email } });
      if (result.data.getUser) {
        const name = result.data.getUser.lastName;
        Cache.setItem('user', result.data.getUser);
        if (name && name.length > 1) {
          navigate('/products');
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();

    if (password !== rePassword) {
      setError('Passwords do not match.');
      setIsLoading(false);
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const signupResponse = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstname,
          family_name: lastname,
        },
      });

      setShowConfirmationModal(true);
    } catch (err) {
      setError(`Failed to sign up. ${err.message}`);
      console.error('Error signing up:', err);
      setIsLoading(false);
    }
  };

  const handleConfirmation = async () => {
    
    
    if (!confirmationCode) {
      setConfirmationCodeError('Please enter the confirmation code.');
      return;
    }
    setConfirmationCodeError('');
    setIsConfirming(true);
      

    try {
      await Auth.confirmSignUp(email, confirmationCode);

      const cognitoUser = await Auth.signIn(email, password);
      const { attributes } = cognitoUser;
      const credentials = await Auth.currentUserCredentials();

      const createUserPayload = {
        email: attributes.email,
        username: attributes.email,
        identityID: credentials.identityId,
        firstName: firstname,
        lastName: lastname,
        hasRfToken: false,
        id: attributes.email,
      };

      try {
        const existingUser = await API.graphql({ query: getUser, variables: { id: attributes.email } });
        
        if (existingUser["data"]["getUser"]) {
          console.log('User already exists');
        } else {
          await API.graphql({ query: createUser, variables: { input: createUserPayload } });
        }
        
        navigate('/products');
      } catch (err) {
      } finally {
        setIsConfirming(false);
        setShowConfirmationModal(false);
      }
    } catch (err) {
      setError(`Failed to confirm sign up. ${err.message}`);
      console.error('Error confirming sign up:', err);
    } finally {
      setIsConfirming(false);
      setShowConfirmationModal(false);
    }
  };

  const formStyles = {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    width: '100%',
    alignItems: 'center',
  };

  const linksStyle = {
    cursor: 'pointer',
    color: '#00607A',
    fontWeight: 'bold',
    textDecoration: 'none',
  };

  const redirectToLogin = () => {
    navigate('/signin');
  };

  const maskEmail = (email) => {
    if (!email) return '';
  
    const [localPart, domain] = email.split('@');
    if (!domain) return email; // If domain is missing, return the original email
  
    const maskedLocalPart = localPart.slice(0, 1) + '***' + localPart.slice(-1);
    const domainParts = domain.split('.');
    if (domainParts.length < 2) return `${maskedLocalPart}@${domain}`; // If domain part is incomplete, return as is
  
    const maskedDomain = domainParts[0].slice(0, 1) + '***' + domainParts[0].slice(-1);
    return `${maskedLocalPart}@${maskedDomain}.${domainParts[1]}`;
  };

  const maskedEmail = email ? maskEmail(email) : '';

  return (
    <Flex direction={{ base: 'column', medium: 'row' }} width="100vw" height="100vh">
      <Flex
        direction="column"
        width={{ base: '100%', medium: '45%' }}
        justifyContent="center"
        alignItems="center"
        padding={{ base: '20px', medium: '40px' }}
        style={{ backgroundColor: 'white' }}
      >
        <Flex direction="column" width="100%" height="70%" justifyContent="center" alignItems="center">
          <Image src="aitium.png" alt="Aitium logo" style={{ width: '100px', height: '100px' }} />
          <Text
            style={{
              fontFamily: 'Inter',
              fontSize: '1.5rem',
              fontWeight: '500',
              color: 'rgba(13,26,38,1)',
            }}
          >
            Sign Up
          </Text>
          <form onSubmit={handleSignup} style={formStyles}>
            {error && <Text color="red">{error}</Text>}
            <Flex direction="row" width="90%" justifyContent="center" alignItems="center">
              <TextField
                label="First Name"
                width="100%"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                required
              />
            </Flex>
            <Flex direction="row" width="90%" justifyContent="center" alignItems="center">
              <TextField
                label="Last Name"
                width="100%"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                required
              />
            </Flex>
            <Flex direction="row" width="90%" justifyContent="center" alignItems="center">
              <TextField
                label="Email"
                width="100%"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Flex>
            <Flex direction="row" width="90%" justifyContent="center" alignItems="center">
              <TextField
                label="Password"
                width="100%"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Flex>
            <Flex direction="row" width="90%" justifyContent="center" alignItems="center">
              <TextField
                label="Re-type Password"
                width="100%"
                type="password"
                value={rePassword}
                onChange={(e) => setRePassword(e.target.value)}
                required
              />
            </Flex>
            <Flex direction="row" width="90%" alignItems="center" style={{ marginBottom: '5px' }}>
              <CheckboxField defaultChecked={true} />
              <Text style={{ fontSize: '14px' }}>
                {' '}
                I agree to the{' '}
                <a href="https://gaiiff.com/termsofservice/" target="_blank" style={linksStyle}>
                  Terms of Use
                </a>{' '}
                and{' '}
                <a target="_blank" href="https://gaiiff.com/privacy/" style={linksStyle}>
                  Privacy Policy
                </a>
              </Text>
            </Flex>
            <Flex direction="row" width="90%" justifyContent="space-between" alignItems="center">
              <Button type="submit" isLoading={isLoading} style={{ width: '100%', color: 'white', backgroundColor: '#00607A' }}>
                {isLoading ? 'Signing Up...' : 'Sign Up'}
              </Button>
            </Flex>
          </form>
          <View flexDirection="column" justifyContent="center" alignItems="center" textAlign="center" style={{ height: '60px' }}>
            <Text style={{ fontSize: '14px' }}>Already have an account?</Text>
            <Button variation="link" onClick={redirectToLogin} style={{ fontSize: '14px', justifyContent: 'center', alignItems: 'center' }}>
              Go to Sign In
            </Button>
          </View>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        width={{ base: '100%', medium: '55%' }}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        padding="20px"
        style={{ backgroundColor: '#EFF0F0', textAlign: 'center' }}
      >
        <Image src="gaiff_logo.png" alt="GAIFF logo" style={{ width: '50%' }} />
        <Flex direction="row" marginTop="10px">
          <Text
            textAlign="center"
            style={{
              fontFamily: 'Inter',
              fontSize: '2.0rem',
              fontWeight: '400',
              color: 'rgba(13,26,38,1)',
              marginBottom: '20px',
            }}
          >
            Aitium: Advancing your Amazon Business
          </Text>
        </Flex>
      </Flex>

      {/* Confirmation Modal */}
      <Modal
        isOpen={showConfirmationModal}
        onRequestClose={() => setShowConfirmationModal(false)}
        shouldCloseOnOverlayClick={false}  
        style={{
          content: {
            width: '90%',
            maxWidth: '400px',  // Maximum width to ensure it doesn't get too big
            height: 'auto',     // Adjust height automatically based on content
            maxHeight: '300px', // Maximum height to ensure it doesn't get too tall
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',   // Add padding for spacing
            boxSizing: 'border-box',  // Ensure padding is included in the width and height
          },
        }}
      >
        {confirmationCodeError && <Text color="red" style={{ marginBottom: '10px' }}>{confirmationCodeError}</Text>}
        <Text style={{ marginBottom: '20px', textAlign: 'center', fontSize: '16px' }}>
          Your code is on the way. To log in, enter the code we emailed to {maskedEmail}. It may take a minute to arrive.
        </Text>
        <TextField
          value={confirmationCode}
          onChange={(e) => setConfirmationCode(e.target.value)}
          style={{ marginBottom: '20px', width: '100%' }}  // Ensure TextField fits the modal width
          placeholder="Enter your confirmation code"
        />
        <Button
          onClick={handleConfirmation}
          isLoading={isConfirming}
          style={{ color: 'white', backgroundColor: '#00607A', width: '100%' }}  // Full-width button
        >
          {isConfirming ? 'Confirming...' : 'Confirm'}
        </Button>
        {error && <Text color="red" style={{ marginTop: '10px' }}>{error}</Text>}
      </Modal>
    </Flex>
  );
};

export default SignupPage;
