/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Button, Flex, Text, Image } from "@aws-amplify/ui-react";

export default function CustomLandingHeroSection(props) {
  const { overrides, navigateToLogin, ...rest } = props;

  const containerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width:"100%",
    height: '100%',
    gap: '0px',
    lineHeight: 'normal'
};

const leftStyle = {
    flex: '0 0 50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: '20px'
};

const rightStyle = {
    flex: '0 0 50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#BCECF5'
};

const headingStyle = {
    // marginBottom: '20px'
    display: 'block',
    fontWeight: 'bold',
    fontSize: '1.8em',
    padding: '0px'
};

const listStyle = {
    listStyleType: 'none',
    padding: '0',
    marginBottom: '20px'
};

const listItemStyle = {
    marginBottom: '10px',
    fontSize: '1.4em'
    

};

const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#00607a',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold'
};

  return (
    <Flex
      style={containerStyle}
      {...getOverrideProps(overrides, "SalesTrafficConversion")}
      {...rest}
    >
      <Flex
        style={leftStyle}
      >
        <Image
          src="horizontal-aitium-logo.png"
          alt="aitium"
          height="80px"
          marginBottom="30px"
          {...getOverrideProps(overrides, "AitiumLogo")}
        />
        <span
          fontFamily="Inter"
          style={headingStyle}
          children="Sales, Traffic and Conversions - with AI."
          {...getOverrideProps(overrides, "Heading1")}
        />
       <span
          
          fontFamily="Inter"
          style={headingStyle}
          children="Gain insight about your customers and"
          {...getOverrideProps(overrides, "Heading2")}
        />
        <span
           
          fontFamily="Inter"
          style={headingStyle}
          children="products with aitium® sales, traffic and"
          {...getOverrideProps(overrides, "Heading3")}
        />
       <span
          fontFamily="Inter"
          style={headingStyle}
          children="conversion report visualized for a deeper"
          {...getOverrideProps(overrides, "Heading4")}
        />
        <span
          fontFamily="Inter"
          style={headingStyle}
          children="knowledge of your Amazon storefront."
          {...getOverrideProps(overrides, "Heading5")}
        />
        <ul style={listStyle}>
          <Text
            as="li"
            style={listItemStyle}
            children="• Sales Analytics"
            {...getOverrideProps(overrides, "ListItem1")}
          />
          <Text
            as="li"
            fontFamily="Inter"
            style={listItemStyle}
            children="• Enterprise demand planning"
            {...getOverrideProps(overrides, "ListItem2")}
          />
          <Text
            as="li"
            fontFamily="Inter"
            style={listItemStyle}
            children="• Free funding assessment"
            {...getOverrideProps(overrides, "ListItem3")}
          />
        </ul>
        <Button
          style={buttonStyle}
          onClick={navigateToLogin}
          children="Get started"
          {...getOverrideProps(overrides, "GetStartedButton")}
        />
      </Flex>
      <Flex
        style={rightStyle}
      >
        <Image
          src="landing-page-bg1.png"
          alt="Illustration"
          style={{ maxHeight: '100%', maxWidth: '100%' }}
          {...getOverrideProps(overrides, "LandingPageImage")}
        />
      </Flex>
    </Flex>
  );
}