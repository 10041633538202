import React from 'react'
import { useState,useEffect,useRef } from 'react';
import { Amplify, Auth, API, Storage,Cache } from 'aws-amplify';
import {Flex, View, Button,Text,
    Divider,Radio,SelectField,SearchField,TextField,Icon, Link} from '@aws-amplify/ui-react';
    import  NavBar  from '../components/navbar.tsx';
import studioTheme from '../ui-components/studioTheme';
import SideBar from '../ui-components/SideBar';
import AccountAccountInformation from '../ui-components/Forecasts'; 
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsconfig from '../aws-exports';
import { getUser } from '../graphql/queries';
import { createImportSpecifier } from 'typescript';
import Selectedbutton from '../ui-components/SelectedButton';
import ItemCard from '../ui-components/ItemCard';

import Partners from '../ui-components/Partners';
import moment from 'moment';
import SalesReportOptions from '../ui-components/SalesReportOptions';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, ChartDataset,LabelItem, Color, CategoryScale, LinearScale, PointElement, LineElement, Tooltip} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

Amplify.configure(awsconfig);

function CorporateAnalyticsReportAsin({changeView}) {
    // testing asin  B0B2HXZG5Q
    const [isLoading, setIsLoading] = useState(true);
    const [lineChartOptions, setLineChartOptions] = useState({});
    const [asinIsLoading, setasinIsLoading] = useState(true);
    const [selectValue, setSelectValue] = useState("time-lastThirtyDays");
    const [orderedProducts, setOrderedProducts] = useState({});
    const [asinOrderedProducts, setAsinOrderedProducts] = useState({});
    const [chartData,setChartData]= useState({});
  
    const [averages1,setAverages1]= useState("");
    const [averages2,setAverages2]= useState("");
    const [averages3,setAverages3]= useState("");

    const [timePeriod, setTimePeriod] = useState("time-lastThirtyDays");
    const [sortBy, setSortBy] = useState(""); 

    const [asin, setAsin] = useState("");

    const _asinTraffic = useRef({});
    const _asinSales = useRef({});
    const _asinStrDates = useRef<String[]>([]);
    const _asinChartDates = useRef<String[]>([]);
    const _timePeriod = useRef("lastThirtyDays");
    const _sortBy = useRef("sessions");
    
    const _orderedProductSales= useRef<Array<number>>([]);
    const _orderedProductSalesB2B = useRef<Array<number>>([]);
    const _orderedProductSalesNotB2B = useRef<Array<number>>([]);

    let averageOfferCount = new Array<Number>();
    let averageParentItems = new Array<Number>();
    let browserPageViews = new Array<Number>();
    let browserSessions = new Array<Number>();
    
    let feedbackReceived = new Array<Number>();
    let mobileAppPageViews = new Array<Number>();
    
    let negativeFeedbackReceived = new Array<Number>();
    let orderItemSessionPercentage = new Array<Number>();
    let orderItemSessionPercentageB2B = new Array<Number>();
    
    let receivedNegativeFeedbackRate = new Array<Number>();
    
    let unitSessionPercentage = new Array<Number>();
    let unitSessionPercentageB2B = new Array<Number>();
    let unitSessionPercentageNotB2B = new Array<Number>();

    let asinOrderItemSessionPercentage = new Array<Number>();
    let asinOrderItemSessionPercentageB2B = new Array<Number>();
    let asinOrderItemSessionPercentageNotB2B = new Array<Number>();
    let asinUnitSessionPercentage = new Array<Number>();
    let asinUnitSessionPercentageB2B = new Array<Number>();
    let asinUnitSessionPercentageNotB2B = new Array<Number>();
    let asinPageViewConversionPercentage = new Array<Number>();

    let asinSessionPercentage = new Array<Number>();
    let asinPageViewPercentage = new Array<Number>();
    let asinSalesPerBrowserSession = new Array<Number>();
    let asinSalesPerMobileAppSession = new Array<Number>();
    let asinSalesPerBrowserPageViews = new Array<Number>();
    let asinSalesPerMobileAppPageViews = new Array<Number>();

    const _filterBy = useRef<String>("");
    const _sessionType = useRef<String>("");
    const _pageViews = useRef<Number[]>([]);
    const _sessions = useRef<Number[]>([]);
    const _buyBoxPercentage = useRef<Number[]>([]);
    const _mobileAppSessions = useRef<Number[]>([]);

    let lineChartData: Number[] = [];
    let lineChartLabels: String[] = [];
    
    let asinLineChartOptions: any = {};
    let asinLineChartLabels: String[] = [];

    let trafficByDate = {};
    let asinParentList = {};
    let asinChildList = {};

    let asinStrDates = [];

    let asinSales = [];

    let view = "sales";
    
    useEffect(() => {
        ChartJS.register(CategoryScale,LinearScale, PointElement, LineElement,zoomPlugin, Tooltip);
        
    },[]);// <-- Have to pass in [] here or is called on every render
    

    function moveAsinView(e) {
      if(e.target.value == "date"){
        changeView('date');
      }
    }

    function onASINChange(event){
      setAsin(event.target.value);
    };
  
    // It is your responsibility to set up onClear
    function onASINClear(){
      console.log('clear');
    };
  
    function onASINClick(event){
      console.log(event.target.value);
    };

    function onASINSubmit(){
      if(asin.length > 3){
        downloadSingleAsin(asin);
      }
    };

    async function downloadSingleAsin(aa:String) {
      _asinStrDates.current = [];
      const market = Cache.getItem('marketCode');
      let salesUrl = market+ "_SalesAndTrafficReport/AsinSales/"+aa+".json";
      let trafficUrl = market+ "_SalesAndTrafficReport/AsinTraffic/"+aa+".json";
      const salesResult = await Storage.vault.get(salesUrl, { download: true });
      const trafficResult = await Storage.vault.get(trafficUrl, { download: true });
      const asinSales = await new Response(salesResult.Body).json();
      const asinTraffic = await new Response(trafficResult.Body).json();
      _asinSales.current = asinSales;
      _asinTraffic.current = asinTraffic;
      sortAsinSTR();
    }

    function findAverageCurrentData(array){
        var total = 0;
        for(var i = 0; i < array.length; i++) {
            total += array[i];
        }
        let returnValue = (total / array.length).toFixed(2);
        if(returnValue == "NaN"){
          return 0;
        }
        return returnValue;
    }

    function oldsortAsinSTR(){
      
    //console.log(asinOrderItemSessionPercentage)
    //console.log(this.asinOrderItemSessionPercentageB2B)
    //console.log(this.asinOrderItemSessionPercentageNotB2B)
    //console.log("asin data")
    //console.log(this.asinUnitSessionPercentageB2B)
    this.dynamicForm.controls.SortBy.setValue("unitSessionPercent")
      this.asinLineChartOptions = {
        responsive: true,
        showTooltips: true,
        scales: {
          yAxes:[  {
             scaleLabel: {
                display: true,
                labelString: "Percent"
             }
            
        }
      ]
         
       },
        plugins:{
          tooltip: {
            callbacks: {
                label: function (tooltipItem, data) {
                    return " "+tooltipItem.dataset.label + " "+tooltipItem.formattedValue;
                }
            }
        },
          // zoom: {
          //   zoom: {
          //     wheel: {
          //       enabled: true,
          //     },
          //     pinch: {
          //       enabled: true
          //     },
          //     mode: 'xy',
          //   }
          // },
          legend:{
            display:true,
            title:{
              display: true,
              text: "Example"
            }
          }
        },
    
      };
      this.asinLineChartData=[
        {
          data:this.asinUnitSessionPercentage,label:"Unit Session Percentage", borderColor: '#80956e'
        },
        {
          data:this.asinUnitSessionPercentageB2B,label:"Unit Session Percentage Corporate", borderColor: '#0000ff' 
        },
        {
          data:this.asinUnitSessionPercentageNotB2B,label:"Unit Session Percentage Retail", borderColor: '#00ff00' 
        },
      ];
      
      this.asinLineChartLabels =this.asinStrDates;
    
        for(let i in this.asinLineChartData ){
          this.asinFullLineChartData.push(this.asinLineChartData[i].data);
        }
        //console.log("Chart data")
        //console.log(this.asinFullLineChartData)
        // if(this.asinFullLineChartData[0].length < 30){
        //   this.filterBy('lastSevenDays')
        //   this.dynamicForm.controls.FilterBy.setValue( 'lastSevenDays')
        // }
        // else{
          
          this.filterBy('lastThirtyDays')
          this.dynamicForm.controls.FilterBy.setValue( 'lastThirtyDays')
        // }
     }

    function setupData(){
      
      _asinChartDates.current = [..._asinStrDates.current];
      
      let temp;
      let unitSessionbtob;
      asinOrderItemSessionPercentage = new Array<Number>();
      asinOrderItemSessionPercentageB2B = new Array<Number>();
      asinOrderItemSessionPercentageNotB2B = new Array<Number>();
      asinUnitSessionPercentage = new Array<Number>();
      asinUnitSessionPercentageB2B = new Array<Number>();
      asinUnitSessionPercentageNotB2B = new Array<Number>();
      asinPageViewConversionPercentage = new Array<Number>();

      asinSessionPercentage = new Array<Number>();
      asinPageViewPercentage = new Array<Number>();
      asinSalesPerBrowserSession = new Array<Number>();
      asinSalesPerMobileAppSession = new Array<Number>();
      asinSalesPerBrowserPageViews = new Array<Number>();
      asinSalesPerMobileAppPageViews = new Array<Number>();

      for(let i in _asinTraffic.current){
    
        if(Number(_asinTraffic.current[i].sessions) === 0 || Number(_asinSales.current[i].unitsOrdered) === 0  )
        {
          temp = 0
          asinOrderItemSessionPercentage.push(temp)
        }
        else{
          temp = Number(_asinSales.current[i].unitsOrdered) / Number(_asinTraffic.current[i].sessions)
          asinOrderItemSessionPercentage.push(temp)
        }
    //------------------------------
        if(Number(_asinTraffic.current[i].sessions) === 0 || Number(_asinSales.current[i].unitsOrderedB2B) === 0  )
        {
          temp = 0
          asinOrderItemSessionPercentageB2B.push(temp)
        }
        else{
          temp = Number(_asinSales.current[i].unitsOrderedB2B) / Number(_asinTraffic.current[i].sessions)
          asinOrderItemSessionPercentageB2B.push(temp)
        }
    //------------------------------
       
        if(Number(_asinSales.current[i].unitsOrdered) === 0|| Number(_asinTraffic.current[i].pageViews) === 0)
        {
          temp = 0;
          console.log("PAGE CONVERSION IF: "+temp);
        } 
        else{
          temp = (Number(_asinSales.current[i].unitsOrdered) / Number(_asinTraffic.current[i].pageViews))
          //console.log(temp)
          // temp >= 1 ? temp = 1: temp = 0;
          //console.log(temp)
          temp = temp * 100;
          console.log("UNITS ORDERED "+_asinSales.current[i].unitsOrdered + " AND PAGE VIEWS "+_asinTraffic.current[i].pageViews);
        }
        asinPageViewConversionPercentage.push(temp)
    //------------------------------
        temp = Number(asinOrderItemSessionPercentage[i]) - Number(asinOrderItemSessionPercentageB2B[i]) 
        asinOrderItemSessionPercentageNotB2B.push(temp)  
        asinUnitSessionPercentage.push(_asinTraffic.current[i].unitSessionPercentage) 
        if( _asinSales.current[i].unitsOrderedB2B == 0 || _asinTraffic.current[i].sessions == 0)
        {
          unitSessionbtob = 0;
        }
        else{   
          unitSessionbtob = ((Number(_asinSales.current[i].unitsOrderedB2B) / Number(_asinTraffic.current[i].sessions)).toFixed(6))
          unitSessionbtob *= 100;
        }
          asinUnitSessionPercentageB2B.push(unitSessionbtob)
          temp = Number(_asinTraffic.current[i].unitSessionPercentage) - unitSessionbtob 
          asinUnitSessionPercentageNotB2B.push(temp)   
          //---salesPerBrowserSession---//
        if(_asinSales.current[i].orderedProductSales.amount === 0 || _asinTraffic.current[i].browserSessions === 0)
        {
          temp = 0;
        }
        else{
          temp = (Number(_asinSales.current[i].orderedProductSales.amount) / Number(_asinTraffic.current[i].browserSessions))
        } 
        asinSalesPerBrowserSession.push(temp)
        //---salesPerMobileAppSession---//
        if(_asinSales.current[i].orderedProductSales.amount === 0 || _asinTraffic.current[i].mobileAppSessions === 0)
        {
          temp = 0;
        }
        else{
          temp = (Number(_asinSales.current[i].orderedProductSales.amount) / Number(_asinTraffic.current[i].mobileAppSessions))
      
        } 
        asinSalesPerMobileAppSession.push(temp)
      //---salesPerBrowserPageViews---//
        if(_asinSales.current[i].orderedProductSales.amount === 0 || _asinTraffic.current[i].browserPageViews === 0)
        {
          temp = 0;
        }
        else{
          
          temp = (Number(_asinSales.current[i].orderedProductSales.amount) / Number(_asinTraffic.current[i].browserPageViews))
        } 
        asinSalesPerBrowserPageViews.push(temp)
      
        asinSessionPercentage.push(_asinTraffic.current[i].sessionPercentage)
        asinPageViewPercentage.push(_asinTraffic.current[i].pageViewsPercentage)
        _asinChartDates.current.push(moment(_asinTraffic.current[i].date,"YYYY-MM-DD").format('MMM D, y'))
      }
      
      if(_asinChartDates.current.length < 30){
        
        let diff = 30 - _asinChartDates.current.length
        let firstDate = new Date (String(_asinChartDates.current[0]))
        
        for(let i = diff; i > 0; i--)
        {
          firstDate.setDate(firstDate.getDate() - 1)
          _asinChartDates.current.unshift(moment(firstDate).format('MMM D, y'))
          asinOrderItemSessionPercentage.unshift(0 as Number)
          asinOrderItemSessionPercentageB2B.unshift(0 as Number)
          asinOrderItemSessionPercentageNotB2B.unshift(0 as Number)
          asinUnitSessionPercentage.unshift(0 as Number)
          asinUnitSessionPercentageB2B.unshift(0 as Number)
          asinUnitSessionPercentageNotB2B.unshift(0 as Number)
          asinPageViewConversionPercentage.unshift(0 as Number)
          asinSessionPercentage.unshift(0 as Number)
          asinPageViewPercentage.unshift(0 as Number)
          asinSalesPerBrowserSession.unshift(0 as Number)
          asinSalesPerMobileAppSession.unshift(0 as Number)
          asinSalesPerBrowserPageViews.unshift(0 as Number)
          asinSalesPerMobileAppPageViews.unshift(0 as Number)
        }
      }
    }

    async function sortAsinSTR(){
      //setupData();
      

      filter();
    }

    function setAverages(average1, average2, average3){
      
      if(_sortBy.current == 'sessions'){
        setAverages1(<ItemCard overrides={{Name:{children:"Average for Unit Session Percentage"}, Number:{children:average1}}}></ItemCard>)
        setAverages2(<ItemCard overrides={{Name:{children:"Average for Unit Session Percentage Corporate"}, Number:{children:average2}}}></ItemCard>)
        setAverages3(<ItemCard overrides={{Name:{children:"Average for Unit Session Percentage Retail"}, Number:{children:average3}}}></ItemCard>)
        
      }
      else if(_sortBy.current == 'pageViews'){
        setAverages1(<ItemCard overrides={{Name:{children:"Average for Total Page View Percentage"}, Number:{children:average1}}}></ItemCard>)
        setAverages2("")
        setAverages3("")
      }
      else if(_sortBy.current == 'browser'){
        setAverages1(<ItemCard overrides={{Name:{children:"Average for Sales per Browser Page Views %"}, Number:{children:average1}}}></ItemCard>)
        setAverages2("");
        setAverages3("")
      }
      else if(_sortBy.current == 'mobile'){
        
        setAverages1(<ItemCard overrides={{Name:{children:"Average for Sales per Mobile App Page Views %"}, Number:{children:average1}}}></ItemCard>)
        setAverages2("");
        setAverages3("")
      }
    }

    function sliceArrayToTimePeriod(inputArray){
      let returnArray;
      const now = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());
    
      if(_timePeriod.current == "lastThirtyDays"){
        returnArray = inputArray.slice(Math.max(inputArray.length - 30, 1));
      }
      else if(_timePeriod.current == "lastSevenDays"){
        returnArray =inputArray.slice(Math.max(inputArray.length - 7, 1));
      }
      else if(_timePeriod.current == "thisWeek"){
        let y = _asinChartDates.current[_asinChartDates.current.length-1]
        // console.log(_asinStrDates.current)
        
        const lastDate = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());
        const date = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());
        const day = date.getDay();
        const diff = date.getDate() - day + (day === 0 ? -6 : 1);
        let x = new Date(date.setDate(diff));
        const firstDay = moment(x).format('MMM D, y')
      
        let index = _asinChartDates.current.indexOf(firstDay);
        if(lastDate.getDay() === 1 && _asinChartDates.current.length > 8)
        {
          index -= 7;
        }
        if(index === -1)
        {
          index = 0;
        }
        
        const lastDay = moment(lastDate).format('MMM D, y')
        let firstIndex = asinLineChartLabels.indexOf(firstDay);
        let lastIndex = asinLineChartLabels.indexOf(lastDay);
        
        returnArray =inputArray.slice(firstIndex,lastIndex);
      }
      else if(_timePeriod.current == "thisMonth"){
        const lastDate = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());
        const now = new Date(_asinChartDates.current[_asinChartDates.current.length-1].toString());
        const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1,)).format('MMM D, y')//this.datepipe.transform(new Date(now.getFullYear(), now.getMonth(), 1,), 'MMM d, y');
        
        let index = _asinChartDates.current.indexOf(firstDay);
        
        const lastDay = moment(lastDate).format('MMM D, y')
        let firstIndex = _asinChartDates.current.indexOf(firstDay);
        let lastIndex = _asinChartDates.current.indexOf(lastDay);
        
        returnArray =inputArray.slice(firstIndex,lastIndex);

      }
      else if (_timePeriod.current === "lastOneYear" || _timePeriod.current === "lastTwoYears") {
        const yearsToSubtract = _timePeriod.current === "lastOneYear" ? 1 : 2;
        const startDate = new Date(now.getFullYear() - yearsToSubtract, now.getMonth(), now.getDate());
        
        returnArray = [];
        let currentDate = new Date(startDate);
        
        while (currentDate <= now) {
          const formattedDate = moment(currentDate).format('MMM D, y');
          const index = _asinChartDates.current.indexOf(formattedDate);
          
          if (index !== -1) {
            // If the date exists in the original data, use the original value
            returnArray.push(inputArray[index]);
          } else {
            // If the date doesn't exist, add a placeholder based on data type
            if (typeof inputArray[0] === 'number') {
              returnArray.push(0);
            } else if (inputArray[0] instanceof Date || typeof inputArray[0] === 'string') {
              returnArray.push(formattedDate);
            } else {
              // For any other type, push null or an appropriate placeholder
              returnArray.push(null);
            }
          }
          
          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
      return returnArray;
  }

  function generateDates(startDate, endDate) {
    let dates = [];
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      dates.push(moment(currentDate).format('MMM D, y'));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }


    function filter(){
      setupData();
      setLineChartOptions( {
          responsive: true,
          // tooltips: {
          //   callbacks: {
          //       label: function (tooltipItem, data) {
          //           return Number(tooltipItem.yLabel);
          //       }
          //   }
          // },
      //   scales: {
      //     yAxes:[  {
      //       //  scaleLabel: {
      //       //     display: true,
      //       //     labelString: 'USD'//market.selectedCurency
      //       //  },
      //       //  ticks:{
      //       //       callback: function(value, index, values) {
      //       //         return '$' + value;
      //       //     }
      //       //  }
            
      //       }
      //     ]
         
      //  },
          plugins: {
            legend: {
              display:true,
              title:{
                display: true
              }
            },
            title: {
              display: true,
              text: 'Chart.js Line Chart',
            },
          },
        });
      
        asinLineChartLabels = _asinChartDates.current;
        
        switch(_sortBy.current){
          case 'sessions':{
            asinLineChartOptions = {
              responsive: true,
            //   tooltips: {
            //     callbacks: {
            //         label: function (tooltipItem, data) {
            //             return Number(tooltipItem.yLabel);
            //         }
            //     }
            // },
            //   scales: {
            //     yAxes:[  {
            //         scaleLabel: {
            //           display: true,
            //           labelString: "Sessions"
            //         }
                  
            //   }
            // ]
                
            //   },
              plugins:{
                legend:{
                  display:true,
                  title:{
                    display: true,
                    text: "Example"
                  }
                }
              },
          
            };
            
            const asinOrderedProducts = {
              labels : sliceArrayToTimePeriod(asinLineChartLabels),
              datasets: [
                {
                  data:sliceArrayToTimePeriod(asinUnitSessionPercentage),
                  label:"Unit Session Percentage", 
                  borderColor: '#80956e',
                  
                  backgroundColor: '#80956e',
                  lineTension: 0.4
                },
                {
                  data:sliceArrayToTimePeriod(asinUnitSessionPercentageB2B),
                  label:"Unit Session Percentage Corporate", 
                  borderColor: '#0000ff',
                  backgroundColor: '#0000ff',
                  lineTension: 0.4
                },
                {
                  data:sliceArrayToTimePeriod(asinUnitSessionPercentageNotB2B),
                  label:"Unit Session Percentage Retail", 
                  borderColor: '#00ff00',
                  backgroundColor: '#00ff00',
                  lineTension: 0.4
                }

              ],
            };
            
            let av1=findAverageCurrentData(sliceArrayToTimePeriod(asinUnitSessionPercentage));
            let av2=findAverageCurrentData(sliceArrayToTimePeriod(asinUnitSessionPercentageB2B));
            let av3=findAverageCurrentData(sliceArrayToTimePeriod(asinUnitSessionPercentageNotB2B));
            setAverages(av1,av2,av3);
            setLineChartOptions(asinLineChartOptions);
            setIsLoading(false);
            setChartData(asinOrderedProducts);
            
            break;
          }
          case 'pageViews':{
            asinLineChartOptions = {
              responsive: true,
            //   tooltips: {
            //     callbacks: {
            //         label: function (tooltipItem, data) {
            //             return Number(tooltipItem.yLabel);
            //         }
            //     }
            // },
            //   scales: {
            //     yAxes:[  {
            //         scaleLabel: {
            //           display: true,
            //           labelString: "Page Views"
            //         }
                  
            //   }
            // ]
                
            //   },
              plugins:{
                legend:{
                  display:true,
                  title:{
                    display: true,
                    text: "Example"
                  }
                }
              },
          
            };
            
            const asinOrderedProducts = {
              labels : sliceArrayToTimePeriod(asinLineChartLabels),
              datasets: [
                {
                  data:sliceArrayToTimePeriod(asinPageViewConversionPercentage),
                  label:"Page View Conversion Percentage", 
                  borderColor: '#80956e',
                  
                  backgroundColor: '#80956e',
                  lineTension: 0.4
                }
              ],
            };
            let av1=findAverageCurrentData(sliceArrayToTimePeriod(asinPageViewConversionPercentage));
            let av2=0;
            let av3=0;
            setAverages(av1,av2,av3);
            setLineChartOptions(asinLineChartOptions);
            setIsLoading(false);
            setChartData(asinOrderedProducts);
            
            break;
          }
          case 'browser':{
            asinLineChartOptions = {
              responsive: true,
            //   tooltips: {
            //     callbacks: {
            //         label: function (tooltipItem, data) {
            //             return Number(tooltipItem.yLabel);
            //         }
            //     }
            // },
            //   scales: {
            //     yAxes:[  {
            //         scaleLabel: {
            //           display: true,
            //           labelString: "Percent"
            //         }
                  
            //   }
            // ]
                
            //   },
              plugins:{
                legend:{
                  display:true,
                  title:{
                    display: true,
                    text: "Example"
                  }
                }
              },
          
            };
            
            const asinOrderedProducts = {
              labels : sliceArrayToTimePeriod(asinLineChartLabels),
              datasets: [
                {
                  data:sliceArrayToTimePeriod(asinSalesPerBrowserPageViews),
                  label:"Sales per Browser Page Views", 
                  borderColor: '#80956e',
                  
                  backgroundColor: '80956e',
                  lineTension: 0.4
                }
              ],
            };
            let av1=findAverageCurrentData(sliceArrayToTimePeriod(asinSalesPerBrowserPageViews));
            let av2=0;
            let av3=0;
            setAverages(av1,av2,av3);
            setLineChartOptions(asinLineChartOptions);
            setIsLoading(false);
            setChartData(asinOrderedProducts);
            
            break;
          }
          case 'mobile':{
            asinLineChartOptions = {
              responsive: true,
            //   tooltips: {
            //     callbacks: {
            //         label: function (tooltipItem, data) {
            //             return Number(tooltipItem.yLabel);
            //         }
            //     }
            // },
            //   scales: {
            //     yAxes:[  {
            //         scaleLabel: {
            //           display: true,
            //           labelString: "Percent"
            //         }
                  
            //   }
            // ]
                
            //   },
              plugins:{
                legend:{
                  display:true,
                  title:{
                    display: true,
                    text: "Example"
                  }
                }
              },
          
            };
            
            const asinOrderedProducts = {
              labels : sliceArrayToTimePeriod(asinLineChartLabels),
              datasets: [
                {
                  data:sliceArrayToTimePeriod(asinSalesPerMobileAppPageViews),
                  label:"Sales per Mobile App Page Views", 
                  borderColor: '#80956e',
                  
                  backgroundColor: '#80956e',
                  lineTension: 0.4
                }
              ],
            };
            let av1=findAverageCurrentData(sliceArrayToTimePeriod(asinSalesPerMobileAppPageViews));
            let av2=0;
            let av3=0;
            setAverages(av1,av2,av3);
            setLineChartOptions(asinLineChartOptions);
            setIsLoading(false);
            setChartData(asinOrderedProducts);
            
            break;
          }
        }
        
      }


      function handleDropdownChange (e){
        let sort = sortBy;
        let time = timePeriod;
        // console.log(e.target.value);
        if(e.target.value.includes("time-")){
          setSelectValue(e.target.value);
          time = e.target.value.replace("time-","");
          setTimePeriod(time);
          _timePeriod.current = time;
        }
        else {
          sort = e.target.value.replace("sort-","");
          // console.log(sort);
          setSortBy(sort);
          _sortBy.current = sort;
        }
        filter();
          
      }
    
    return (
        
       
                    <Flex
                        gap="32px"
                        direction="column"
                        width="1194px"
                        height="842px"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        overflow="hidden"
                        position="relative"
                        padding="32px 32px 32px 32px"
                        
                        >
                        <Flex
                            gap="8px"
                            direction="column"
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            <Text
                            fontFamily="Inter"
                            fontSize="32px"
                            fontWeight="500"
                            color="rgba(13,26,38,1)"
                            lineHeight="40px"
                            textAlign="left"
                            display="block"
                            direction="column"
                            justifyContent="unset"
                            width="unset"
                            height="unset"
                            gap="unset"
                            alignItems="unset"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            whiteSpace="pre-wrap"
                            children="Corporate Analytics Report By ASIN"
                            
                            ></Text>
                            <SearchField
          label="Search"
          placeholder="Enter ASIN"
          onChange={onASINChange}
          onClear={onASINClear}
          onClick={onASINClick}
          onSubmit={onASINSubmit}
          size="large"
        />
                        </Flex>
                        <Flex
                            gap="32px"
                            direction="row"
                            width="unset"
                            height="unset"
                            justifyContent="center"
                            alignItems="flex-start"
                            shrink="0"
                            alignSelf="stretch"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                        >
                            
                                {averages1}
                                
                            
                            
                               {averages2}
                               {averages3}
                            
                            {/* <Flex
                            width="unset"
                            height="unset"
                            justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                            >
                                <div>Average for Ordered Product Sales Not B2B 
              {currency} {orderedProductSalesNotB2BModel}
            </div>
                            </Flex> */}
                        </Flex>
                        
                    
                        <Flex
                        gap="48px"
                        direction="column"
                        width="1130px"
                        height="535px"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        position="relative"
                        border="1px SOLID rgba(174,179,183,1)"
                        borderRadius="12px"
                        padding="31px 31px 31px 31px"
                        backgroundColor="rgba(255,255,255,1)"
                    
                        >
                            <Flex
                                gap="32px"
                                direction="row"
                                width="unset"
                                height="unset"
                                justifyContent="center"
                                alignItems="flex-start"
                                shrink="0"
                                alignSelf="stretch"
                                position="relative"
                                padding="0px 20px 0px 20px"
                                
                            ><Flex
                            gap="4px"
                            direction="column"
                            width="unset"
                            height="72px"
                            justifyContent="flex-start"
                            alignItems="center"
                            shrink="0"
                            position="relative"
                            padding="0px 0px 0px 0px"
                            
                            >
                                <SelectField
                                    width="300px"
                                    height="unset"
                                    label="Type"
                                    shrink="0"
                                    size="default"
                                    isDisabled={false}
                                    labelHidden={false}
                                    variation="default"
                                    onChange={moveAsinView}
                                    defaultValue="asin"
                                >
                                  <option value="date" >By Date</option>
                                  <option value="asin">By ASIN</option>
                                </SelectField>
                            </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="300px"
                                        height="unset"
                                        label="Type"
                                        placeholder="By Date"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        
                                       
                                        label="Filter By Date" value={selectValue}  onChange={handleDropdownChange}
                                      >
                                          
                                          <option value="time-lastSevenDays">Last 7 Days</option>
                                          <option value="time-lastThirtyDays">Last 30 Days</option>
                                          <option value="time-thisWeek">This Week</option>
                                          <option value="time-thisMonth">This Month</option>
                                          <option value="time-lastOneYear">Last 1 Year</option>
                                          <option value="time-lastTwoYears">Last 2 Years</option>
                                      </SelectField>
                                </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Filter By"
                                        
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                    
                                        onChange={handleDropdownChange}
                                       
                                        label="Session Type"  onChange={handleDropdownChange}
                                      >
                                          <option value="sort-sessions">Unit Session %</option>
                                          <option value="sort-pageViews">Total Page View %</option>
                                          <option value="sort-browser">Sales per Browser Page Views</option>
                                          <option value="sort-mobile">Sales per Mobile App Page Views</option>
                                      </SelectField>
                                </Flex>
                                <Flex
                                gap="4px"
                                direction="column"
                                width="unset"
                                height="72px"
                                justifyContent="flex-start"
                                alignItems="center"
                                shrink="0"
                                position="relative"
                                padding="0px 0px 0px 0px"
                                
                                >
                                    {/* <SelectField
                                        width="320px"
                                        height="unset"
                                        label="Sort By"
                                        placeholder="Unit Session %"
                                        shrink="0"
                                        size="default"
                                        isDisabled={false}
                                        labelHidden={false}
                                        variation="default"
                                        
                                    ></SelectField> */}
                                </Flex>
                            </Flex>
                            
                             {isLoading ? <Flex/> : <Line options={lineChartOptions} data={chartData} width="400" height="100" />}
           
                            
            
                  
                        </Flex> 
                    </Flex>          
               
        
    );
}

export default withAuthenticator(CorporateAnalyticsReportAsin);