/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "./utils";
import { Flex, Image, Text, View, Link, Button } from "@aws-amplify/ui-react";

export default function CustomNavBarHeader(props) {
  const { overrides, homeRef, productsRef, pricingRef, contactRef, navigateToSignup, ...rest } = props;

 
  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '30px 50px',
    backgroundColor: 'white',
    // borderBottom: '1px solid #ddd'
};

const logoStyle = {
    height: '40px'
};
  const navStyle = {
    display: 'flex',
    alignItems: 'center'
};

const navLinkStyle = {
    margin: '0 15px',
    textDecoration: 'none',
    color: 'black',
    fontSize: '1.3em'
    // fontWeight: 'bold'
};
  const authStyle = {
    display: 'flex',
    alignItems: 'center'
  };
  const authLinkStyle = {
    fontSize: '1.3em',
    marginRight: '30px',
    textDecoration: 'none',
    color: '#007B8F',
    fontWeight: 'bold'
};


const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: '#00607a',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold',
    fontSize: '1.3em'
};

const buttonHoverStyle = {
    backgroundColor: '#005f6f'
};


  return (
    <Flex
      direction="row"
      width="100%"
      justifyContent="space-between"
      alignItems="center"
      style={headerStyle}
      {...getOverrideProps(overrides, "CustomNavBarHeader")}
      {...rest}
    >
      <Flex direction="row" gap="100px" alignItems="center" ref={homeRef}>
        <View className="logo">
          <Image src="gaiff_logo.png" alt="The Global AI Internet Freedom Fund" style={logoStyle} {...getOverrideProps(overrides, "Logo")} />
        </View>
        <Flex direction="row" style={navStyle} {...getOverrideProps(overrides, "NavLinks")}>
          <Link href="#home" onClick={() => scrollToSection(homeRef)} style={navLinkStyle} {...getOverrideProps(overrides, "HomeLink")}>Home</Link>
          <Link href="#products" onClick={() => scrollToSection(productsRef)} style={navLinkStyle} {...getOverrideProps(overrides, "ProductsLink")}>Products</Link>
          <Link href="#pricing" onClick={() => scrollToSection(pricingRef)} style={navLinkStyle} {...getOverrideProps(overrides, "PricingLink")}>Pricing</Link>
          <Link href="#contact" onClick={() => scrollToSection(contactRef)} style={navLinkStyle} {...getOverrideProps(overrides, "ContactLink")}>Contact</Link>
        </Flex>
      </Flex>
      <Flex direction="row" gap="16px" style={authStyle} {...getOverrideProps(overrides, "AuthSection")}>
        <Link href="/signin" style={authLinkStyle} {...getOverrideProps(overrides, "LoginLink")}>Log in</Link>
        <Button onClick={navigateToSignup} style={{...buttonStyle, ':hover': buttonHoverStyle}} {...getOverrideProps(overrides, "SignUpButton")}>Sign up</Button>
      </Flex>
    </Flex>
  );
}

function scrollToSection(ref) {
  ref.current.scrollIntoView({ behavior: 'smooth' });
}