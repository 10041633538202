/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import {
  getOverrideProps,
  getOverridesFromVariants,
  mergeVariantsAndOverrides,
} from "./utils";
import MyIcon from "./MyIcon";
import { Flex, Text } from "@aws-amplify/ui-react";
export default function SelectedButton(props) {
  const { overrides: overridesProp, ...rest } = props;
  const variants = [
    {
      overrides: { MyIcon: {}, icon: {}, label: {}, SelectedButton: {} },
      variantValues: { state: "Default" },
    },
    {
      overrides: {
        MyIcon: {},
        icon: {},
        label: { fontWeight: "400" },
        SelectedButton: {},
      },
      variantValues: { state: "Unselected" },
    },
  ];
  const overrides = mergeVariantsAndOverrides(
    getOverridesFromVariants(variants, props),
    overridesProp || {}
  );
  return (
    <Flex
      gap="16px"
      direction="row"
      width="unset"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      padding="8px 8px 8px 8px"
      display="flex"
      {...getOverrideProps(overrides, "SelectedButton")}
      {...rest}
    >
      <Flex
        gap="0"
        direction="row"
        width="30px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        borderRadius="4px"
        padding="6px 6px 6px 6px"
        backgroundColor="rgba(0,96,122,1)"
        display="flex"
        {...getOverrideProps(overrides, "icon")}
      >
        <MyIcon
          width="18px"
          height="18px"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          type="home"
          {...getOverrideProps(overrides, "MyIcon")}
        ></MyIcon>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="600"
        color="rgba(0,96,122,1)"
        lineHeight="24px"
        textAlign="left"
        display="block"
        direction="column"
        justifyContent="unset"
        letterSpacing="0.01px"
        width="unset"
        height="unset"
        gap="unset"
        alignItems="unset"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Sales Report"
        {...getOverrideProps(overrides, "label")}
      ></Text>
    </Flex>
  );
}
