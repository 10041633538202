import React from "react";

const ForecastJobMetricsModal = ({ isOpen, onClose, algorithm, MAPE, MASE, RMSE, WAPE }) => {
  if (!isOpen) return null;

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      zIndex: 1000,
    },
    container: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "8px",
      width: "320px",
      maxWidth: "90%",
      textAlign: "center",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    },
    header: {
      marginTop: 0,
      marginBottom: "35px",
      fontSize: "25px",
      fontWeight: "bold",
      fontFamily: "Inter",
      color: "#0D1A26",
    },
    text: {
      margin: "8px 0px",
      fontFamily: "Inter",
      fontSize: "16px",
      color: "#304050",
      marginTop:"20px"
    },
    strong: {
      color: "#2D3748",
      fontFamily: "Inter",
      fontWeight:"800",
    },
    link: {
      color: "#00607A",
      textDecoration: "none",
      fontSize: "16px",
      marginTop: "10px",
      fontWeight: "bold",
      display: "inline-block",
      fontFamily: "Inter",
      cursor: "pointer",
    },
    hr: {
      marginTop: "35px",
      borderTop: "1px solid #E2E8F0",
    },
    button: {
      padding: "8px 12px",
      backgroundColor: "#FFF",
      border: "1px solid #AEB3B7",
      borderRadius: "6px",
      fontSize: "15px",
      fontFamily: "Inter",
      color: "#2D3748",
      cursor: "pointer",
      marginTop: "10px",
      fontWeight: "bold"
    },
    buttonHover: {
      backgroundColor: "#EDF2F7",
    },
  };

  return (
    <div style={styles.overlay}>
      <div style={styles.container}>
        <h2 style={styles.header}>Job Metrics</h2>
        <p style={styles.text}>
          Algorithm: <strong style={styles.strong}>{algorithm}</strong>
        </p>
        <p style={styles.text}>
          MAPE: <strong style={styles.strong}>{MAPE}</strong>
        </p>
        <p style={styles.text}>
          MASE: <strong style={styles.strong}>{MASE}</strong>
        </p>
        <p style={styles.text}>
          RMSE: <strong style={styles.strong}>{RMSE}</strong>
        </p>
        <p style={styles.text}>
          WAPE: <strong style={styles.strong}>{WAPE}</strong>
        </p>
        <a href="#more-info" style={styles.link}>
          What does that mean?
        </a>
        <hr style={styles.hr} />
        <button
          style={styles.button}
          onMouseOver={(e) => (e.target.style.backgroundColor = styles.buttonHover.backgroundColor)}
          onMouseOut={(e) => (e.target.style.backgroundColor = styles.button.backgroundColor)}
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ForecastJobMetricsModal;
