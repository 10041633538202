import {Amplify, API, Auth, Cache} from 'aws-amplify';
import { withAuthenticator,Flex,Text, Link,View, Divider } from '@aws-amplify/ui-react';
import Partners from '../ui-components/Partners';
import React,{useEffect, useRef, useState} from 'react';
import  NavBar  from '../components/navbar.tsx';
import awsconfig from './../aws-exports';
import AccountRegionCard from '../ui-components/AccountRegionCard';
import { getUser, getStripeCustomer,getRefreshToken,asinIngest, inventoryIngest } from '../graphql/queries';
import { updateUser } from '../graphql/mutations.js';
import  { useNavigate } from 'react-router'
import AccountAuthorizetheRegion from '../ui-components/AccountAuthorizetheRegion';
import AccountAuthorizeRegion from '../ui-components/AccountAuthorizeRegion.jsx';
import Selectedbutton from '../ui-components/SelectedButton';
import MyIcon from '../ui-components/MyIcon';
import ButtonWithIcon2 from '../ui-components/ButtonWithIcon2';
import ButtonWithIcon from '../ui-components/ButtonWithIcon';

import { createForecastJob, createIngestJob, createJob } from '../graphql/mutations';
import GeneralFooter from '../ui-components/GeneralFooter.jsx';
const { DateTime } = require("luxon");
Amplify.configure(awsconfig);

function Authorize({ signOut, user }) {

  const navigate = useNavigate();
  const [buttonUSColor, setButtonUSColor] = useState("");
  const [buttonEUColor, setButtonEUColor] = useState("");
  const [buttonFEColor, setButtonFEColor] = useState("");

  const [buttonUSText, setButtonUSText] = useState("");
  const [buttonEUText, setButtonEUText] = useState("");
  const [buttonFEText, setButtonFEText] = useState("");
  
  let regions =[
    "https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.29cd1533-5615-4a22-93ef-fdc0f236730a&state=NA&version=beta",
    "https://sellercentral-europe.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.29cd1533-5615-4a22-93ef-fdc0f236730a&state=EU&version=beta",
    "https://sellercentral.amazon.com.au/apps/authorize/consent?application_id=amzn1.sp.solution.29cd1533-5615-4a22-93ef-fdc0f236730a&state=FE&version=beta"
  ];
  const unauthColor = "rgba(64,106,191,1)";
  const authColor = "rgba(67,168,84,1)";

  useEffect(() => {
    checkAccount();
    
  }, []); 

  async function updateUserDetails(email, authCode, state, selling_partner_id) {
   
    // if we are redirecting back from Seller Central
    const onGenerateAuth = (await API.graphql(
      {query:getRefreshToken, variables:{ authCode: authCode , region : state, email:email, selling_partner_id:selling_partner_id}}
    ))
    // console.log(onGenerateAuth);
    let jsonPayload = onGenerateAuth["data"]["getRefreshToken"];
    if (JSON.parse(jsonPayload) != "query failed")
      { 
        let jsonResponse = JSON.parse(jsonPayload)
        let responseRefreshtoken = jsonResponse["refresh_token"]
        let responseMarketplaces = jsonResponse["marketplaces"]
        let refreshToken = responseRefreshtoken
        let credentials = await Auth.currentUserCredentials();
        let curr_user = await API.graphql({ query: getUser , variables:{id:email}});
        
        let rf_data = curr_user["refreshToken"] ? curr_user["refreshToken"] : {}
        let spid_data = curr_user["SellingPartnerId"] ? curr_user["SellingPartnerId"] : {}
        rf_data[state] = refreshToken
        spid_data[state] = selling_partner_id
        let NA_rf_token = rf_data["NA"] ? rf_data["NA"] : null
        let EU_rf_token = rf_data["EU"] ? rf_data["EU"] : null
        let FE_rf_token = rf_data["FE"] ? rf_data["FE"] : null 
        let NA_spid = spid_data["NA"] ? spid_data["NA"] : null
        let EU_spid = spid_data["EU"] ? spid_data["EU"] : null
        let FE_spid = spid_data["FE"] ? spid_data["FE"] : null 
        let rf_holder = {}
        if (EU_rf_token) {
          rf_holder["EU"] = EU_rf_token
        }
        if (NA_rf_token) {
          rf_holder["NA"] = NA_rf_token
        }
        if (FE_rf_token) {
          rf_holder["FE"] = FE_rf_token
        }
        let spid_holder = {}
        if (EU_spid) {
          spid_holder["EU"] = EU_spid
        }
        if (NA_spid) {
          spid_holder["NA"] = NA_spid
        }
        if (FE_spid) {
          spid_holder["FE"] = FE_spid
        }

       // console.log(rf_holder)
        //console.log(spid_holder)
        let userMarketplaces
        if(curr_user["Marketplace"]){
          userMarketplaces = JSON.parse(curr_user["Marketplace"])
        }
        
        let marketplaces;
        let mp_to_update  = []
        
        if(!userMarketplaces){
          for(let mp in responseMarketplaces[state] ){
            mp_to_update.push(responseMarketplaces[state][mp]["id"])
          }
          marketplaces = responseMarketplaces
        }
        else{
          //console.log("in marketplaces")
          for(let mp in userMarketplaces[state] ){
            mp_to_update.push(userMarketplaces[state][mp]["id"])
          }
          marketplaces = {...userMarketplaces, ...responseMarketplaces};
          //console.log(mp_to_update)
          //console.log(marketplaces)
        }
        
        const updateUserPayload = {
          email:email,
          username:email,
          hasRfToken:true,
          id:email,
          refreshToken: JSON.stringify(rf_holder),
          sellingPartnerIds : JSON.stringify(spid_holder),
          Marketplace: JSON.stringify(marketplaces)
        }
        
        await API.graphql({query: updateUser,variables:{input:updateUserPayload}});

        // now get 2 years of data
        const start = DateTime.now().plus({ days: - 730 })
        const end = DateTime.now()

        const diffInDays = Math.floor(end.diff(start, 'days').days);

        // create ingest for each region
        for(let i = 0; i < mp_to_update.length; i++){
          const ingestJobPayload = {
            startIngestDateTime: start.toISO(),
            endIngestDateTime: end.toISO(),
            lastIngestedOrderDateTime: end.toISO(),
            ingestIntervalDays: diffInDays,
            marketID: mp_to_update[i],
            sellerId: selling_partner_id,
            cognitoID: credentials.identityId,
            // __typename:"InitialIngest"
          };

          // console.log(ingestJobPayload);

          const ingestResponse = (await API.graphql({query: createIngestJob,variables:{input:ingestJobPayload}})) as{data:CreateIngestJobResponse};
          
          const jobInput = {
              ingestJobID: ingestResponse.data.createIngestJob.id,
              forecastJobID: "none",
              //merchantID: payload.merchantID,
              userID: user.attributes.email,
              userIDCognito: credentials.identityId
          };
            
          await API.graphql({query: createJob,variables:{input:jobInput}});

          
        }
        //ingest asins now

        const inventoryResponse = await API.graphql({
          query: inventoryIngest,
          variables : {"email": email}
        });
        console.log("invenoty response" , inventoryResponse);


        const response = await API.graphql({
          query: asinIngest
          ,variables : {"email": email}
        });
        
        console.log("STR 2 years response", response);
        
        // Update cache with user including refresh token and marketplaces
        let cognitoUser = await Auth.currentAuthenticatedUser();
        const { attributes } = cognitoUser;
        const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});
        if(result['data'].getUser){
          Cache.setItem('user', result['data'].getUser);
        }

        navigate("/sales-report")
        
      //     const strHistory = (await API.graphql(
      //       graphqlOperation(getStrHistory, { email: attributes.email, marketplaces: mp_to_update.join()})
      //     ));
      //     //console.log(strHistory);
      //  this.market.getInfo();
      //   this.canaccessUI = false
      }
      else {
        let refreshToken = null
        console.log("rftkn_err")
      }
  }
    
  async function checkAccount(){
    
    // const start = DateTime.now().plus({ days: -30 })
    // const end = DateTime.now()
    // const ingestJobPayload = {
    //   startIngestDateTime: start.toISO(),
    //   endIngestDateTime: end.toISO(),
    //   lastIngestedOrderDateTime: end.toISO(),
    //   ingestIntervalDays: 30,
    //   marketID: 'ATVPDKIKX0DER',
    //   sellerId: 'AERPN1UM8O1I4',
    //   cognitoID: 'us-east-1:3d40c31d-25cd-cb01-6ca5-e6b9ff70feb5',
    //   // __typename:"InitialIngest"
    // };

    // // console.log(ingestJobPayload);

    // const ingestResponse = (await API.graphql({query: createIngestJob,variables:{input:ingestJobPayload}})) as{data:CreateIngestJobResponse};
    
    // const jobInput = {
    //     ingestJobID: ingestResponse.data.createIngestJob.id,
    //     forecastJobID: "none",
    //     //merchantID: payload.merchantID,
    //     userID: 'pete.nelson+1@gaiiff.com',
    //     userIDCognito: 'us-east-1:3d40c31d-25cd-cb01-6ca5-e6b9ff70feb5'
    // };
    //   console.log('starting job')
    // await API.graphql({query: createJob,variables:{input:jobInput}});
    
    //invalid token to test with 
    //Atzr|IwEBIDj7-by8uF4ZQRc-tfoIShPGKce7tF-60y6Jq6_fDWGceBreZRU8NaBI-0veBI9V3EkvaCCAOWzzX0TSwoFciuOjS5qbYtpfwHrzp9tZDkmMGOhs9jxFcR6cWaNHJ5H9718tHolMf2Sij5clYvfwLdsQBVWgsdF0qHXqKPO4j3ry9GtqLQ1Lg-vFfnzJmihzsigvHnY0GzeTauYJhzhtHjpdrlxC72OW1pE9UkKXhS_Tfyz6TsCbVz6M9xsLKN7PoesRX8usGSnpwdHNAeOUhTW4u95myWgXuQ9RF5vPr5ZV24UVdd-AV5opdUviGlnbYqk
    
    //https://sellercentral.amazon.com/apps/authorize/consent?application_id=amzn1.sp.solution.29cd1533-5615-4a22-93ef-fdc0f236730a&state=NA&version=beta
    let cognitoUser = await Auth.currentAuthenticatedUser();

    const { attributes } = cognitoUser;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    
    const authCode = params.get('spapi_oauth_code');
    const state = params.get('state');
    const selling_partner_id = params.get('selling_partner_id');
   
    if(authCode){
      
      await updateUserDetails(attributes.email,authCode,state,selling_partner_id);
    }
    
    const credentials = await Auth.currentUserCredentials();

    const result = await API.graphql({ query: getUser , variables:{id:attributes.email}});
    console.log("IS USER NULL:" + JSON.stringify(result['data'].getUser.refreshToken))
    if(result['data'].getUser !=null && result['data'].getUser.refreshToken !=null){
      const refreshTokenJSON  = result['data'].getUser.refreshToken;
      const refreshTokens = JSON.parse(refreshTokenJSON);
      if(refreshTokens){
        if(refreshTokens['NA']){
          setButtonUSColor(authColor);
          setButtonUSText('Authorized');
        }
        else {
          setButtonUSColor(unauthColor);
          setButtonUSText('Authorize Now');
        }

        if(refreshTokens['EU']){
          setButtonEUColor(authColor);
          setButtonEUText('Authorized');
        }
        else {
          setButtonEUColor(unauthColor);
          setButtonEUText('Authorize Now');
        }

        if(refreshTokens['FE']){
          setButtonFEColor(authColor);
          setButtonFEText('Authorized');
        }
        else {
          setButtonFEColor(unauthColor);
          setButtonFEText('Authorize Now');
        }
      }
      else {
        setButtonUSColor(unauthColor);
        setButtonUSText('Authorize Now');
        setButtonEUColor(unauthColor);
        setButtonEUText('Authorize Now');
        setButtonFEColor(unauthColor);
        setButtonFEText('Authorize Now');
      }
      
    }else{
      setButtonUSColor(unauthColor);
      setButtonUSText('Authorize Now');
      setButtonEUColor(unauthColor);
      setButtonEUText('Authorize Now');
      setButtonFEColor(unauthColor);
      setButtonFEText('Authorize Now')
    }
  }


  return (
    <div className="Account" style={{width:"100%"}}>
                <Flex 
                padding="20px 20px 20px 15px"
                overflow={'auto'}>
                    <Flex position={'relative'} overflow={'hidden'} grow={1}>
                    <Flex
      gap="46px"
      direction="column"
      width="100%"
      height="unset"
      justifyContent="flex-start"
      alignItems="center"
      overflow="hidden"
      position="relative"
      padding="48px 48px 48px 48px"
      backgroundColor="rgba(250,250,250,1)"
      
    >
      <Flex
        gap="16px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="flex-start"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        
        <Text
          fontFamily="Inter"
          fontSize="32px"
          fontWeight="600"
          color="rgba(13,26,38,1)"
          lineHeight="32px"
          textAlign="left"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Authorize the Region"
          
        ></Text>
        
      </Flex>
      <Flex
        gap="28px"
        direction="row"
        width="817px"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
       <Flex
      gap="24px"
      direction="column"
      width="250px"
      height="686px"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
      borderRadius="8px"
      padding="20px 16px 20px 16px"
      backgroundColor="rgba(255,255,255,1)"
     
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="flex-start"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Americas"
            
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            color="rgba(92,102,112,1)"
            lineHeight="25px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="4 Supported Marketplaces included "
            
          ></Text>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
           
          >
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Brazil"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
             
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
              
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Canada"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Mexico"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
             
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
               
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="United States"
                
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Link href={regions[0]}>
      {regions['NA']}
      <ButtonWithIcon2
        display="flex"
        gap="12px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(0,0,0,0)"
        borderRadius="4px"
        padding="7px 15px 7px 15px"
        backgroundColor={buttonUSColor}
        overrides={{label:{children:buttonUSText}}}
        state="Default"
        type="Authorized"
        
      ></ButtonWithIcon2>
      </Link>
    </Flex>











    <Flex
      gap="24px"
      direction="column"
      width="267px"
      height="686px"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
      borderRadius="8px"
      padding="20px 16px 20px 16px"
      backgroundColor="rgba(255,255,255,1)"
      
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
         
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Eurasia"
           
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            color="rgba(92,102,112,1)"
            lineHeight="25px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="15 Supported Marketplaces included"
            
          ></Text>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            
          >
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
            
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Belgium"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
             
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
               
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Egypt"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
               
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="France"
               
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Germany"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
            
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
               
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="India"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Italy"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
               
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Poland"
               
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
             
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
               
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Netherlands"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Saudi Arabia"
               
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Spain"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Sweden"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
               
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Turkey"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="UAE"
               
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
            
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
               
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="United Kingdom"
                
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Link href={regions[1]}>
      <ButtonWithIcon2
        display="flex"
        gap="12px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(0,0,0,0)"
        borderRadius="4px"
        padding="7px 15px 7px 15px"
        backgroundColor={buttonEUColor}
        overrides={{label:{children:buttonEUText}}}
        state="Default"
        type="Authorized"
        
      ></ButtonWithIcon2>
      </Link>
    </Flex>













    <Flex
      gap="24px"
      direction="column"
      width="267px"
      height="686px"
      justifyContent="space-between"
      alignItems="center"
      position="relative"
      boxShadow="0px 2px 4px rgba(0.05098039284348488, 0.10196078568696976, 0.14901961386203766, 0.05000000074505806)"
      borderRadius="8px"
      padding="20px 16px 20px 16px"
      backgroundColor="rgba(255,255,255,1)"
     
    >
      <Flex
        gap="24px"
        direction="column"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        
      >
        <Flex
          gap="8px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="flex-start"
          alignItems="flex-start"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          
        >
          <Text
            fontFamily="Inter"
            fontSize="32px"
            fontWeight="600"
            color="rgba(13,26,38,1)"
            lineHeight="25px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Asia Pacific"
            
          ></Text>
        </Flex>
        <Flex
          gap="16px"
          direction="column"
          width="unset"
          height="unset"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
        
        >
          <Text
            fontFamily="Inter"
            fontSize="16px"
            fontWeight="600"
            color="rgba(92,102,112,1)"
            lineHeight="25px"
            textAlign="center"
            display="block"
            direction="column"
            justifyContent="unset"
            width="unset"
            height="unset"
            gap="unset"
            alignItems="unset"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children=" 3 Supported Marketplaces included"
            
          ></Text>
          <Flex
            gap="0"
            direction="column"
            width="unset"
            height="unset"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
          
          >
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Australia"
               
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
            
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Japan"
                
              ></Text>
            </Flex>
            <Flex
              gap="16px"
              direction="row"
              width="unset"
              height="34px"
              justifyContent="flex-start"
              alignItems="center"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              
            >
              <MyIcon
                width="24px"
                height="24px"
                display="block"
                gap="unset"
                alignItems="unset"
                justifyContent="unset"
                overflow="hidden"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                type="checkmark"
                
              ></MyIcon>
              <Text
                fontFamily="Inter"
                fontSize="16px"
                fontWeight="400"
                color="rgba(13,26,38,1)"
                lineHeight="24px"
                textAlign="left"
                display="block"
                direction="column"
                justifyContent="unset"
                letterSpacing="0.01px"
                width="unset"
                height="unset"
                gap="unset"
                alignItems="unset"
                grow="1"
                shrink="1"
                basis="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Singapore"
               
              ></Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Link href={regions[2]}>
      <ButtonWithIcon2
        display="flex"
        gap="8px"
        direction="row"
        width="unset"
        height="unset"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        border="1px SOLID rgba(0,0,0,0)"
        borderRadius="4px"
        padding="7px 15px 7px 15px"
        backgroundColor={buttonFEColor}
        overrides={{label:{children:buttonFEText}}}
      ></ButtonWithIcon2>
      </Link>
    </Flex>



      </Flex>
    </Flex>
                    </Flex>
                </Flex>
                <GeneralFooter/>
        </div>
  );
      
   
  
}

export default withAuthenticator(Authorize);
