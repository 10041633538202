import {Amplify} from 'aws-amplify';
import  { useNavigate } from 'react-router'
import React,{useEffect, useRef } from 'react';
import awsconfig from './../aws-exports';
import CustomNavBarHeader from '../ui-components/CustomNavBarHeader';
import CustomLandingHeroSection from '../ui-components/CustomLandingHeroSection';
import LandingPageFeatureList from '../ui-components/LandingPageFeatureList';
import CustomPricingPlanList from '../ui-components/CustomPricingPlanList';
import CustomContactSection from '../ui-components/CustomContactSection';
import CustomFooter from '../ui-components/CustomFooter';
import ContactUsHeader from '../ui-components/ContactUsHeader';

Amplify.configure(awsconfig);

function LandingPage() {
  const navigate = useNavigate();
  useEffect(() => {
    
  }, []);

  const homeRef = useRef(null);
  const productsRef = useRef(null); 
  const pricingRef = useRef(null);
  const contactRef = useRef(null); 

  const navigateToLogin = () => {
    navigate("/signin");
  }

  const navigateToSignup = () => {
    navigate("/signup");
  }

  return (
    <>
      <CustomNavBarHeader homeRef = {homeRef} productsRef= {productsRef} pricingRef= {pricingRef} contactRef ={ contactRef} navigateToSignup = {navigateToSignup}/>

      <CustomLandingHeroSection navigateToLogin = {navigateToLogin}/>

      <LandingPageFeatureList  productsRef={productsRef}  />
    
      <CustomPricingPlanList  pricingRef = {pricingRef} navigateToSignup = {navigateToSignup}/>

      <ContactUsHeader contactRef= {contactRef}/>
   
      <CustomContactSection />

      <CustomFooter />
    </>
  );
}

export default (LandingPage);
